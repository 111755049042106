import React from 'react'

const flexValueTable = {
    heading:[
        {
            head:"Flex Value ID"
        },
        {
            head:"Flex Set Name"
        },
        {
            head:"Flex Value Name"
        },
        {
            head:"Flex Short Name"
        },
        {
            head:"Active From"
        },
        {
            head:"Active To"
        },
        {
            head:"Action"
        }
    ],
    data:[
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2023-1-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2023-1-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"1",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"2",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"3",
            flexsetname:"Religion",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"4",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"5",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"6",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            flexvalueid:"7",
            flexsetname:"Gender",
            flexvaluename:"Islam",
            flexshortname:"Islam",
            activefrom:"2022-04-15",
            activeto:"2023-04-05",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
    ]
}

const FlexValue = () => {
  return (
    <></>
  )
}


export {flexValueTable}

export default FlexValue