import React,{useEffect,useState} from 'react'
import {Navbar,Nav,Button } from 'react-bootstrap'

import {NavLink,Link, useNavigate} from 'react-router-dom';
import '../../../../sassFiles/mobile/sassPages/homepage.scss'
import { Accordion,Card } from 'react-bootstrap';
import {Row,Col} from 'react-bootstrap'
import { sidebarApiadmin } from '../../../../utils/DashboardApi/AdminDashboard/AdminDashboardApi';

const AdDashSidebarmb = (props) => {
    let navigate = useNavigate()
   
  let activeStyle = {
    color:"#ED1C24"
  };
const [varforiconchange,setvarforiconchange ]=useState("")
const handleonclick=()=>{
  
  
  var mynavbarnav = document.getElementById("mynavbarnav");
  mynavbarnav.classList.toggle('show');
  mynavbarnav.classList.toggle('navbar-white');
}

const handletoggler=()=>{
  var navtoggler = document.getElementById("nav-icon4");
  navtoggler.classList.toggle('open');
}

const handleTabvalue=(mytabval)=>{
    var mynavbarnav = document.getElementById("mynavbarnav");
    mynavbarnav.classList.remove('show');
    var navtoggler = document.getElementById("nav-icon4");
    navtoggler.classList.toggle('open');
    
    var getiddashboardtab = document.getElementById("dashboardTab")
    var getiddocumenttab = document.getElementById("documentTab")
    var getinoticetab = document.getElementById("noticeTab")
    var getclassroutinetab = document.getElementById("classRoutineTab")
    var getatttab = document.getElementById("attendanceTab")
    var getbillinfotab = document.getElementById("billInfoTab")
    var getresulttab = document.getElementById("resultTab")
    var getprofiletab = document.getElementById("profileTab")
    var getexamroutinetab = document.getElementById("examRoutineTab")

    getiddashboardtab.classList.remove("activei")
    getiddocumenttab.classList.remove("activei")
    getinoticetab.classList.remove("activei")
    getatttab.classList.remove("activei")
    getclassroutinetab.classList.remove("activei")
    getbillinfotab.classList.remove("activei")
    getresulttab.classList.remove("activei")
    getprofiletab.classList.remove("activei")
    getexamroutinetab.classList.remove("activei")

    var getidtab = document.getElementById(mytabval)
    setvarforiconchange(mytabval)
    getidtab.classList.add('activei')

}
const handlelogout=()=>{
    navigate("/")
}
const handleLi = (mylink)=>{
    navigate(mylink)
   
  }
  
  return (
    <>
          <Navbar id="navbarmini" className="mynavbarmb studashnav adminnavbamain" expand="lg">
            
            <div className="headerwrapper">
              <Row style={{width:"100%"}}>
                <Col xs={2}>
                    <div className='navbar-brand2'>
                      <Link to="/"><img src="./assets/images/logo/navbar_logo.png" alt="logo" /> </Link>
                                         
                </div>                
                </Col>
                <Col xs={9} style={{paddingRight:"0",paddingLeft:"0"}} className="togglermenuclass2">
                    <p>British Standard School</p> 
                </Col>

                <Col xs={1} className="togglermenuclass">
                <div className="navbar-toggler" onClick={handleonclick} id="navbar-toggler" aria-controls="basic-navbar-nav" >
                <div id="nav-icon4" onClick={handletoggler}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                </div>
                </Col>
              </Row>
                
             <div className="menupartmb">
             <Nav className="mynavbarnav mynavbarnavdash adminnavmb" id="mynavbarnav">
                        
                    <div className="sidebarscrolldiv">
                        {
                        sidebarApiadmin.map((item,key)=>{
                            if(item.drpdwn.length!==0){
                                return(
                                  <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header><img src={item.imgurl} alt="" /> <span>{item.name}</span> </Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                    { item.drpdwn.map((item2,key2)=>{
                                      return(
                                        <li onClick={()=>handleLi(item2.link)}> 
                                        <div className={window.location.pathname==item2.link?`wr active`:`wr`}>
                                          {item2.item}
                                          </div>
                                           </li>
                                      )
                                    })}
          
                                  </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                )
          
                              }else{
                                return(
                                
                                    <Nav.Item key={key}>
                                    <NavLink to={item.link} className={window.location.pathname==item.link?`activei`:``}><img src={item.imgurl} alt="" /> {item.name}</NavLink>
                                    </Nav.Item>
                                )
                              }
                            
                        })
                    }
                        
                    {/* <NavLink to="/studashboard"
                    className={props.redirectkey=='first'?'activei':''}
                     id="dashboardTab"
                    onClick={()=>handleTabvalue("dashboardTab")}
                    >

                         {
                            varforiconchange=="dashboardTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        
                        Dashboard
                        </NavLink>

                    <NavLink to="/studashboard/document" className={props.redirectkey=='second'?'activei':''} id="documentTab" onClick={()=>handleTabvalue("documentTab")}                    
                    >
                        {
                            varforiconchange=="documentTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Document
                    </NavLink>
                    <NavLink to="/studashboard/notice" className={props.redirectkey=='third'?'activei':''} id="noticeTab" onClick={()=>handleTabvalue("noticeTab")}                    
                    >
                        {
                            varforiconchange=="noticeTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Notice
                    </NavLink>
                    <NavLink className={props.redirectkey=='fourth'?'activei':''} id="classRoutineTab" onClick={()=>handleTabvalue("classRoutineTab")}                    
                    >
                        {
                            varforiconchange=="classRoutineTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Class Routine
                    </NavLink>
                    <NavLink className={props.redirectkey=='fifth'?'activei':''}  id="examRoutineTab" onClick={()=>handleTabvalue("examRoutineTab")}                    
                    >
                        {
                            varforiconchange=="examRoutineTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Exam Routine
                    </NavLink>
                    <NavLink to="/studashboard/attendance" className={props.redirectkey=='sixth'?'activei':''} id="attendanceTab" onClick={()=>handleTabvalue("attendanceTab")}                    
                    >
                        {
                            varforiconchange=="attendanceTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Attendance
                    </NavLink>
                    <NavLink to="/studashboard/billinfo" className={props.redirectkey=='seventh'?'activei':''} id="billInfoTab" onClick={()=>handleTabvalue("billInfoTab")}                    
                    >
                        {
                            varforiconchange=="billInfoTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Bill Info
                    </NavLink>
                    <NavLink to="/studashboard/result" className={props.redirectkey=='eighth'?'activei':''} id="resultTab" onClick={()=>handleTabvalue("resultTab")}                    
                    >
                        {
                            varforiconchange=="resultTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Result
                    </NavLink>
                    <NavLink to="/studashboard/leaveform" className={props.redirectkey=='ninth'?'activei':''} id="leaveFormTab" onClick={()=>handleTabvalue("leaveFormTab")}                    
                    >
                        {
                            varforiconchange=="leaveFormTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Leave Form
                    </NavLink>
                    <NavLink to="/studashboard/profile" className={props.redirectkey=='tenth'?'activei':''} id="profileTab" onClick={()=>handleTabvalue("profileTab")}                    
                    >
                        {
                            varforiconchange=="profileTab"?
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticon.png" alt="" />
                            :
                            <img src="./assets/images/dashboards/studentDashboard/mobile/icons/bulleticonblack.png" alt="" />
                        }
                        Profile
                    </NavLink> */}
                    </div>

            <div className="logoutbuttondiv">
                <Button onClick={handlelogout}>Log Out</Button>
            </div>
                </Nav>
             </div>
                
               
                
                
            
            </div>
            </Navbar>
    </>
  )
}

export default AdDashSidebarmb