import React from 'react'
import {BiBell} from 'react-icons/bi'
import { adminHeaderApi } from '../../../utils/AdminDashApi/HeaderApi'
const HeaderofAdash = (props) => {

    const notification = {
        notification:true
    }

  return (
    <>
    

        <div className="headerofadash">
            <div className="left">
                <h2>{props.head}</h2>
            </div>
            <div className="right">
                {
                    notification.notification==true?
                    <div className="belldiv">
                        <BiBell/>
                    </div>
                    :
                    <BiBell/>
                }
                <div className="displaypic">
                    <img src="./assets/images/dashboards/adminDashboard/header/DP.png" alt="" />
                </div>
                <div className="nameandid">
                    <h5>{adminHeaderApi.name}</h5>
                    <p>ID {adminHeaderApi.id}</p>
                </div>
            </div>
        </div>
    
    </>
  )
}

export default HeaderofAdash