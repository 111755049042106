import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import Dropdown2 from './subComponents/CustomSubComponents/Dropdown2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const ApplyOnlineComp = () => {
    const navigate = useNavigate()
    const [drpsectionselectgender, setdrpsectiongender] = useState("")
    const [dateofbirthnew, setdateofbirthnew] = useState("")
    const [dateofadmission, setdateofadmission] = useState("")
    const [classoptions, setclassoptions] = useState("")
    const [drpsesstionselect, setdrpsession] = useState("")
    const [studentpicfile, setstudentpic] = useState()
    const [studentbirthimgfile, setstudentbirthimgfile] = useState()
    const [fatherNIDimg, setfatherNIDimg] = useState()
    const [matherNIDimg, setmatherNIDimg] = useState()
    const [transcriptimg, settranscriptimg] = useState()



    const handleInput = (e) => {
        setformdata({ ...formdata, [e.target.name]: e.target.value })
    }
    const drpfuncClass = (myclassval, classid) => {
        setclassoptions(classid)
    }
    const drpfunctionsesstion = (mysesstion, sesstionid) => {
        setdrpsession(sesstionid)
    }

    const drpfunctiongender = (mysectionval, genderid) => {
        setdrpsectiongender(genderid)
    }


    moment().format();
    const handleDateofbirth = (newvaldob) => {
        console.log(newvaldob)
        // setdateofbirthnew(moment(newvaldob).format("YYYY-MM-DD"))
        if (newvaldob != null) {

            var dtofbrth = newvaldob.$M + 1;
            var dtdobfinalc = newvaldob.$y + '-' + dtofbrth + '-' + newvaldob.$D;
            setdateofbirthnew(moment(dtdobfinalc).format("YYYY-MM-DD"))
            // setdateofbirthnew(newvaldob)
        }
        console.log(dateofbirthnew);


    }
    const handleDateofadmission = (newvaldob) => {

        if (newvaldob != null) {

            var dtofbrth = newvaldob.$M + 1;
            var dtdobfinalc = newvaldob.$y + '-' + dtofbrth + '-' + newvaldob.$D;
            setdateofadmission(moment(dtdobfinalc).format("YYYY-MM-DD"))
        }


    }
    const changeHandlerStudentpic = (e) => {
        // setstudentpic(event.target.files)
        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {

            setstudentpic(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }


    }
    const changeHandlerStudentbirthimg = (e) => {

        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setstudentbirthimgfile(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
    }
    const changeHandlerFatherNIDimg = (e) => {

        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setfatherNIDimg(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
    }
    const changeHandlerMotherNIDimg = (e) => {

        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            setmatherNIDimg(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
    }
    const changeHandlertranscriptimg = (e) => {

        var file = e.target.files[0]
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
            settranscriptimg(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        }
    }
    const headers = {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "*",
    }
    const [id, setid] = useState("");
    const [name, setname] = useState("");
    const [classresponse, setclassresponse] = useState([]);
    const [genderresponse, setgenderrsponse] = useState([]);
    const [sessionresponse, setsessionrsponse] = useState([]);

    //classdropdownload
    useEffect(() => {
        const fetchClassDropdown = async () => {
            try {
                const classresponse = await axios.get(
                    "https://sass.britishstandardschool.com/api/Public/ClassList",
                    {},
                    headers
                );
                setclassresponse(classresponse.data);
                console.log(classresponse);
            } catch (err) {
                if (err.classresponse) {
                    console.error(err.classresponse.data);
                    console.log(err.classresponse.status);
                    console.log(err.classresponse.header);
                } else {
                    console.error(`Error: ${err.message}`);
                }
            }
        };
        fetchClassDropdown();
    }, []);

    //genderdropdownload
    useEffect(() => {
        const fetchGenderDropdown = async () => {
            try {
                const genderresponse = await axios.get(
                    "https://sass.britishstandardschool.com/api/Public/GenderDropDown",
                    {},
                    headers
                );
                setgenderrsponse(genderresponse.data);
                console.log(genderresponse);
            } catch (err) {
                if (err.genderresponse) {
                    console.error(err.genderresponse.data);
                    console.log(err.genderresponse.status);
                    console.log(err.genderresponse.header);
                } else {
                    console.error(`Error: ${err.message}`);
                }
            }
        };
        fetchGenderDropdown();
    }, []);

    //sessiondropdownload
    useEffect(() => {
        const fetchSessionDropdown = async () => {
            try {
                const sessionresponse = await axios.get(
                    "https://sass.britishstandardschool.com/api/Public/SessionDropDown",
                    {},
                    headers
                );
                setsessionrsponse(sessionresponse.data);
                console.log(sessionresponse);
            } catch (err) {
                if (err.sessionresponse) {
                    console.error(err.sessionresponse.data);
                    console.log(err.sessionresponse.status);
                    console.log(err.sessionresponse.header);
                } else {
                    console.error(`Error: ${err.message}`);
                }
            }
        };
        fetchSessionDropdown();
    }, []);

    const [formdata, setformdata] = useState({
        firstName: "",
        middleName: "",
        lastName: "",
        nickName: "",
        birthCertificateNo: "",
        mobileNo: "",
        email: "",
        fatherFirstName: "",
        fatherMiddleName: "",
        fatherLastName: "",
        fatherNIDNo: "",
        motherFirstName: "",
        motherMiddleName: "",
        motherLastName: "",
        motherNIDNo: "",
    })

    const [firstnamelabel, setfirstnamelabel] = useState("")
    const [middlenamelabel, setmiddlenamelabel] = useState("")
    const [lastnamelabel, setlastnamelabel] = useState("")
    const [labelbirthcert, setlabelbirthcert] = useState("")
    const [labeldob, setlabeldob] = useState("")
    const [labelmobileno, setlabelmobileno] = useState("")
    const [labelgender, setlabelgender] = useState("")
    const [labelemail, setlabelemail] = useState("")
    const [labeladDate, setlabeladDate] = useState("")
    const [labelclass, setlabelclass] = useState("")
    const [labelsession, setlabelsession] = useState("")
    const [fatherfirstnamelabel, setfatherfirstnamelabel] = useState("")
    const [fathermiddlenamelabel, setfathermiddlenamelabel] = useState("")
    const [fatherlastnamelabel, setfatherlastnamelabel] = useState("")
    const [fathernidlabel, setfathernidlabel] = useState("")
    const [mothernidlabel, setmothernidlabel] = useState("")
    const [motherfirstnamelabel, setmotherfirstnamelabel] = useState("")
    const [mothermiddlenamelabel, setmothermiddlenamelabel] = useState("")
    const [motherlastnamelabel, setmotherlastnamelabel] = useState("")
    const [stupiclabel, setstupiclabel] = useState("")
    const [stubirthlabel, setstubirthlabel] = useState("")
    const [fnidlabel, setfnidlabel] = useState("")
    const [mnidlabel, setmnidlabel] = useState("")
    const [transimglabel, settransimglabel] = useState("")

    const [bordererrorfirstname, setbordererrorfirstname] = useState(false)
    const [bordererrormiddlename, setbordererrormiddlename] = useState(false)
    const [bordererrorlastname, setbordererrorlastname] = useState(false)
    const [bordererrorlabelbirthcert, setbordererrorlabelbirthcert] = useState(false)
    const [bordererrormobileno, setbordererrormobileno] = useState(false)
    const [bordererroeemail, setbordererroeemail] = useState(false)
    const [bordergender, setbordergender] = useState(false)
    const [borderclass, setborderclass] = useState(false)
    const [bordersection, setbordersection] = useState(false)
    const [bordererrorfatherfirstname, setbordererrorfatherfirstname] = useState(false)
    const [bordererrorfathermiddlename, setbordererrorfathermiddlename] = useState(false)
    const [bordererrorfatherlastname, setbordererrorfatherlastname] = useState(false)
    const [bordererrorfathernid, setbordererrorfathernid] = useState(false)
    const [bordererrormothernid, setbordererrormothernid] = useState(false)
    const [bordererrormotherfirstname, setbordererrormotherfirstname] = useState(false)
    const [bordererrormothermiddlename, setbordererrormothermiddlename] = useState(false)
    const [bordererrormotherlastname, setbordererrormotherlastname] = useState(false)
    const [bordererrorstupic, setbordererrorstupic] = useState(false)
    const [bordererrorstubirth, setbordererrorstubirth] = useState(false)
    const [bordererrorfnid, setbordererrorfnid] = useState(false)
    const [bordererrormnid, setbordererrormnid] = useState(false)

    async function ApplyOnline(mydata) {
        try {
            axios.post('https://sass.britishstandardschool.com/api/Public/ApplyOnline', {
                firstName: mydata.firstName,
                middleName: mydata.middleName,
                lastName: mydata.lastName,
                nickName: mydata.nickName,
                birthCertificateNo: mydata.birthCertificateNo,
                dob: dateofbirthnew,
                gender: drpsectionselectgender,
                mobileNo: mydata.mobileNo,
                email: mydata.email,
                admissionDate: dateofadmission,
                admissionClassId: classoptions,
                admissionSesstionId: drpsesstionselect,
                fatherFirstName: mydata.fatherFirstName,
                fatherMiddleName: mydata.fatherMiddleName,
                fatherLastName: mydata.fatherLastName,
                fatherNIDNo: mydata.fatherNIDNo,
                motherFirstName: mydata.motherFirstName,
                motherMiddleName: mydata.motherMiddleName,
                motherLastName: mydata.motherLastName,
                motherNIDNo: mydata.motherNIDNo,
                stuImg: studentpicfile,
                birthCertificateImg: studentbirthimgfile,
                transcriptImage: transcriptimg,
                fatherNidImg: fatherNIDimg,
                motherNidImg: matherNIDimg
            }, headers).then(function (response) {
                console.log(response)
                if (response.status == 200 && response.data.value == 1) {
                    navigate("/submitSuccess")
                }
                else {
                    navigate("/applyonline")
                }
            })

        } catch (err) {
            if (err) {
                console.error(err);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        console.log("clicked")


        console.log(formdata)
        console.log(dateofbirthnew)
        console.log(studentpicfile)

        if (formdata.firstName && formdata.firstName.length < 3 || formdata.firstName.length > 50) {
            setfirstnamelabel("3 to 50 characters required")
            setbordererrorfirstname(true)
        } else if (formdata.firstName.length == 0) {
            setfirstnamelabel("First Name Required")
            setbordererrorfirstname(true)
        }
        else {
            setfirstnamelabel("")
            setbordererrorfirstname(false)
        }
        if (formdata.middleName && formdata.middleName.length < 3 || formdata.middleName.length > 50) {
            setmiddlenamelabel("3 to 50 characters please")
            setbordererrormiddlename(true)
        } else if (formdata.middleName.length == 0) {
            setmiddlenamelabel("Middle name Required")
            setbordererrormiddlename(true)
        }
        else {
            setmiddlenamelabel("")
            setbordererrormiddlename(false)
        }
        if (formdata.lastName && formdata.lastName.length < 3 || formdata.lastName.length > 50) {
            setlastnamelabel("3 to 50 characters required")
            setbordererrorlastname(true)
        } else if (formdata.lastName.length == 0) {
            setlastnamelabel("Last name Required")
            setbordererrorlastname(true)
        }
        else {
            setlastnamelabel("")
            setbordererrorlastname(false)
        }
        if (formdata.birthCertificateNo.length > 0 && formdata.birthCertificateNo.length < 17) {
            setlabelbirthcert("17 digits required")
            setbordererrorlabelbirthcert(true)
        }
        else if (formdata.birthCertificateNo.length == 0) {
            setlabelbirthcert("Birth certificate number required")
            setbordererrorlabelbirthcert(true)
        }
        else {
            setlabelbirthcert("")
            setbordererrorlabelbirthcert(false)
        }
        if (dateofbirthnew.length == 0) {
            setlabeldob("Date Required")
        } else {
            setlabeldob("")
        }

        if (formdata.mobileNo.length > 0 && formdata.mobileNo.length < 11) {
            setlabelmobileno("Mobile no must be 11 digits")
            setbordererrormobileno(true)
        } else if (formdata.mobileNo.length == 0) {
            setlabelmobileno("Mobile no is required")
            setbordererrormobileno(true)
        }
        else {
            setlabelmobileno("")
            setbordererrormobileno(false)
        }
        if (drpsectionselectgender.length == 0) {
            setlabelgender("Gender reauired")
            setbordergender(true)
        }
        else {
            setlabelgender("")
            setbordergender(false)
        }

        if (formdata.email.length == 0) {
            setlabelemail("Email is required")
            setbordererroeemail(true)
        }
        else if (formdata.email && !/\S+@\S+\.\S+/.test(formdata.email)) {
            setlabelemail('Email format is incorrect')
            setbordererroeemail(true)
        }
        else {
            setlabelemail('')
            setbordererroeemail(false)
        }
        if (dateofadmission.length == 0) {
            setlabeladDate("Date Required")
        } else {
            setlabeladDate("")
        }
        if (classoptions.length == 0) {
            setlabelclass("Class reauired")
            setborderclass(true)
        }
        else {
            setlabelclass("")
            setborderclass(false)
        }
        if (drpsesstionselect.length == 0) {
            setlabelsession("Session reauired")
            setbordersection(true)
        }
        else {
            setlabelsession("")
            setbordersection(false)
        }
        if (formdata.fatherFirstName && formdata.fatherFirstName.length < 3 || formdata.fatherFirstName.length > 50) {
            setfatherfirstnamelabel("3 to 50 characters required")
            setbordererrorfatherfirstname(true)
        } else if (formdata.fatherFirstName.length == 0) {
            setfatherfirstnamelabel("First Name Required")
            setbordererrorfatherfirstname(true)
        }
        else {
            setfatherfirstnamelabel("")
            setbordererrorfatherfirstname(false)
        }
        if (formdata.fatherMiddleName && formdata.fatherMiddleName.length < 3 || formdata.fatherMiddleName.length > 50) {
            setfathermiddlenamelabel("3 to 50 characters please")
            setbordererrorfathermiddlename(true)
        } else if (formdata.fatherMiddleName.length == 0) {
            setfathermiddlenamelabel("Middle name Required")
            setbordererrorfathermiddlename(true)
        }
        else {
            setfathermiddlenamelabel("")
            setbordererrorfathermiddlename(false)
        }
        if (formdata.fatherLastName && formdata.fatherLastName.length < 3 || formdata.fatherLastName.length > 50) {
            setfatherlastnamelabel("3 to 50 characters required")
            setbordererrorfatherlastname(true)
        } else if (formdata.fatherLastName.length == 0) {
            setfatherlastnamelabel("Last name Required")
            setbordererrorfatherlastname(true)
        }
        else {
            setfatherlastnamelabel("")
            setbordererrorfatherlastname(false)
        }
        if (formdata.fatherNIDNo.length == 0) {
            setfathernidlabel("NID is required")
            setbordererrorfathernid(true)
        }
        else if (formdata.fatherNIDNo.length < 10) {
            setfathernidlabel("NID should be 10 - 17 numbers")
            setbordererrorfathernid(true)
        }
        else {
            setfathernidlabel("")
            setbordererrorfathernid(false)
        }
        if (formdata.motherNIDNo.length == 0) {
            setmothernidlabel("NID is required")
            setbordererrormothernid(true)
        }
        else if (formdata.motherNIDNo.length < 10) {
            setmothernidlabel("NID should be 10 - 17 numbers")
            setbordererrormothernid(true)
        }
        else {
            setmothernidlabel("")
            setbordererrormothernid(false)
        }

        if (formdata.motherFirstName && formdata.motherFirstName.length < 3 || formdata.motherFirstName.length > 50) {
            setmotherfirstnamelabel("3 to 50 characters required")
            setbordererrormotherfirstname(true)
        } else if (formdata.motherFirstName.length == 0) {
            setmotherfirstnamelabel("First Name Required")
            setbordererrormotherfirstname(true)
        }
        else {
            setmotherfirstnamelabel("")
            setbordererrormotherfirstname(false)
        }
        if (formdata.motherMiddleName && formdata.motherMiddleName.length < 3 || formdata.motherMiddleName.length > 50) {
            setmothermiddlenamelabel("3 to 50 characters please")
            setbordererrormothermiddlename(true)
        } else if (formdata.motherMiddleName.length == 0) {
            setmothermiddlenamelabel("Middle name Required")
            setbordererrormothermiddlename(true)
        }
        else {
            setmothermiddlenamelabel("")
            setbordererrormothermiddlename(false)
        }
        if (formdata.motherLastName && formdata.motherLastName.length < 3 || formdata.motherLastName.length > 50) {
            setmotherlastnamelabel("3 to 50 characters required")
            setbordererrormotherlastname(true)
        } else if (formdata.motherLastName.length == 0) {
            setmotherlastnamelabel("Last name Required")
            setbordererrormotherlastname(true)
        }
        else {
            setmotherlastnamelabel("")
            setbordererrormotherlastname(false)
        }

        if (!studentpicfile) {
            setbordererrorstupic(true)
            setstupiclabel("Select a file")
        } else {
            setbordererrorstupic(false)
            setstupiclabel("")
        }
        if (!studentbirthimgfile) {
            setbordererrorstubirth(true)
            setstubirthlabel("Select a file")
        } else {
            setbordererrorstubirth(false)
            setstubirthlabel("")
        }
        if (!fatherNIDimg) {
            setbordererrorfnid(true)
            setfnidlabel("Select a file")
        } else {
            setbordererrorfnid(false)
            setfnidlabel("")
        }
        if (!matherNIDimg) {
            setbordererrormnid(true)
            setmnidlabel("Select a file")
        } else {
            setbordererrormnid(false)
            setmnidlabel("")
        }


        if (firstnamelabel == "" &&
            middlenamelabel == "" && lastnamelabel == "" && labelbirthcert == "" && labeldob == "" && labelmobileno == "" && labelgender == "" && labelemail == "" && labeladDate == "" && labelclass == "" && labelsession == "" && fatherfirstnamelabel == "" && fathermiddlenamelabel == "" && fatherlastnamelabel == "" && fathernidlabel == "" && mothernidlabel == "" && motherfirstnamelabel == "" && mothermiddlenamelabel == "" && motherlastnamelabel == "" && studentpicfile && studentbirthimgfile && fatherNIDimg && matherNIDimg) {
            console.log("Done")
            await ApplyOnline(formdata)
        }
        else {
            console.log("not done")
        }
    }
    return (
        <>
            <div className="myContainer">
                <Form onSubmit={handleSubmit}>
                    <div className="studentinfo" data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos-delay="300" data-aos="fade-in" data-aos-duration="1000">Student Information</h4>

                        <Row>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>First Name*</p>
                                    <div className="inputgap">
                                        <input maxLength={50} style={bordererrorfirstname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='First Name' name="firstName" value={formdata.firstName} onChange={handleInput} />
                                        <label>{firstnamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Middle Name*</p>
                                    <div className="inputgap">
                                        <input maxLength={50} style={bordererrormiddlename ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='Middle Name' name="middleName" value={formdata.middleName} onChange={handleInput} />

                                        <label>{middlenamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Last Name*</p>
                                    <div className="inputgap">
                                        <input style={bordererrorlastname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} maxLength={50} type="text" placeholder='Last Name' name="lastName" value={formdata.lastName} onChange={handleInput} />
                                        <label>{lastnamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Nick Name</p>
                                    <div className="inputgap">
                                        <input maxLength={50} type="text" placeholder='Nick Name' name="nickName" value={formdata.nickName} onChange={handleInput} />

                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Birth Certificate No*</p>
                                    <div className="inputgap">
                                        <input style={bordererrorlabelbirthcert ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} maxLength={17} type="text" placeholder='Birth Certificate No' name="birthCertificateNo" value={formdata.birthCertificateNo} onChange={handleInput} />
                                        <label>{labelbirthcert}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Date of Birth*</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <div className="inputgap">
                                            <DatePicker


                                                value={dateofbirthnew}
                                                onChange={(newvaldob) => handleDateofbirth(newvaldob)}
                                                renderInput={(params) => <TextField {...params} inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Date of Birth"
                                                }} error={false} />}
                                            />
                                            <label>{labeldob}</label>
                                        </div>
                                    </LocalizationProvider>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Gender*</p>
                                    <div className="inputgap">
                                        <div className="dropdownwrapper" style={bordergender ? { border: "1px solid rgb(237, 28, 36)", borderRadius: "10px" } : { border: "1px solid transparent", borderRadius: "10px" }}>

                                            <Dropdown2 func={drpfunctiongender} myplaceholder="Select Gender" fontsize="18" fontfamily="'Poppins', sans-serif" options={genderresponse} />
                                        </div>
                                        <label>{labelgender}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Mobile No*</p>
                                    <div className="inputgap">
                                        <input onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} maxLength={11} style={bordererrormobileno ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='Mobile No' name="mobileNo" value={formdata.mobileNo} onChange={handleInput} />
                                        <label>{labelmobileno}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Email Address*</p>
                                    <div className="inputgap">
                                        <input type="text" style={bordererroeemail ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} placeholder='Email Address' name="email" value={formdata.email} onChange={handleInput} />
                                        <label>{labelemail}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="basicinputfield">
                                    <p>Admission Date*</p>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <div className="inputgap">

                                            <DatePicker

                                                value={dateofadmission}
                                                onChange={(newvaldob) => handleDateofadmission(newvaldob)}
                                                renderInput={(params) => <TextField {...params} inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Admission Date"
                                                }} error={false} />}
                                            />
                                            <label>{labeladDate}</label>
                                        </div>
                                    </LocalizationProvider>
                                </div>
                            </Col>
                            <Col lg={4}>
                                <div className="basicinputfield">
                                    <p>Select Class</p>
                                    <div className="inputgap">
                                        <div className="dropdownwrapper scrollabledrp" style={borderclass ? { border: "1px solid rgb(237, 28, 36)", borderRadius: "10px" } : { border: "1px solid transparent", borderRadius: "10px" }}>
                                            <Dropdown2 func={drpfuncClass} myplaceholder="Select Class" fontsize="18" fontfamily="'Poppins', sans-serif" options={classresponse} />
                                        </div>
                                        <label>{labelclass}</label>
                                    </div>
                                </div>

                            </Col>
                            <Col lg={4}>
                                <div className="basicinputfield">
                                    <p>Select Session</p>
                                    <div className="inputgap">
                                        <div className="dropdownwrapper" style={bordersection ? { border: "1px solid rgb(237, 28, 36)", borderRadius: "10px" } : { border: "1px solid transparent", borderRadius: "10px" }}>
                                            <Dropdown2 func={drpfunctionsesstion} myplaceholder="Select Session" fontsize="18" fontfamily="'Poppins', sans-serif" options={sessionresponse} />
                                        </div>
                                        <label>{labelsession}</label>
                                    </div>
                                </div>

                            </Col>
                        </Row>
                    </div>
                    <div className="fatherinfo" data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos-delay="300" data-aos="fade-in" data-aos-duration="1000">Father Information</h4>

                        <Row>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>First Name*</p>
                                    <div className="inputgap">
                                        <input maxLength={50} style={bordererrorfatherfirstname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='First Name' name="fatherFirstName" value={formdata.fatherFirstName} onChange={handleInput} />
                                        <label>{fatherfirstnamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Middle Name*</p>
                                    <div className="inputgap">
                                        <input maxLength={50} style={bordererrorfathermiddlename ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='Middle Name' name="fatherMiddleName" value={formdata.fatherMiddleName} onChange={handleInput} />

                                        <label>{fathermiddlenamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Last Name*</p>
                                    <div className="inputgap">
                                        <input style={bordererrorfatherlastname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} maxLength={50} type="text" placeholder='Last Name' name="fatherLastName" value={formdata.fatherLastName} onChange={handleInput} />
                                        <label>{fatherlastnamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>NID No*</p>
                                    <div className="inputgap">
                                        <input onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} maxLength={17} style={bordererrorfathernid ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='NID No' name="fatherNIDNo" value={formdata.fatherNIDNo} onChange={handleInput} />
                                        <label>{fathernidlabel}</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="motherinfo" data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos-delay="300" data-aos="fade-in" data-aos-duration="1000">Mother Information</h4>

                        <Row>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>First Name*</p>
                                    <div className="inputgap">
                                        <input maxLength={50} style={bordererrormotherfirstname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='First Name' name="motherFirstName" value={formdata.motherFirstName} onChange={handleInput} />
                                        <label>{motherfirstnamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Middle Name*</p>
                                    <div className="inputgap">
                                        <input maxLength={50} style={bordererrormothermiddlename ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='Middle Name' name="motherMiddleName" value={formdata.motherMiddleName} onChange={handleInput} />

                                        <label>{mothermiddlenamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>Last Name*</p>
                                    <div className="inputgap">
                                        <input style={bordererrormotherlastname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} maxLength={50} type="text" placeholder='Last Name' name="motherLastName" value={formdata.motherLastName} onChange={handleInput} />
                                        <label>{motherlastnamelabel}</label>
                                    </div>
                                </div>
                            </Col>
                            <Col lg={3}>
                                <div className="basicinputfield">
                                    <p>NID No*</p>
                                    <div className="inputgap">
                                        <input onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }} maxLength={17} style={bordererrormothernid ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder='NID No' name="motherNIDNo" value={formdata.motherNIDNo} onChange={handleInput} />
                                        <label>{mothernidlabel}</label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <div className="fileupload" data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
                        <h4 data-aos-delay="300" data-aos="fade-in" data-aos-duration="1000">Upload Documents</h4>
                        <Row>

                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Student Picture*</p>
                                    <div className="inputgap">
                                        <input style={bordererrorstupic ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="file" name="file" onChange={changeHandlerStudentpic} className="fileinputbutton" />
                                        <label>{stupiclabel}</label>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Student Birth Certificate Image</p>
                                    <div className="inputgap">
                                        <input style={bordererrorstubirth ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="file" name="birthimg" onChange={changeHandlerStudentbirthimg} className="fileinputbutton" />
                                        <label>{stubirthlabel}</label>
                                    </div>

                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Father's NID Image</p>
                                    <div className="inputgap">
                                        <input style={bordererrorfnid ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="file" name="birthimg" onChange={changeHandlerFatherNIDimg} className="fileinputbutton" />
                                        <label>{fnidlabel}</label>
                                    </div>


                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Mother's NID Image</p>
                                    <div className="inputgap">
                                        <input style={bordererrormnid ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="file" name="birthimg" onChange={changeHandlerMotherNIDimg} className="fileinputbutton" />
                                        <label>{mnidlabel}</label>
                                    </div>


                                </div>
                            </Col>
                            <Col lg={6}>
                                <div className="basicinputfield">
                                    <p>Transcript Image</p>
                                    <div className="inputgap">
                                        <input type="file" name="birthimg" onChange={changeHandlertranscriptimg} className="fileinputbutton" />

                                    </div>


                                </div>
                            </Col>
                        </Row>
                        <div className="read-more2 text-center">
                            <button type="submit">Submit</button>
                        </div>


                    </div>

                </Form>
            </div>
        </>
    )
}

export default ApplyOnlineComp