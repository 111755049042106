import React from 'react'
import StuDashCRoutineComp from '../../../components/dashboardComponents/studentDashboard/StuDashCRoutineComp'
import StuDashboardClassroutinemb from './../../../components/mobile/dashbordMobile/StuDashboardClassroutinemb';
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb';
import FooterMobile from '../../../components/mobile/FooterMobile';

const StuDashClassRPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">
                    <StuDashCRoutineComp/>

                </div>
            </div>
        </div>  
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="first"/>
           
            <StuDashboardClassroutinemb/>
            <FooterMobile/>
             

          </div>
        </div> 
    </>
  )
}

export default StuDashClassRPage