import React, { useState, useEffect } from 'react'
import { FiSearch } from 'react-icons/fi'
import { HiOutlineBell } from 'react-icons/hi'
import { Col, Row } from 'react-bootstrap'
import { MdHistory } from 'react-icons/md'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { useNavigate } from 'react-router-dom';
import api from "../../../Api/index";

const Headerpartbill = () => {

    const headers = {
        "X-localization": localStorage.getItem("lan"),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Access-Control-Allow-Methods": "*",
        "Access-Control-Max-Age": 3600,
    };

    const [studentID, setStudentId] = useState("");
    const [studentName, setStudentName] = useState("");
    const [studentClass, setStudentClass] = useState("");
    const [studentSession, setStudentSession] = useState("");
    const [studentSection, setStudentSection] = useState("");
    const [studentPeriod, setStudentPeriod] = useState("");
    const [todayDate, setTodayDate] = useState("");
    const [previousBills, setPreviousBills] = useState([]);
    const [response, setResponse] = useState([]);
    const navigate = useNavigate();

    const fetchBillInfoes = async (id) => {
        try {
            const response = await api.get("/Bill/" + id, headers);
            console.log(response);
            setStudentId(response.data.id);
            setResponse(response.data);
            setStudentName(response.data.name);
            setStudentClass(response.data.class);
            setStudentSession(response.data.sessionName);
            setStudentSection(response.data.section);
            setStudentPeriod(response.data.currentPeriodName);
            setPreviousBills(response.data.previousBills);
            setTodayDate(new Date().toDateString("dd/MM/yyyy"));
            console.log(response.data);
            
        } catch (err) {
            if (err.response) {
                console.error(err.response.data);
                console.log(err.response.status);
                console.log(err.response.header);
            } else {
                console.error(`Error: ${err.message}`);
            }
        }
    };

    const handleSubmit = (id) => {
        try {
            console.log(id);
            fetchBillInfoes(id);
        } catch (err) {
            if (err) {
                console.error(err);
            }
        }
    }

    const [windowSize, setWindowSize] = useState(getWindowSize());

   
    
    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    }, []);
    function getWindowSize() {
        const { innerWidth, innerHeight } = window;
        return { innerWidth, innerHeight };
    }

    const handleReceiveBill = () => {
        navigate('/billentry')
    }

    const handleSearch = (e) => {
        setStudentId(e.target.value)
    }
    return (
        <>
            <div className="headerpartbill">

                <div className="leftsidesearch">
                    <input type="number" name="studentID" placeholder='Search Student ID' value={studentID} onChange={(e) => handleSearch(e)} />
                    <span onClick={() => handleSubmit(studentID)}><FiSearch /></span>
                </div>

                <div className="rightsidestuff">
                    <div className="bellicon">
                        <HiOutlineBell />
                    </div>
                    <div className="profilesmallinfo">
                        <div className="profilepic">
                            <img src="./assets/images/billpage/headerprofilepic.png" alt="headerprofilepic" />
                        </div>
                        <div className="roleandid">
                            <div className="roleidwrapper">
                                <h5>Admin</h5>
                                <p>ID 1705032108</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
            <div className="basicinfobox">
                <h5>Basic Infomation</h5>
                <div className="basicinfopopulate">
                    <Row className="mx-0">
                        <Col lf={6}>
                            <Row>
                                <Col lg={12}>
                                    <p>Name</p>
                                    <input type="text" name="stdName" placeholder='' value={response.name} />
                                </Col>
                            </Row>
                            <Row style={{ marginTop: "15px" }}>
                                <Col lg={6}>
                                    <p>Class</p>
                                    <input type="text" name="stdClass" placeholder='' value={response.class} />
                                </Col>
                                <Col lg={6}>
                                    <p>Session</p>
                                    <input type="text" name="stdSession" placeholder='' value={studentSession} />
                                </Col>
                            </Row>
                        </Col>
                        <Col lf={6}>
                            <Col lf={6}>
                                <Row>
                                    <Col lg={6}>
                                        <p>ID</p>
                                        <input type="text" name="stdId" placeholder='' value={studentID} />
                                    </Col>
                                    <Col lg={6}>
                                        <p>Section</p>
                                        <input type="text" name="stdSection" placeholder='' value={studentSection} />
                                    </Col>
                                </Row>
                            </Col>
                            <Col lf={6}>
                                <Row style={{ marginTop: "15px" }}>
                                    <Col lg={6}>
                                        <p>Period</p>
                                        <input type="text" name="stdSession" placeholder='' value={studentPeriod} />
                                    </Col>
                                    <Col lg={6}>
                                        <p>Date</p>
                                        <input type="text" name="presentDate" placeholder='' value={todayDate} />
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </Row>

                </div>
            </div>
            <div className="lowerpart">
                <Row>
                    <Col lg={7}>
                        <div className="previoushistorylist">
                            <h5><span><MdHistory /></span>Previous Collection History</h5>
                            <table>
                                <tr>
                                    <th>Date</th>
                                    <th>Bill No.</th>
                                    <th>RCV Type</th>
                                    <th>Period</th>
                                    <th>Amount</th>
                                </tr>
                                
                                {previousBills.map((element) => {
                                    // {console.log(element)}
                                    return (
                                        element.rcvType.includes("W")?
                                        <tr>
                                            <td>{element.billDate}</td>
                                            <td>{element.billNo}</td>
                                            <td>{`O- ${element.rcvType}`}</td>
                                            <td>{element.period}</td>
                                            <td>{element.rcvAmount}</td>
                                        </tr>
                                        :
                                        <tr>
                                        <td>{element.billDate}</td>
                                        <td>{element.billNo}</td>
                                        <td>{element.rcvType}</td>
                                        <td>{element.period}</td>
                                        <td>{element.rcvAmount}</td>
                                    </tr>

                                    );
                                })}

                            </table>

                        </div>
                        <div className="prelistbottom">
                            <div className="prelistbtminner">
                                <h6>Total collection: 73604.00 BDT</h6>
                            </div>
                        </div>
                    </Col>
                    <Col lg={5} style={{ paddingLeft: "0", paddingRight: "15px" }} >
                        <div className="lowerrightwrap" style={{ flexDirection: "column", display: "flex", justifyContent: "space-between" }}>
                            <div className="wrapperforscrollbar2">
                                <div className="dueboard" style={{ flex: "1 1 40%" }}>
                                    <div className="headingwithiconfixed">
                                        <h5><span><MdHistory /></span>Due Information</h5>
                                        <p>You have total 5 months tuition fee due</p>
                                    </div>
                                    <ul>
                                        <li>
                                            <div className="left">
                                                <h5>22-Oct</h5>
                                            </div>
                                            <div className="right">
                                                <div className="redpill">
                                                    <p>Not Paid</p>
                                                </div>
                                                <div className="amount">
                                                    <p>12390.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h5>22-Oct</h5>
                                            </div>
                                            <div className="right">
                                                <div className="redpill">
                                                    <p>Not Paid</p>
                                                </div>
                                                <div className="amount">
                                                    <p>12390.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h5>22-Oct</h5>
                                            </div>
                                            <div className="right">
                                                <div className="redpill">
                                                    <p>Not Paid</p>
                                                </div>
                                                <div className="amount">
                                                    <p>12390.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h5>22-Oct</h5>
                                            </div>
                                            <div className="right">
                                                <div className="redpill">
                                                    <p>Not Paid</p>
                                                </div>
                                                <div className="amount">
                                                    <p>12390.00</p>
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="left">
                                                <h5>22-Oct</h5>
                                            </div>
                                            <div className="right">
                                                <div className="redpill">
                                                    <p>Not Paid</p>
                                                </div>
                                                <div className="amount">
                                                    <p>12390.00</p>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>

                                </div>
                                <div className="dueboardbottom" style={{ flex: "1 1 10%" }}>
                                    <div className="dueboardbottominner" onClick={handleReceiveBill}>
                                        <h6>Receive Bill</h6>
                                    </div>
                                </div>
                            </div>


                            <div className="notepart" style={{ flex: "1 1 50%" }}>
                                <h5><span><AiOutlineInfoCircle /></span>Note</h5>

                                <div className="noteboard">
                                    <p>No note available</p>
                                    <p>{windowSize.innerHeight}</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default Headerpartbill