export const menumgmtApi = {
    heading:[
        {
            head:"Menu Name"
        },
        {
            head:"URL"
        },
        {
            head:"Role Name"
        },
        {
            head:"Active From"
        },
        {
            head:"Active To"
        },
        {
            head:"Action"
        }
    ],
    data:[
        {
            id:1,
            menuname:"Home", 
            url:"www.fb.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:2,
            menuname:"About", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:3,
            menuname:"Service", 
            url:"www.twitter.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:4,
            menuname:"Home2", 
            url:"www.fb.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:5,
            menuname:"Home3", 
            url:"www.fb.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:6,
            menuname:"Home4", 
            url:"www.fb.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:7,
            menuname:"About2", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:8,
            menuname:"About3", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:10,
            menuname:"Home2", 
            url:"www.fb.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:11,
            menuname:"Home3", 
            url:"www.fb.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:12,
            menuname:"Home4", 
            url:"www.fb.com", 
            rolename:"admin",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:9,
            menuname:"About4", 
            url:"www.twitter.com", 
            rolename:"admin academic",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
     
       
    ]
}

export const menumgmtmodelApi = {
    heading : [
        {
            head:""
        },
        {
            head:"Menu Name"
        },
        {
            head:"URL"
        }
    ],
    data:[
        {
            
            username:"",
            usertype:"",
            status:""
        }
    ],
    selectRoles:{
        heading:[
            {
                
                head:""
            },
            {

                head:"Select Roles"
            }
        ],
        data:[
            {
                selectroles:""
            },
          
        ]
    },
    admindata:[
        {
            
            menuname:"name of menu",
            url:"www.fb.com",
            
        },
        {
            
            menuname:"name of menu 2",
            url:"www.twitter.com",
            
        },
        
    ]
}