import React from 'react'

const attachmentfilesapi = 
    {
        stupic:"assets/images/dashboards/adminDashboard/attachmentFiles/stupic.jpg",
        transcript:"assets/images/dashboards/adminDashboard/attachmentFiles/transcript.jpg",
        bcertificate:"assets/images/dashboards/adminDashboard/attachmentFiles/bcertificate.jpg",
        fnid:"assets/images/dashboards/adminDashboard/attachmentFiles/fnid.jpg",
        mnid:"assets/images/dashboards/adminDashboard/attachmentFiles/fnid.jpg"
    }


const AttachmentFiles = () => {
  return (
    <>
    
    </>
  )
}
export {attachmentfilesapi}
export default AttachmentFiles