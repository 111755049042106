import React from 'react'
import StuDashResultComp from '../../../components/dashboardComponents/studentDashboard/StuDashResultComp'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardResultmb from '../../../components/mobile/dashbordMobile/StuDashboardResultmb'
import FooterMobile from '../../../components/mobile/FooterMobile'

const StuDashResultPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">
                    <StuDashResultComp/>

                </div>
            </div>
        </div> 
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="seventh"/>
           
            <StuDashboardResultmb/>
             <FooterMobile/>

          </div>
        </div> 
    </>
  )
}

export default StuDashResultPage