import React from 'react'

const adminSidebarApi ={
    topPart:{
        img:"./assets/images/dashboards/adminDashboard/sidebar/BSSmediumlogo.png"
    },
    
    menuItem: [
        {
            name:"Home",
            link:"/admindashboard",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:true,
            drpdwn:[],
            activeicon:"./assets/images/dashboards/adminDashboard/sidebar/colorediconofmenu.png"
        },
        {
            name:"Flex",
            link:"",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:false,
            drpdwn:[
                {
                    item:"Flex Value",
                    link:"/flexvalueadashpage"
                },
                {
                    item:"Flex Value Set",
                    link:"/flexvaluesetadashpage"
                }
            ]
        },
        {
            name:"Result",
            link:"/AdminResult",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:false,
            drpdwn:[
                
            ]
        },
        {
            name:"Manage Online Admission",
            link:"/manageonlineadmission",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:false,
            drpdwn:[
                
            ],
            activeicon:"./assets/images/dashboards/adminDashboard/sidebar/colorediconofmenu.png"
        },
        {
            name:"Attendance Summary",
            link:"/attendancesummary",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:false,
            drpdwn:[
                
            ],
            activeicon:"./assets/images/dashboards/adminDashboard/sidebar/colorediconofmenu.png"
       
        },
        {
            name:"Role",
            link:"",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:false,
            drpdwn:[
                {
                    item:"Role",
                    link:"/admin/role"
                },
                {
                    item:"Role Management",
                    link:"/admin/rolemgmt"
                },
            ]
        },
        {
            name:"Menus",
            link:"",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:false,
            drpdwn:[
                {
                    item:"Menu",
                    link:"/admin/menu"
                },
                {
                    item:"Menu Management",
                    link:"/admin/menumanagement"
                },
            ]
        },
        {
            name:"Result",
            link:"",
            img:"./assets/images/dashboards/adminDashboard/sidebar/collapsedicon.png",
            active:false,
            drpdwn:[
                {
                    item:"Result Process",
                    link:"/admin/resultprocess"
                },
                {
                    item:"Report Card",
                    link:"/admin/reportcard"
                },
                {
                    item:"Student Position Process",
                    link:"/admin/stuposition"
                },
                {
                    item:"Result Report",
                    link:"/admin/resultreport"
                },
            ]
        },
    
    
    ]
}

const AdminSidebar = () => {
  return (
    <>
    
    </>
  )
}

export {adminSidebarApi}

export default AdminSidebar