import React, { useEffect, useState } from 'react'
import {Row,Col} from 'react-bootstrap'
import axios from 'axios';


const EventAndNews = () => {

  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  
  const [eventId, seteventId] = useState("");
  const [eventTitle, seteventTitle] = useState("");
  const [eventDescp, seteventDescp] = useState("");
  const [newsDate, setnewsDate] = useState("");
  const [activationDate, setactivationDate] = useState("");
  const [status, setstatus] = useState("");
  const [imagePath1, setimagePath1] = useState("");
  const [position, setposition] = useState("");
  const [Eventresponse, setEventResponse] = useState([]);
  
  useEffect(() => {
    const fetchEventNewsData= async () => {
      try {
        const myresponse = await axios.get("https://sass.britishstandardschool.com/api/Home/HomePageEventNews", headers);
        setEventResponse(myresponse.data)
        console.log(myresponse);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchEventNewsData();
    console.log(Eventresponse);
  }, []);
  return (
    <>
        <div className="myContainer">
            <div className="eventandnews" data-aos="fade-up" data-aos-duration="2000">
            <div className="headingdiv">
                <h3 className="appplybuttonother">Event & News</h3>

            </div>
                <Row className="eventcardsall">
                  {/* //{PaymentAmountresponse.slice(0, -1).map((item, key) => { */}
                  {Eventresponse.slice(0, +1).map((item, key)=> {
                    return(
                      <Col lg={6} md={12} className="eventcardfirst">
                    <div className="eventcardwrapper">
                      <div className="eventcard firstoneev">
                        <div className="eventcardimg">
                          {/* <img src="./assets/images/eventcardpic1.png" alt="eventcardpic" /> */}
                          <img src={"./assets/images/Event&News/" + Eventresponse[0].imagePath1}/>
                          
                          <div className="evcardlabel"><p>{item.newsDate}</p></div>
                        </div>
                        <div className="eventcardtext">
                          {/* <h4>Class 3 students working on their <br/> science project</h4> */}
                          <h4>{item.eventTitle}</h4>
                          <p>{item.eventDescp}</p>
                          {/* <p>To improve the human resources by educating  <br/> them on all round development of human being i.e. <br/> - development of all four levels</p> */}

                        </div>
                      </div>

                    </div>
                  </Col>
                    );
                    
                  })}
                  
                  <Col lg={6} md={12}>
                    <Row>
                    {Eventresponse.slice(1).map((item, key)=> {
                    return(
                      <Col lg={6} md={6} className="evxsmgap">
                        <div className="eventcardwrapper">
                            <div className="eventcard">
                              <div className="eventcardimg">
                                {/* <img src="./assets/images/eventcardpic2.png" alt="eventcardpic" /> */}
                                <img src={"./assets/images/Event&News/" + Eventresponse[key+1].imagePath1}/>
                                <div className="evcardlabel smallleft"><p>{item.newsDate}</p></div>
                              </div>
                              <div className="eventcardtext smallcard">
                                <p>{item.eventDescp}</p>
                              </div>
                            </div>

                          </div>
                      </Col>
                      );
                    
                    })}
                      {/* <Col lg={6} md={6} className="evxsmgap">
                          <div className="eventcardwrapper">
                            <div className="eventcard">
                              <div className="eventcardimg">
                                <img src="./assets/images/eventcardpic3.png" alt="eventcardpic" />
                                <div className="evcardlabel smallleft"><p>OCT 12, 2022</p></div>
                              </div>
                              <div className="eventcardtext smallcard">
                                <p>National Nature Summit organized by (NDNSC)</p>
                              </div>
                            </div>

                          </div>
                      </Col>
                      <Col lg={6} md={6} className="eventlowercardsmallgap">
                        <div className="eventcardwrapper">
                            <div className="eventcard">
                              <div className="eventcardimg">
                                <img src="./assets/images/eventcardpic4.png" alt="eventcardpic" />
                                <div className="evcardlabel smallleft"><p>SEP 29, 2022</p></div>
                              </div>
                              <div className="eventcardtext smallcard">
                                <p>Our Students enjoyed making Breakfast</p>
                              </div>
                            </div>

                          </div>
                      </Col>
                      <Col lg={6} md={6} className="eventlowercardsmallgap">
                          <div className="eventcardwrapper">
                            <div className="eventcard">
                              <div className="eventcardimg">
                                <img src="./assets/images/eventcardpic5.png" alt="eventcardpic" />
                                <div className="evcardlabel smallleft"><p>SEP 12, 2022</p></div>
                              </div>
                              <div className="eventcardtext smallcard">
                                <p>Senior KG enjoy their Art and Craft class</p>
                              </div>
                            </div>
                          </div>
                      </Col> */}
                    </Row>
                    
                  </Col>
                  
                </Row>
                
            </div>
        </div>
    </>
  )
}

export default EventAndNews