import React, { useEffect, useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { BsChevronDown } from 'react-icons/bs'
import { NavLink, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { elements } from 'chart.js';

const Mynavbar = () => {

  let navigate = useNavigate()

  const handleadmissionclick = () => {
    navigate('/admission')
  }
  let activeStyle = {
    color: "#ED1C24"
  };
  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [menuId, setmenuId] = useState("");
  const [menuName, setmenuName] = useState("");
  const [url, seturl] = useState("");
  const [sequence, setsequence] = useState("");
  const [parentMenuId, setparentMenuId] = useState("");
  const [childNavbarVm, setchildNavbarVm] = useState("");
  const [myresponse, setmyResponse] = useState([]);

  useEffect(() => {
    const fetchNavbar = async () => {
      try {
        const myresponse = await axios.get("https://sass.britishstandardschool.com/api/Home/HomePageNavbar", {}, headers);
        setmyResponse(myresponse.data);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchNavbar();
  }, []);
  const [temp, settemp] = useState(false)
  return (
    <>
      <Navbar id="mynavbar" className="mynavbar" expand="lg">
        <div className="myContainer">
          <div className="headerwrapper">
            <Navbar.Brand href="#" className="smallbrandv">
              <img
                src="./assets/images/logo/bsslogostudashboard.png"
                alt="bsslogostudashboard.png"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                {myresponse.map((element, index) => {
                  const isChildValue = element.childNavbarVm;
                  return (
                    <NavLink
                      key={element.menuId}
                      className="admissionclass"
                      to={element.url}
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                    >
                      {element.childNavbarVm.length != 0 ? (
                        <>
                          {element.menuName} <BsChevronDown />
                          <div className="admissionmenu">
                            <ul>
                              {element.childNavbarVm.map((item, key) => {
                                return (
                                  <li key={item.menuId}>
                                    <NavLink
                                      // to="/studentagecheck"
                                      to={item.url}
                                      style={({ isActive }) =>
                                        isActive ? activeStyle : undefined
                                      }
                                    >
                                      {item.menuName}
                                      {/* Candidate Age */}
                                    </NavLink>
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </>
                      ) : (
                        <>{element.menuName}</>
                      )}
                    </NavLink>
                  );
                })}
              </Nav>
            </Navbar.Collapse>
            <Link to="/applyonline" className="appplybutton">
              Apply Online{" "}
              <img src="./assets/images/icons/arrowright.png" alt="" />{" "}
            </Link>
          </div>
        </div>
      </Navbar>
    </>
  );
}

export default Mynavbar
