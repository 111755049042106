import React from 'react'
import TeadResultEntryComp from '../../../components/dashboardComponents/teacherDashboard/TeadResultEntryComp'
import TeaDashSidebarMB from '../../../components/mobile/dashbordMobile/TeacherDashboard/SubComponent/TeaDashSidebarMB'
import TeaResultEntryTab from '../../../components/mobile/dashbordMobile/TeacherDashboard/TeaResultEntryTab'
import FooterMobile from '../../../components/mobile/FooterMobile'

const TeadResultEntryPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
          <div className="desktop">
              <div className="studentdashboardwrapper">
                  <div className="overlayfordrpdwn"></div>
                  <div className="stu_sidebar">
                      <TeadResultEntryComp/>

                  </div>
              </div>
          </div>
          <div className="mobile">
              <div className="teacherdashboardmobilewrapper">
                 <TeaDashSidebarMB/>
                 <TeaResultEntryTab/>
                 <FooterMobile/>

              </div>
          </div> 
    </>
  )
}

export default TeadResultEntryPage