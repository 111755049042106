import React from 'react'
import ManageOnlineAdmissionComp from '../../components/adminDashboard/desktop/ManageOnlineAdmissionComp'
import '../../sassFiles/sassPages/admindashboard/manageonlineadmission.scss'
import '../../sassFiles/sassPages/admindashboard/mobile/manageonlineadMb.scss'
import AdDashSidebarmb from '../../components/mobile/dashbordMobile/AdminDashboard/AdDashSidebarmb'
import ManageOnlineAdMbComp from '../../components/adminDashboard/mobile/ManageOnlineAdMbComp'
const ManageOnlineAdmission = () => {
  return (
    <>
        <div className="desktop mngonlnadmsn">
            <ManageOnlineAdmissionComp/>
        </div>
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <AdDashSidebarmb/>
           
            <div className="mobilecontainer">
              <div className="heading text-center">
                <h3>Manage Online Admission</h3>
              </div>
              <ManageOnlineAdMbComp/>
            </div>
             

          </div>
        </div> 
    </>
  )
}

export default ManageOnlineAdmission