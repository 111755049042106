import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import CommonButton from '../components/subComponents/CommonButton';
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios';
import PropTypes from 'prop-types'


const getFreshModel = () => ({
  email: "",
  password: "",
});
export const LoginPageComp = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);

  const navigate = useNavigate()
  const [formdata, setFormdata] = useState({
    username: "",
    password: ""
  })

  const handleChange = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsSubscribed(current => !current);
  };

  const [LoginFormData, setLoginFormData] = useState({
    username: "",
    password: "",
  });
  const [Type, setuserType] = useState("");

  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
  }
  async function LoginUser() {
    try {
      axios.post('https://sass.britishstandardschool.com/api/Auth/Login', {
        username: formdata.username,
        password: formdata.password
      }, headers)
        .then(function (response) {
          console.log(response);
          if (response.status == 200 && response.title != "Unauthorized") {
            navigate("/teacherdashboard", response);
            //sessionStorage.setItem('jwttoken',response.data)
            window.token = response.data;
          } else {
            formdata.username = "";
            formdata.password = "";
            navigate("/userpassnotmatched");
            localStorage.setItem("authenticated", false);
          }
        })
    } catch (err) {
      if (err) {
        console.error(err);
      }
    }
  }

  const [userName, setUserName] = useState()
  const [password, setPassword] = useState()

  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    await LoginUser({
      userName,
      password
    })
    // console.log(token);
    // setToken(token)
  }

  // const handleLoginSubmit=(e)=>{
  //   e.preventDefault()
  //   console.log(formdata.username,formdata.password)
  //   // navigate('/aboutus');
  //   try {
  //     axios.post('https://sass.britishstandardschool.com/api/Auth/Login', {
  //       username: formdata.username,
  //       password: formdata.password
  //     },headers)
  //     .then(function (response) {
  //       console.log(response);
  //       console.log(response.data.userType);
  //       if (response.status == 200 && response.title != "Unauthorized") {
  //         navigate("/teacherdashboard", response);
  //         // if (response.data.userType == "stu"){
  //         //   localStorage.setItem("authenticated", true);
  //         //   navigate("/studashboard", response);
  //         // }
  //         // else if(response.data.userType == "emp"){
  //         //   localStorage.setItem("authenticated", true);
  //         //   navigate("/teacherdashboard", response);
  //         // }
  //       } else {
  //         formdata.username = "";
  //         formdata.password = "";
  //         navigate("/userpassnotmatched");
  //         localStorage.setItem("authenticated", false);
  //       }
  //     })
  //   } catch (err) {
  //     if (err) {
  //       console.error(err);
  //     } 
  //   }
  // }
  const handleInput = (e) => {
    setFormdata({ ...formdata, [e.target.name]: e.target.value })
  }
  return (
    <>
      <div className="myContainer">
        <Row>
          <Col lg={6} className="d-flex align-items-center">
            <div className="loginlogodiv">
              <img src="./assets/images/bssloginlogo.png" alt="" />
            </div>
          </Col>
          <Col>
            <div className="loginbox">
              <div className="loginboxinner">
                <h4>Login</h4>

                <div className="fieldboxforlogin">
                  <Form onSubmit={handleLoginSubmit}>
                    <p>User Name</p>
                    <input type="text" name="username" value={formdata.username} onChange={handleInput} />
                    <p>password</p>
                    <input type="password" name="password" value={formdata.password} onChange={handleInput} />

                    <div className="checkboxfull">

                      <input
                        type="checkbox"
                        value={isSubscribed}
                        onChange={handleChange}
                        id="remember"
                        name="subscribe"
                      />
                      <span>Remember Me</span>


                    </div>

                    <div className="buttonpartlogin">
                      <CommonButton buttonTitle="Login" />
                      <Link className="onlytextlink" to="/forgotpassword">Forgot Password?</Link>
                    </div>
                  </Form>

                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}
LoginPageComp.propTypes = {
  setToken: PropTypes.func.isRequired
}

// export {LoginUser}

export default LoginPageComp