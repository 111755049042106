import React,{useState} from 'react'
import {Row,Col,Nav,Tab,Tabs} from 'react-bootstrap'
import SidebarofTdash from './subComponents/SidebarofTdash';
import { DayPicker } from 'react-day-picker';
import Dropdown from 'react-dropdown';
import {BsChevronDown} from 'react-icons/bs'
import { attendanceApiteacher, periodsubjectdatateacher, salaryInformationteacher } from '../../../utils/DashboardApi/TeacherDashboardApi';
import { Doughnut } from "react-chartjs-2";
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import {HiOutlineBell} from 'react-icons/hi'
import { noticeData } from '../../../utils/DashboardApi/StudentDashboardApi';
const TeaDashComp = () => {
  const [selected, setSelected] = React.useState(Date);
  const [monthdrpdwnval,setmonthdrpdwnval]=useState("")
  const [yeardrpdwnval,setyeardrpdwnval]=useState("")
  
  const dayoptions = [
    'Select Day','Saturday','Sunday','Monday','Tuesday','Wednesday','Thursday','Frriday'
  ];
  const defaultOptiontype = dayoptions[0];
  const [drpday,setDrpday] = useState("")
  function drpfunctionyear(data){
    setyeardrpdwnval(data)
}


    const dropdownvalue=(e)=>{        
        setDrpday(e.value)
        console.log(drpday)
      }
      function drpfunctionmonth(data){
        setmonthdrpdwnval(data)
    }
    const optionmemonth = [
      {
          opt:'Jan'
      },
      {
          opt:'Feb'
      }
  ]
  const optionmeyear = [
    {
        opt:2023
    },
    {
        opt:2022
    }
]
const chart1color1 = "rgba(245, 141, 145, 1)";
    const chart1color2 = "rgba(127, 228, 169, 1)";
    const chart1color3 = "rgba(177, 206, 255, 1)";
    const [dashheaderchange,setdashheaderchange] = useState("Good Morning Farhana")
  return (
    <>
        <div className="teadashcomp">
            <SidebarofTdash/>
            <div className="rightpartcontentwrap">
            <div className="headerpartbill">
            
            <div className="leftsidecontent">
               <h3>{dashheaderchange}</h3>
            </div>
        
            <div className="rightsidestuff">
                <div className="bellicon">
                    <HiOutlineBell/>
                </div>
                <div className="profilesmallinfo">
                    <div className="profilepic">
                        <img src="./assets/images/dashboards/teacherDashboard/profile_pic.png" alt="profile_pic.png" />
                    </div>
                    <div className="roleandid">
                        <div className="roleidwrapper">
                            <h5>Farhana Ahmed</h5>
                            <p>ID 1309010495</p>
                        </div>
                    </div>
                </div>
            </div>
       
    
        </div>

            <div className="tabPane">
            <div className="tabpaneheightadjust dashboardTab">
                    <Row className="firstRow">
                    <Col lg={4} style={{paddingRight:"15px"}}>
                        <div className="samebox sameboxteacher sameboxroutine">
                            <div className="headerpart">
                                <div className="flexwrap">
                                    <h4>Class Routine</h4>
                                    <div className="dropdownwrapper">
                                        <Dropdown className='filterdropone' options={dayoptions} onChange={(e)=>dropdownvalue(e)} value={defaultOptiontype}  />
                                        <BsChevronDown/>
                                    </div>
                                </div>
                            </div>
                            <div className="routinetable">
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                periodsubjectdatateacher.heading.map((item,key)=>{
                                                    return(
                                                        <th>{item.head}</th>
                                                    )

                                                })
                                            }
                                        </tr>
                                    </thead>
                                    {
                                        drpday=="Saturday"?
                                        periodsubjectdatateacher.saturday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        drpday=="Sunday"?
                                        periodsubjectdatateacher.sunday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        
                                        :
                                        
                                        drpday=="Monday"?
                                        periodsubjectdatateacher.monday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        
                                        :
                                        drpday=="Tuesday"?
                                        periodsubjectdatateacher.tuesday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        drpday=="Wednesday"?
                                        periodsubjectdatateacher.wednesday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        drpday=="Thursday"?
                                        periodsubjectdatateacher.thursday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        drpday=="Friday"?
                                        periodsubjectdatateacher.friday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        :
                                        periodsubjectdatateacher.saturday.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.periodnumber} Period </td>
                                                    <td>{item.class}</td>
                                                    <td>{item.section}</td>
                                                </tr>
                                            )
                                        })
                                        
                                    }
                                </table>
                            </div>
                            <div className="extraclassbox">
                                <p>No Extra Class</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} style={{paddingLeft:"0"}}>
                    <div className="samebox sameboxteacher">
                            <div className="headerpart">
                                <div className="flexwrap">
                                    <h4>Attendance</h4>
                                    <p eventKey="second">View all</p>
                                </div>
                            </div>
                            <div className="drpmonthyearwrapper">
                                <div className="leftone">
                                    <Dropdown2 func={drpfunctionmonth} fontsize="12" fontfamily="'Poppins', sans-serif"  options={optionmemonth}/>
                                </div>
                                <div className="rightone">
                                    <Dropdown2 func={drpfunctionyear} fontsize="12" fontfamily="'Poppins', sans-serif"  options={optionmeyear}/>
                                </div>

                            </div>
                            <div className="chartwrapperheight">
                                {
                                    
                                    attendanceApiteacher.map((item,key)=>{
                                        return(
                                            yeardrpdwnval && monthdrpdwnval ?
                                            item.month.map((item2,key2)=>{
                                                if(item.yearnum==yeardrpdwnval && item2.monthname==monthdrpdwnval){
                                                    
                                                    return(
                                                        <>
                                                        
                                                        <Doughnut style={{margin:"0 auto",width:"250px",height:"250px"}} data={
                                                            {
                                                            labels:["absent","present","late"],
                                                            datasets:[{
                                                                data:[item2.data.absent,item2.data.present,item2.data.late],
                                                                backgroundColor:[
                                                                    chart1color1,
                                                                    chart1color2,
                                                                    chart1color3
                                                    
                                                                ],
                                                                borderWidth:0,
                                                                
                                                                
                                                            }],
                                                            options: {
                                                                plugins: {
                                                                  legend: {
                                                                    display: false
                                                                  }
                                                                }
                                                              }}
                                                        }/>
                                                        
                                                        </>
                                                    )
                                                }
                                            })
                                            :
                                            <Doughnut style={{margin:"0 auto",width:"250px",height:"250px"}} data={
                                                {
                                                    labels:["absent","present","late"],
                                                    datasets:[{
                                                        data:[50,10,40],
                                                        backgroundColor:[
                                                            chart1color1,
                                                            chart1color2,
                                                            chart1color3
                                            
                                                        ],
                                                        borderWidth:0,
                                                        
                                                        
                                                    }],
                                                    options: {
                                                        plugins: {
                                                          legend: {
                                                            display: false
                                                          }
                                                        }
                                                      }}
                                            }/>

                                        )
                                        
                                        
                                    })
                                    
                                }
                            </div>
                                
                        </div>
                    </Col>
                    <Col lg={4} style={{paddingLeft:"0",paddingRight:"15px"}}>
                    <div className="samebox sameboxteacher semesterprogress">
                            <div className="headerpart">
                                <div className="flexwrap">
                                    <h4>Semester Progress</h4>
                                    <p>View all</p>
                                </div>
                            </div>
                            <div className="progressboxmini">
                                <div className="progressminileft">
                                <CircularProgressbar
                                    value={91}
                                    text={`91%`}
                                    strokeWidth={10}
                                    styles={buildStyles({
                                    textColor: "#ED1C24",
                                    pathColor: "#ED1C24",
                                    trailColor: "white"
                                    })}
                                />
                                </div>
                                <div className="progressminiright">
                                    <p>Lesson progress</p>
                                    <h4><span style={{color:"#000"}}>30</span> / 33</h4>
                                </div>
                            </div>
                            <div className="progressboxmini">
                                <div className="progressminileft">
                                <img src="./assets/images/dashboards/teacherDashboard/progress/calendar.png" alt="calendar.png" />
                                </div>
                                <div className="progressminiright">
                                    <p>Semester Exam in</p>
                                    <h4><span style={{color:"#000"}}>7 Days</span></h4>
                                </div>
                            </div>
                    </div>
                    </Col>
                    </Row>
                    <Row className="secondRow"> 
                    <Col lg={4} style={{paddingRight:"15px"}}>
                        <div className="samebox sameboxteacher prevhistory">
                            <div className="headerpart">
                                <div className="flexwrap">
                                    <h4>Salary Information</h4>
                                    <p>View all</p>
                                </div>
                            </div>

                            <div className="smallheading">
                                <h5><span style={{fontWeight:"500"}}>Current Status:</span> {salaryInformationteacher.currentstatus}</h5>
                            </div>
                            <div className="cardbarprevphistory cardbarprevphistoryteacher">
                                <div className="paymenthistoryoverview">
                                    <div className="paymenthistoryovflexwrap">
                                        {
                                        salaryInformationteacher.salaryinfodetails.map((item,key)=>{
                                            return(
                                                <div className="payhpartcardbar">
                                                    <div className="monthdatewrapper">
                                                    <h3>{item.month}</h3>
                                                    <p>Date: {item.date}</p>
                                                    </div>
                                                    <div className="rightsidflexpart">
                                                        <div className="pillright">
                                                            <h6>Paid</h6>
                                                        </div>
                                                        <div className="amount">
                                                            <p>৳ {item.amount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col lg={4} style={{paddingLeft:"0",paddingRight:"15px"}}>
                       <div className="samebox sameboxteacher">
                        <div className="headerpart" style={{marginBottom:"0"}}>
                            <div className="flexwrap">
                                <div className="flexwrap2">
                                <h4>Notice </h4>
                               
                                </div>
                                <p>View more</p>
                            </div>
                        </div>
                        <div className="contentpart">
                        {
                            noticeData.map((item,key)=>{
                                return(
                                <div className="noticebarmini" key={key}>
                                    <div className="publishdatepart teacherpublishpart">
                                        <p>{item.publishDateDayMonth}</p>
                                        <p>{item.publishDateYear}</p>
                                    </div>
                                    <div className="noticeboxtextbox">
                                    <h5> {item.heading}</h5>                    
                                    <p>{item.noticeheading}</p>
                                    </div>                                    
                                </div>
                                )
                            })
                        }

                        </div>
                        
                    </div>
                    </Col>
                    <Col lg={4} style={{paddingLeft:"0",paddingRight:"15px"}}>
                        <div className="samebox sameboxteacher sameboxteacher sameboxcalender">
                        <DayPicker
                            mode="single"
                            selected={selected}
                            onSelect={setSelected}
                            showOutsideDays
                            />

                            <div className="eventbox">
                                <p>No Event</p>
                            </div>
                            
                        </div>
                    </Col>
                    </Row>

                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default TeaDashComp