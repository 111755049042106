
import React,{useState,useEffect} from 'react'
import '../../../sassFiles/sassPages/dashboards/srudentDashboards.scss'
import '../../../sassFiles/sassPages/dashboards/teacherDashboard.scss'
import '../../../sassFiles/mobile/sassPages/dashboardsmb/TeachDashmb.scss'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardmb from '../../../components/mobile/dashbordMobile/StuDashboardmb'
import TeaDashComp from '../../../components/dashboardComponents/teacherDashboard/TeaDashComp'
import TeacherDashSidebarmb from '../../../components/mobile/TeacherDashSidebarmb'
import TeaDashSidebarMB from '../../../components/mobile/dashbordMobile/TeacherDashboard/SubComponent/TeaDashSidebarMB'
import TeaDashmb from '../../../components/mobile/dashbordMobile/TeacherDashboard/TeaDashmb'
import FooterMobile from '../../../components/mobile/FooterMobile'

const TeaDashPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">                
                    <TeaDashComp/>
                    
                </div>
            </div>
        </div> 
        <div className="mobile">
        <div className="teacherdashboardmobilewrapper">
            {/* <StuDashNavbarSidebarmb redirectkey="first"/>
        
            <StuDashboardmb/> */}
            <TeaDashSidebarMB/>
            <TeaDashmb/>
            <FooterMobile/>
        </div>
        </div> 
    </>
  )
}

export default TeaDashPage