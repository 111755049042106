import React, { useEffect, useState } from 'react'
import Dropdown from 'react-dropdown';
import { BsChevronDown } from 'react-icons/bs'
import { Table } from 'react-bootstrap';
import Dropdown2 from './subComponents/CustomSubComponents/Dropdown2';
import axios from 'axios';
const SchoolTimingComp = () => {

  const [drpsectionselect, setdrpsection] = useState("")

  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };

  const [ClassId, setClassId] = useState("");
  const [ClassName, setclassName] = useState("");
  const [Shift, setShift] = useState("");
  const [ClassTime, setClassTime] = useState("");
  const [myresponse, setmyResponse] = useState([]);

  useEffect(() => {
    const fetchSchoolTiming = async () => {
      try {
        const myresponse = await axios.get("https://sass.britishstandardschool.com/api/Public/SchoolTiming", headers);
        setmyResponse(myresponse.data)
        console.log(myresponse);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchSchoolTiming();
    console.log(myresponse);
  }, []);

  const optionssection = [
    {
      opt: 'All Sections'
    },
    {
      opt: 'Junior Section'
    },
    {
      opt: 'Middle Section'
    },
    {
      opt: 'Senior Section'
    },
  ];
  const drpfunctionsection = (mysectionval) => {
    setdrpsection(mysectionval)
  }
  return (
    <>
      <div className="myContainer">
        <div
          className="studentagecompropdown"
          data-aos-delay="300"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="dropdownwrapper">
            <Dropdown2
              func={drpfunctionsection}
              myplaceholder="Select Section"
              fontsize="12"
              fontfamily="'Poppins', sans-serif"
              options={optionssection}
            />
          </div>
        </div>

        {drpsectionselect == "All Sections" ? (
          <Table
            className="juniortable"
            data-aos-delay="300"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <tbody>
              <tr>
                <th>Class</th>
                <th>Shift</th>
                <th>Time</th>
              </tr>
              {myresponse.map((element, index) => {
                return (
                  <>
                    <tr>
                      <td>{element.className}</td>
                      <td>{element.shift}</td>
                      <td>{element.classTime}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        ) : drpsectionselect == "Junior Section" ? (
          <Table
            className="juniortable"
            data-aos-delay="300"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <tbody>
              <tr>
                <th>Class</th>
                <th>Shift</th>
                <th>Time</th>
              </tr>
              {myresponse.map((element, index) => {
                if (element.classId <= 5)
                  return (
                    <>
                      <tr>
                        <td>{element.className}</td>
                        <td>{element.shift}</td>
                        <td>{element.classTime}</td>
                      </tr>
                    </>
                  );
              })}
            </tbody>
          </Table>
        ) : drpsectionselect == "Middle Section" ? (
          <Table
            className="juniortable"
            data-aos-delay="300"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <tbody>
              <tr>
                <th>Class</th>
                <th>Shift</th>
                <th>Time</th>
              </tr>

              {myresponse.map((element, index) => {
                if (element.classId > 5 && element.classId < 11)
                  return (
                    <>
                      <tr>
                        <td>{element.className}</td>
                        <td>{element.shift}</td>
                        <td>{element.classTime}</td>
                      </tr>
                    </>
                  );
              })}
            </tbody>
          </Table>
        ) : drpsectionselect == "Senior Section" ? (
          <Table
            className="juniortable"
            data-aos-delay="300"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <tbody>
              <tr>
                <th>Class</th>
                <th>Shift</th>
                <th>Time</th>
              </tr>
              {myresponse.map((element, index) => {
                if (element.classId > 10)
                  return (
                    <>
                      <tr>
                        <td>{element.className}</td>
                        <td>{element.shift}</td>
                        <td>{element.classTime}</td>
                      </tr>
                    </>
                  );
              })}
            </tbody>
          </Table>
        ) : (//Onpageload
          <Table
            className="juniortable"
            data-aos-delay="300"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <tbody>
              <tr>
                <th>Class</th>
                <th>Shift</th>
                <th>Time</th>
              </tr>
              {myresponse.map((element, index) => {
                return (
                  <>
                    <tr>
                      <td>{element.className}</td>
                      <td>{element.shift}</td>
                      <td>{element.classTime}</td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </Table>
        )}

        <div
          className="studentagesometext"
          data-aos-delay="300"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <h4>Attendance Information</h4>
          <ul>
            <li>
              <p>Student's Should come to school in time</p>
            </li>
            <li>
              <p>
                Student's will not be allowed to enter school after 08:30 am
                [Morning Shift] or 11:30 am [Day Shift]
              </p>
            </li>
            <li>
              <p>
                Student's will not be allowed to enter school if He/She is late
                for the third time in a month
              </p>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default SchoolTimingComp