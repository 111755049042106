import React from 'react'

const adminHeaderApi = {
    name:"Admin",
    id:"1705032108"
}

const HeaderApi = () => {
  return (
    <>

    </>
  )
}

export {adminHeaderApi}

export default HeaderApi