import React from 'react'
import StuDashLeaveFComp from '../../../components/dashboardComponents/studentDashboard/StuDashLeaveFComp'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardLeaveFormmb from './../../../components/mobile/dashbordMobile/StuDashboardLeaveFormmb';
import FooterMobile from '../../../components/mobile/FooterMobile';

const StuDashLeaveFPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">
                    <StuDashLeaveFComp/>

                </div>
            </div>
        </div> 
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="eighth"/>
           
            <StuDashboardLeaveFormmb/>
             <FooterMobile/>

          </div>
        </div> 
    </>
  )
}

export default StuDashLeaveFPage