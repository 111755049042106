import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import RedCardwithText from './subComponents/RedCardwithText'
import { noticelist } from '../utils/Homepage'
import { useNavigate } from 'react-router-dom';
import { noticedata, upcomingnoticedata } from '../utils/NoticePageApi';
import axios from 'axios';

const WhyChooseUs = () => {

  // $(window).load(function(){
  //   $(".static").addClass("aa")
  // })
  let navigate = useNavigate()
  const handleNoticeClick = (id) => {
    navigate(`/notice/latest/${id}`)
  }
  const handleNoticeClickupcoming = (id) => {
    navigate(`/notice/upcoming/${id}`)
  }

  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [NoticeId, setNoticeId] = useState("");
  const [NoticeHeader, setNoticeHeader] = useState("");
  const [NoticeBody, setNoticeBody] = useState("");
  const [NoticeDate, setNoticeDate] = useState("");
  const [myresponse, setmyResponse] = useState([]);


  useEffect(() => {

    const fetchHomePageNotice = async () => {
      try {
        const myresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Home/HomePageNotice", {},
          headers
        );
        setmyResponse(myresponse.data);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchHomePageNotice();
  }, []);
  return (
    <>
      <div className="myContainer">
        <div
          className="whychooseus"
          data-aos="fade-up"
          data-aos-duration="2000"
        >
          <Row>
            <Col lg={7} className="whyleft">
              <h4>Why Choose Us</h4>
              <p>
                The decision to become an exceptional is a highly personal one,
                based on a number of factors that you must weigh for yourself.
                It's not a step to be taken lightly. We are different because
              </p>

              <Row>
                <Col lg={6}>
                  <Row style={{ height: "50px", marginBottom: "25px" }}>
                    <Col lg={2}>
                      <div className="whysmbox">
                        <img src="./assets/images/wcucardicon1.png" alt="" />
                      </div>
                    </Col>
                    <Col lg={10} className="innerp d-flex align-items-center">
                      <p>Encourage contact between Students and Faculty</p>
                    </Col>
                  </Row>
                  <Row style={{ height: "50px", marginBottom: "25px" }}>
                    <Col lg={2}>
                      <div className="whysmbox">
                        <img src="./assets/images/wcucardicon3.png" alt="" />
                      </div>
                    </Col>
                    <Col lg={10} className="innerp d-flex align-items-center">
                      <p>Encourage Active Learning</p>
                    </Col>
                  </Row>
                  <Row style={{ height: "50px", marginBottom: "25px" }}>
                    <Col lg={2}>
                      <div className="whysmbox">
                        <img src="./assets/images/wcucardicon5.png" alt="" />
                      </div>
                    </Col>
                    <Col lg={10} className="innerp d-flex align-items-center">
                      <p>Emphasize time on task</p>
                    </Col>
                  </Row>
                </Col>
                <Col lg={6}>
                  <Row style={{ height: "50px", marginBottom: "25px" }}>
                    <Col lg={2}>
                      <div className="whysmbox">
                        <img src="./assets/images/wcucardicon2.png" alt="" />
                      </div>
                    </Col>
                    <Col lg={10} className="innerp d-flex align-items-center">
                      <p>Develop Reciprocity and cooperation among students</p>
                    </Col>
                  </Row>
                  <Row style={{ height: "50px", marginBottom: "25px" }}>
                    <Col lg={2}>
                      <div className="whysmbox">
                        <img src="./assets/images/wcucardicon4.png" alt="" />
                      </div>
                    </Col>
                    <Col lg={10} className="innerp d-flex align-items-center">
                      <p>Give Prompt Feedback</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={2}>
                      <div className="whysmbox">
                        <img src="./assets/images/wcucardicon6.png" alt="" />
                      </div>
                    </Col>
                    <Col lg={10} className="innerp d-flex align-items-center">
                      <p>Communicate high expectations</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col lg={5}>
              <div className="whyright">
                <h4>Notice</h4>

                <div className="extraforborder">
                  <div className="noticecase" id="noticecase">
                    <div className="tablelikediv">
                      {myresponse.map((element, index) => {
                        let trimmedString = element.body != null ? element.body.substr(0, 38) : "";
                        return (
                          <div
                            className="tbdiv"
                            onClick={() => handleNoticeClick(element.noticeId)}
                            key={element.noticeId}
                          >
                            <div className="flexwrap">
                              <h5>{element.header}</h5>
                              <p className="mainparagraph">
                                {trimmedString}...
                              </p>
                              <div className="cald">
                                <img
                                  src="./assets/images/icons/cal.png"
                                  alt=""
                                />
                                <p>{element.noticeDate}</p>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default WhyChooseUs