export const SET_VARIABLE = 'SET_VARIABLE';

export const SET_OBJECT = "SET_OBJECT";
// Define action creator
export const setVariable = (value) => ({
  type: SET_VARIABLE,
  payload: value,
});
export const setObject = (value) => ({
  type: SET_OBJECT,
  payload:value  
});