import React,{useState,useEffect} from 'react'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import {Row,Col} from 'react-bootstrap'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { optionssession,optionsClass,optionspromotion } from '../../Helper'
import { reportCardApi } from '../../../utils/AdminDashApi/ReportCardApi'
import ReactPaginate from 'react-paginate';
import { resultReportApi } from '../../../utils/AdminDashApi/ResultReportApi'
const ResultReportComp = () => {
    const [drpsectionselect,setdrpsection] = useState("")
    const [drpclassselect,setdrpclassselect] = useState("")
    const [drppromotionselect,setdrppromotionselect] = useState("")
    const [paginatenumselected,setpaginatenumselected] = useState(0)
    const drpfuncsession=(mysectionval)=>{
        setdrpsection(mysectionval)
    }
    const drpfunctionclass=(mysectionval)=>{
        setdrpclassselect(mysectionval)
    }
    const drpfunctionpromotion=(mysectionval)=>{
        setdrppromotionselect(mysectionval)
    }
   
    const handleProcess=()=>{
        // Process Code
    }
    const [show,setShow] = useState("")
    const [activefrom,setactivefrom] = useState("")
    const drpfuncshow=(myshow)=>{
        setShow(myshow)
      }
      const optionsShowt = [
        {
          id:1,
          opt:1
        },
        {
          id:2,
          opt:2
        },
        {
          id:3,
          opt:3
        },
      ]
      const handlesearch =()=>{
    
        setactivefrom("")
      
    }
    const [currentItems, setCurrentItems] = useState(null);
      const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage,setitemsPerPage] = useState(10);
      useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(resultReportApi.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(resultReportApi.data.length / itemsPerPage));
      }, [itemOffset, itemsPerPage]);
      const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % resultReportApi.data.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setpaginatenumselected(event.selected);
        setItemOffset(newOffset);
      };
      const handleFirstfilter=()=>{
        console.log(show,drpsectionselect,drpclassselect,drppromotionselect)
      }
  return (
    <>
         <div className="d-flex ">

<SidebarOfAdash/>
<div className="contentpartwidthwrapper">
  <HeaderofAdash head="Result Report"/>
  <div className="contentofadmindash contentflexvalue">
     <Row>
        <Col lg={10} className="m-auto">

            <Row>
                <Col lg={3}>
                    <div className="dropdownwrapper">
                        <Dropdown2 func={drpfuncsession} myplaceholder="Select Session" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionssession}/>
                    </div>
                
                </Col>
                <Col lg={3}>
                    <div className="dropdownwrapper">
                        <Dropdown2 func={drpfunctionclass} myplaceholder="Select Class" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionsClass}/>
                    </div>
                
                </Col>
                <Col lg={3}>
                    <div className="dropdownwrapper">
                        <Dropdown2 func={drpfunctionpromotion} myplaceholder="Promotional Status" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionspromotion}/>
                    </div>
                
                </Col>
                <Col lg={3}>
                    <div className="commonadminbutton">
                        <button onClick={handleFirstfilter}>Search</button>
                    </div>
                </Col>
            </Row>
            
        </Col>
     </Row>
     <Row style={{marginTop:"20px"}}>
                <Col className="m-auto" lg={4}>
                  <Row>
                    <Col lg={3}>
                    <div className="basicinput">
                      <p>Show</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                      </div>
                      </div>
                    </Col>
                    <Col lg={9}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" />
                              <img onClick={handlesearch} src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <div className="resultreporttable">
              <Row>
                <Col lg={10} className="m-auto">
                  <div className="admincommontable">
                    <table>
                      <thead>
                        <tr>
                            {
                              resultReportApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItems?

                          currentItems.map((item,key)=>{
                            return(
                              <tr>
                                <td>{item.sl}</td>                                
                                <td>{item.name}</td>
                                <td>{item.class}</td>
                                <td>{item.obtainedmarks}</td>
                                <td>{item.pic}</td>
                                <td>{item.pis}</td>
                                <td>{item.status}</td>
                                
                              </tr>
                            )
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">

                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffset+1} to {itemOffset+10} of {resultReportApi.data.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                </Col>
               
              </Row>
              </div>

  </div>

</div>
</div>
    </>
  )
}

export default ResultReportComp