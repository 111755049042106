import React from 'react'
import '../../sassFiles/sassPages/admindashboard/resultProcess.scss'
import ResultReportComp from '../../components/adminDashboard/desktop/ResultReportComp'

const ReesultReportPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
        <div className="overlayfordrpdwn"></div>
            <ResultReportComp/>
        </div>
    </>
  )
}

export default ReesultReportPage