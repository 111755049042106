import React,{useState,useEffect} from 'react'
import StudentDashComp from '../../../components/dashboardComponents/studentDashboard/StudentDashComp'
import '../../../sassFiles/sassPages/dashboards/srudentDashboards.scss'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardmb from '../../../components/mobile/dashbordMobile/StuDashboardmb'
const StuDashPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
    
      
  return (
    <>
         <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">                
                    <StudentDashComp/>
                    
                </div>
            </div>
        </div> 
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="first"/>
           
            <StuDashboardmb/>
             

          </div>
        </div> 
    </>
  )
}

export default StuDashPage