import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import $ from 'jquery'
import { oldernoticedata } from '../utils/NoticePageApi'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
const OlderNoticePartNoticePage = () => {
  let navigate = useNavigate()
  useEffect(() => {
    $(function noticehover() {

      let x, y;
      $(".noticebox").on('mouseenter', function (e) {
        x = e.pageX - $(this).offset().left;
        y = e.pageY - $(this).offset().top;
        $(this).find("span").css({
          top: y,
          left: x
        });
      });
      $(".noticebox").on('mouseout', function (e) {
        x = e.pageX - $(this).offset().left;
        y = e.pageY - $(this).offset().top;
        $(this).find("span").css({
          top: y,
          left: x
        });
      });
    });
  }, [])

  const handleNoticeSinglePage = (id) => {
    navigate(`/notice/older/${id}`)
  }

  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };

  const [NoticeId, setNoticeId] = useState("");
  const [NoticeHeader, setNoticeHeader] = useState("");
  const [NoticeBody, setNoticeBody] = useState("");
  const [NoticeDate, setNoticeDate] = useState("");
  const [myresponse, setmyResponse] = useState([]);

  useEffect(() => {

    const fetchOlderNotice = async () => {
      try {
        const myresponse = await axios.get("https://sass.britishstandardschool.com/api/Public/OlderNotice", {}, headers);
        setmyResponse(myresponse.data)
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };

    fetchOlderNotice();
    console.log(myresponse);
  }, []);

  return (
    <>
      <div className="oldernoticepartnoticepage">
        <div className="myContainerMini">

          <div className="subheadingandparagraph" data-aos-delay="600" data-aos="fade-in" data-aos-duration="1000">
            <h3>Older</h3>
          </div>

          <Row className="noticecontent noticecontentolder">
            {myresponse.map((element, index) => {
              let trimmedString = String(element.body).substr(0, 38)
              console.log(myresponse);

              return (
                <div className="myContainermini" key={element.noticeId}>
                  <Col lg={12} md={12}>
                    <div className="noticebox hoverscale noticeboxleft upcoming" data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000" onClick={() => handleNoticeSinglePage(element.noticeId)}>
                      <span></span>
                      <div className="flexwrap">
                        <div className="noticeheading">
                          <h4>{element.header}</h4>
                        </div>
                        <p className='mainparagraph'>

                          {trimmedString}...
                        </p>
                        <div className="lastrow d-flex">
                          <img src="./assets/images/icons/calfaded.png" alt="" />
                          <p>{element.noticeDate}</p>

                        </div>
                      </div>
                    </div>
                  </Col>

                </div>
              )
            })}

          </Row>
        </div>
      </div>
    </>
  )
}

export default OlderNoticePartNoticePage