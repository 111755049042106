import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Nav, Tab, Button } from 'react-bootstrap'
import { prevPaymentHistory, paymentHistoryDue, documentData, periodsubjectdata } from '../../../utils/DashboardApi/StudentDashboardApi'
import Dropdown from 'react-dropdown';
import { Doughnut } from "react-chartjs-2";
import { BsChevronDown } from 'react-icons/bs'
import { DayPicker } from 'react-day-picker';
import { headerprops,notificationstuboxApi } from '../../../utils/DashboardApi/StudentDashboardApi';

import HeaderOfstuDashComp from './subComponents/HeaderOfstuDashComp';
import SidebarOfsDash from './subComponents/SidebarOfsDash';
import NotificationboxComp from '../commonSybcomponent/NotificationboxComp';
const StudentDashComp = () => {
    const [notices, setNotices] = useState([]);
    const [prevAttendance, setPrevAttendance] = useState({});
    const [currentAttendance, setCurrentAttendance] = useState({});

    const fetchNotices = (async () => {
        const response = await (await fetch("https://sass.britishstandardschool.com/api/Public/LatestNotice")).json();
        setNotices(response);
    });

    const fetchAtt = (async () => {
        const response = await (await fetch("https://sass.britishstandardschool.com/api/StudentDashboard/GetAttendancePercentage?studentId=" + headerprops.id)).json();
        console.log(response.value)
        setPrevAttendance(response.value[0]);
        setCurrentAttendance(response.value[1]);
        console.log(prevAttendance)
    })

    useEffect(() => {
        fetchNotices();
        fetchAtt()
    }, [])

    const [selected, setSelected] = React.useState(Date);
    const navigate = useNavigate()
    const handleSelecttab = (mylink) => {
        navigate(mylink)
    }
    const handlelogout = () => {
        navigate('/')
    }
    const chart1color1 = "rgba(245, 141, 145, 1)";
    const chart1color2 = "rgba(127, 228, 169, 1)";
    const chart1color3 = "rgba(177, 206, 255, 1)";
    const chdata = {
        labels: ["absent", "present", "late"],
        datasets: [{
            data: [prevAttendance.PreviousAbsent, prevAttendance.PreviousPresent, prevAttendance.PreviousLate],
            backgroundColor: [
                chart1color1,
                chart1color2,
                chart1color3

            ],
            borderWidth: 0,


        }],
        options: {
            plugins: {
                legend: {
                    display: false
                }
            }
        }

    }
    const chdata2 = {
        labels: ["absent", "present", "late"],
        datasets: [{
            data: [currentAttendance.CurrentAbsent, currentAttendance.CurrentPresent, currentAttendance.CurrentLate],
            backgroundColor: [
                chart1color1,
                chart1color2,
                chart1color3

            ],
            borderWidth: 0,


        }],

    }
    const [drpday, setDrpday] = useState("")
    const dropdownvalue = (e) => {
        // console.log(e.value)
        setDrpday(e.value)
        console.log(drpday)
    }

    const dayoptions = [
        'Select Day', 'Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Frriday'
    ];
    const defaultOptiontype = dayoptions[0];

    const fetchDocuments = async (id, fileType) => {
        let hwAPIString = "https://sass.britishstandardschool.com/api/StudentDashboard/";
        console.log(id);

        if (fileType === "HW") {
            hwAPIString = "https://sass.britishstandardschool.com/api/StudentDashboard/DownloadTodaysHomework?studentID=" + id;
        }
        else if (fileType === "CW") {
            hwAPIString = "https://sass.britishstandardschool.com/api/StudentDashboard/DownloadTodaysClasswork?studentID=" + id;
        }
        else if (fileType === "SYL") {
            hwAPIString = "https://sass.britishstandardschool.com/api/StudentDashboard/DownloadSyllabus?studentID=" + id;
        }
        else if (fileType === "ASSES") {
            hwAPIString = "https://sass.britishstandardschool.com/api/StudentDashboard/DownloadTodaysAssessment?studentID=" + id;
        }
        else if (fileType === "EXP") {
            hwAPIString = "https://sass.britishstandardschool.com/api/StudentDashboard/DownloadTodaysExampaper?studentID=" + id;
        }
        console.log(hwAPIString)
        try {
            const response = await fetch(hwAPIString)
            const blob = await response.blob();
            console.log(blob.name)
            const a = document.createElement('a');
            a.href = hwAPIString;
            document.body.appendChild(a);
            a.click();
            a.remove();
        } catch (err) {
            if (err.response) {
                console.error(err.response.data);
                console.log(err.response.status);
                console.log(err.response.header);
            } else {
                console.error(`Error: ${err.message}`);
            }
        }
    }
    const [shownotificationbox,setshownotificationbox] = useState(false)
    
    function funcnotification(){
        
        setshownotificationbox(!shownotificationbox);
      }

    return (
        <>

            <div className="stu_dash_mainWrap">
                <SidebarOfsDash activeID="0" />
                <div className="rightpartcontentwrap">
                    <HeaderOfstuDashComp func={funcnotification} heading="Good Morning Jisan" myprops={headerprops} />
                    {
                        shownotificationbox == true ?
                        <NotificationboxComp api={notificationstuboxApi}/>
                        :
                        ""
                    }
                    <div className="tabPane">
                        <div className="tabpaneheightadjust">
                            <Row style={{ flex: "1 1 50%" }}>
                                <Col lg={4} style={{ paddingRight: "15px" }}>
                                    <div className="samebox">
                                        <div className="headerpart">
                                            <div className="flexwrap">
                                                <h4>Documents</h4>
                                                <p style={{ cursor: "pointer" }} onClick={() => handleSelecttab('/studashboard/document')} >View more</p>
                                            </div>
                                        </div>
                                        <div className="contentpart" >
                                            <div className="smallbarcard" >
                                                <img src="./assets/images/dashboards/studentDashboard/smallbaricon1.png" alt="smallbaricon1.png" />

                                                <div className="textssmallbar">
                                                    <div className="flexwrap">
                                                        <h5>{documentData.homework.filetype}</h5>
                                                        <p>{documentData.homework.numberOfFiles == 0 ? "No File Available For Download" : documentData.homework.numberOfFiles + " files available for download"} </p>
                                                    </div>
                                                </div>
                                                <div className="downloadbutton" onClick={() => fetchDocuments(headerprops.id, "HW")}>
                                                    <img src="./assets/images/dashboards/studentDashboard/downloadimg.png" alt="downloadimg.png" />
                                                </div>
                                            </div>
                                            <div className="smallbarcard" >
                                                <img src="./assets/images/dashboards/studentDashboard/smallbaricon2.png" alt="smallbaricon1.png" />
                                                <div className="textssmallbar">
                                                    <div className="flexwrap">
                                                        <h5>{documentData.classwork.filetype}</h5>
                                                        <p>{documentData.classwork.numberOfFiles == 0 ? "No File Available For Download" : documentData.homework.numberOfFiles + " files available for download"} </p>
                                                    </div>
                                                </div>
                                                <div className="downloadbutton">
                                                    <img src="./assets/images/dashboards/studentDashboard/downloadimg.png" alt="downloadimg.png" />
                                                </div>
                                            </div>
                                            <div className="smallbarcard" >
                                                <img src="./assets/images/dashboards/studentDashboard/smallbaricon3.png" alt="smallbaricon1.png" />
                                                <div className="textssmallbar">
                                                    <div className="flexwrap">
                                                        <h5>{documentData.assesment.filetype}</h5>
                                                        <p>{documentData.assesment.numberOfFiles == 0 ? "No File Available For Download" : documentData.homework.numberOfFiles + " files available for download"} </p>
                                                    </div>
                                                </div>
                                                <div className="downloadbutton">
                                                    <img src="./assets/images/dashboards/studentDashboard/downloadimg.png" alt="downloadimg.png" />
                                                </div>
                                            </div>
                                            <div className="smallbarcard" >
                                                <img src="./assets/images/dashboards/studentDashboard/smallbaricon4.png" alt="smallbaricon1.png" />
                                                <div className="textssmallbar">
                                                    <div className="flexwrap">
                                                        <h5>{documentData.exampaper.filetype}</h5>
                                                        <p>{documentData.exampaper.numberOfFiles == 0 ? "No File Available For Download" : documentData.homework.numberOfFiles + " files available for download"} </p>
                                                    </div>
                                                </div>
                                                <div className="downloadbutton">
                                                    <img src="./assets/images/dashboards/studentDashboard/downloadimg.png" alt="downloadimg.png" />
                                                </div>
                                            </div>
                                            <div className="smallbarcard" >
                                                <img src="./assets/images/dashboards/studentDashboard/smallbaricon5.png" alt="smallbaricon1.png" />
                                                <div className="textssmallbar">
                                                    <div className="flexwrap">
                                                        <h5>{documentData.syllabus.filetype}</h5>
                                                        <p>{documentData.syllabus.numberOfFiles == 0 ? "No File Available For Download" : documentData.homework.numberOfFiles + " files available for download"} </p>
                                                    </div>
                                                </div>
                                                <div className="downloadbutton">
                                                    <img src="./assets/images/dashboards/studentDashboard/downloadimg.png" alt="downloadimg.png" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} style={{ paddingLeft: "0", paddingRight: "15px" }}>
                                    <div className="samebox">
                                        <div className="headerpart">
                                            <div className="flexwrap">
                                                <div className="flexwrap2">
                                                    <h4>Notice </h4>

                                                </div>
                                                <p style={{ cursor: "pointer" }} onClick={() => handleSelecttab('/studashboard/notice')} >View more</p>
                                            </div>
                                        </div>
                                        <div className="contentpart">
                                            {
                                                notices.map((item, key) => {
                                                    return (
                                                        <div className="noticebarmini" key={key}>
                                                            <div className="publishdatepart">
                                                                <p>{item.noticeDate.split(',')[0]}</p>
                                                                <p>{item.noticeDate.split(',')[1]}</p>
                                                            </div>
                                                            <div className="noticeboxtextbox">
                                                                <h5 style={{ paddingLeft: "30" }}> {item.header}</h5>
                                                                <p>{item.body}</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} style={{ paddingLeft: "0" }}>
                                    <div className="samebox">
                                        <div className="headerpart">
                                            <div className="flexwrap">
                                                <h4>Attendence</h4>
                                                <p eventKey="second" style={{ cursor: "pointer" }} onClick={() => handleSelecttab('/studashboard/attendance')}>View all</p>
                                            </div>
                                        </div>
                                        <div className="chartidtabd">
                                            <Tab.Container id="chartidtabs" defaultActiveKey="first-chart">
                                                <Nav variant="pills" className="flex-row">
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="first-chart">First Semester</Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="second-chart">Second Semester</Nav.Link>
                                                    </Nav.Item>
                                                </Nav>
                                                <Tab.Content>
                                                    <Tab.Pane eventKey="first-chart">
                                                        <div className="presenttext">
                                                            <p>{JSON.stringify(prevAttendance.PreviousPresent)}%</p>
                                                            <p>present</p>
                                                        </div>
                                                        <div className="latetext">
                                                            <p>{JSON.stringify(prevAttendance.PreviousLate)}%</p>
                                                            <p>late</p>
                                                        </div>
                                                        <div className="absenttext">
                                                            <p >{JSON.stringify(prevAttendance.PreviousAbsent)}%</p>
                                                            <p>absent</p>
                                                        </div>
                                                        <div className="chartwrapperheight">
                                                            <Doughnut style={{ margin: "0 auto", width: "250px", height: "250px" }} data={chdata} />

                                                        </div>
                                                    </Tab.Pane>
                                                    <Tab.Pane eventKey="second-chart">
                                                        <div className="presenttext">
                                                            <p>{currentAttendance.CurrentPresent}%</p>
                                                            <p>present</p>
                                                        </div>
                                                        <div className="latetext">
                                                            <p>{currentAttendance.CurrentLate}%</p>
                                                            <p>late</p>
                                                        </div>
                                                        <div className="absenttext">
                                                            <p >{currentAttendance.CurrentAbsent}%</p>
                                                            <p>absent</p>
                                                        </div>
                                                        <div className="chartwrapperheight">

                                                            <Doughnut style={{ margin: "0 auto", width: "50%!important", height: "50%!important" }} data={chdata2} />
                                                        </div>

                                                    </Tab.Pane>
                                                </Tab.Content>

                                            </Tab.Container>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="secondRow" style={{ flex: "1 1 50%" }}>
                                <Col lg={4} style={{ paddingRight: "15px" }}>
                                    <div className="samebox prevhistory">
                                        <div className="headerpart">
                                            <div className="flexwrap">
                                                <h4>Payment History</h4>
                                                <p style={{ cursor: "pointer" }} onClick={() => handleSelecttab('/studashboard/billinfo')}>View all</p>
                                            </div>
                                        </div>

                                        <div className="smallheading">
                                            <h5>{paymentHistoryDue.dueformonths == 1 ? `You have total ${paymentHistoryDue.dueformonths} month tuition fee due` : paymentHistoryDue.dueformonths > 1 ? `You have total ${paymentHistoryDue.dueformonths} months tuition fee due` : "You have no tution fee due"}</h5>
                                        </div>
                                        <div className="cardbarprevphistory">
                                            <div className="duepartcardbar">
                                                <h3>Due Amount</h3>
                                                <div className="rightsidflexpart">
                                                    <div className="pillright">
                                                        <h6>Not Paid</h6>
                                                    </div>
                                                    <div className="amount">
                                                        <p>৳ {paymentHistoryDue.dueAmount}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="paymenthistoryoverview">
                                                <div className="paymenthistoryovflexwrap">
                                                    {
                                                        prevPaymentHistory.map((item, key) => {
                                                            return (
                                                                <div key={key} className="payhpartcardbar">
                                                                    <div className="monthdatewrapper">
                                                                        <h3>{item.month}</h3>
                                                                        <p>Date: {item.date}</p>
                                                                    </div>
                                                                    <div className="rightsidflexpart">
                                                                        <div className="pillright">
                                                                            <h6>Paid</h6>
                                                                        </div>
                                                                        <div className="amount">
                                                                            <p>৳ {item.amount}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <Button className="paymentbutton">Pay Now</Button>
                                    </div>
                                </Col>
                                <Col lg={4} style={{ paddingLeft: "0", paddingRight: "15px" }}>
                                    <div className="samebox sameboxroutine">
                                        <div className="headerpart">
                                            <div className="flexwrap">
                                                <h4>Class Routine</h4>
                                                <div className="dropdownwrapper">
                                                    <Dropdown className='filterdropone' options={dayoptions} onChange={(e) => dropdownvalue(e)} value={defaultOptiontype} />
                                                    <BsChevronDown />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="routinetable">
                                            <table>
                                                {
                                                    drpday == "Saturday" ?
                                                        periodsubjectdata.saturday.map((item, key) => {
                                                            return (
                                                                <tr key={key}>
                                                                    <td>{item.periodnumber} Period </td>
                                                                    <td>{item.subject}</td>
                                                                </tr>
                                                            )
                                                        })
                                                        :
                                                        drpday == "Sunday" ?
                                                            periodsubjectdata.sunday.map((item, key) => {
                                                                return (
                                                                    <tr key={key}>
                                                                        <td>{item.periodnumber} Period </td>
                                                                        <td>{item.subject}</td>
                                                                    </tr>
                                                                )
                                                            })

                                                            :

                                                            drpday == "Monday" ?
                                                                periodsubjectdata.monday.map((item, key) => {
                                                                    return (
                                                                        <tr>
                                                                            <td>{item.periodnumber} Period </td>
                                                                            <td>{item.subject}</td>
                                                                        </tr>
                                                                    )
                                                                })

                                                                :
                                                                drpday == "Tuesday" ?
                                                                    periodsubjectdata.tuesday.map((item, key) => {
                                                                        return (
                                                                            <tr>
                                                                                <td>{item.periodnumber} Period </td>
                                                                                <td>{item.subject}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                    :
                                                                    drpday == "Wednesday" ?
                                                                        periodsubjectdata.wednesday.map((item, key) => {
                                                                            return (
                                                                                <tr key={key}>
                                                                                    <td>{item.periodnumber} Period </td>
                                                                                    <td>{item.subject}</td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        :
                                                                        drpday == "Thursday" ?
                                                                            periodsubjectdata.thursday.map((item, key) => {
                                                                                return (
                                                                                    <tr>
                                                                                        <td>{item.periodnumber} Period </td>
                                                                                        <td>{item.subject}</td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                            :
                                                                            drpday == "Friday" ?
                                                                                periodsubjectdata.friday.map((item, key) => {
                                                                                    return (
                                                                                        <tr>
                                                                                            <td>{item.periodnumber} Period </td>
                                                                                            <td>{item.subject}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })
                                                                                :
                                                                                periodsubjectdata.saturday.map((item, key) => {
                                                                                    return (
                                                                                        <tr key={key}>
                                                                                            <td>{item.periodnumber} Period </td>
                                                                                            <td>{item.subject}</td>
                                                                                        </tr>
                                                                                    )
                                                                                })

                                                }

                                            </table>
                                        </div>
                                        <div className="extraclassbox">
                                            <p>No Extra Class</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={4} style={{ paddingLeft: "0", paddingRight: "15px" }}>
                                    <div className="samebox sameboxcalender">
                                        <DayPicker
                                            captionLayout="dropdown"
                                            mode="single"
                                            selected={selected}
                                            onSelect={setSelected}
                                            showOutsideDays
                                        />

                                        <div className="eventbox">
                                            <p>No Event</p>
                                        </div>

                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default StudentDashComp