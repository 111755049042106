import React from 'react'
import StuDashNoticeComp from '../../../components/dashboardComponents/studentDashboard/StuDashNoticeComp'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardNoticemb from '../../../components/mobile/dashbordMobile/StuDashboardNoticemb'

const StuDashNoticePage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">
                    <StuDashNoticeComp/>

                </div>
            </div>
        </div>  
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="third"/>
           
            <StuDashboardNoticemb/>
             

          </div>
        </div> 
    </>
  )
}

export default StuDashNoticePage