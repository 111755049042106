export const reportCardApi = {
    heading:[
        {
            head:"SL"
        },
        {
            head:"Student ID"
        },
        {
            head:"Name"
        },
        {
            head:"Class"
        },
        {
            head:"Section"
        },
        {
            head:"Front"
        },
        {
            head:"Back"
        }
    ],
    data:[
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"1",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"2",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"3",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"4",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"5",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"6",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
        {
            sl:"7",
            studentid:"2207302974",
            name:"Arko Chatterjee",
            class:"Starter",
            section:"Diamond",
            front:"",
            back:"",
        },
       
    ]
}