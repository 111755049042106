import React,{useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {Row,Col,Nav,Tab,Tabs,Button} from 'react-bootstrap'
import { sidebarApi } from '../../../../utils/DashboardApi/StudentDashboardApi'
import $ from 'jquery'
import { Accordion,Card } from 'react-bootstrap';
const SidebarOfsDash = (props) => {
    // const [myactive,setmyactive] = useState("active")
    const navigate = useNavigate()
    
    const handlelogout=()=>{
        navigate('/')
    }
    const handleSelecttab=(mylink,myid)=>{
        navigate(mylink)
        // for(let i=1;i<= sidebarApi.length;i++){
        //     $(`#myid${i}`).removeClass("active");
        // }
        
         // setmyactive("")
        
        // 
    }
    // useEffect(()=>{
    //     $(`#myid${props.activeID}`).addClass("active");
    // })
    // className={item.active==true? myactive :""}
    const handleLi = (mylink)=>{
        navigate(mylink)
       
      }
  return (
    <>
         <div className="sidebarleftbill">
            <div className="sidebarleftbillbox">
                <div className="logo">
                    <img src="./assets/images/logo/bsslogostudashboard.png" alt="" />
                </div>
                <div className="menupart">
                
                <Nav variant="pills" className="flex-column">
                    {
                        sidebarApi.map((item,key)=>{
                            if(item.drpdwn.length!==0){
                                return(
                                  <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header><img src={item.imgurl} alt="" /> <span>{item.name}</span> </Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                    { item.drpdwn.map((item2,key2)=>{
                                      return(
                                        <li onClick={()=>handleLi(item2.link)}> 
                                        <div className={window.location.pathname==item2.link?`wr active`:`wr`}>
                                          {item2.item}
                                          </div>
                                           </li>
                                      )
                                    })}
          
                                  </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                )
          
                              }else{
                                return(
                                
                                    <Nav.Item key={key}>
                                    <p className={window.location.pathname==item.link?`active`:``} onClick={()=>handleSelecttab(item.link,key)}><img src={item.imgurl} alt="" /> {item.name}</p>
                                    </Nav.Item>
                                )
                              }
                            
                        })
                    }
                   
                </Nav>
                  
                </div>
                <div className="logout">
                  <Button onClick={handlelogout}>Logout</Button>
                </div>
            </div>
        </div>
    </>
  )
}

export default SidebarOfsDash