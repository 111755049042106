import React, { useEffect, useRef, useState } from 'react'
import { FaEvernote } from 'react-icons/fa'
import HeadlineOfOtherPage from './subComponents/HeadlineOfOtherPage'
import { Row, Col, Form } from 'react-bootstrap'
import CommonButton from './subComponents/CommonButton'
import Dropdown from 'react-dropdown';
import { BsChevronDown } from 'react-icons/bs'
import FeeStructureDynamiccomp from './subComponents/FeeStructureDynamiccomp'
import { allfeedata, feeinfodata } from '../utils/FeeStructureApi'
import Dropdown2 from './subComponents/CustomSubComponents/Dropdown2'
import axios from 'axios'
import { elements } from 'chart.js'
const FeeStructureComp = () => {
  const [contactformdata, setcontactformdata] = useState({
    name: "",
    email: "",
    phone: "",
    info: "",
    subject: "",
    message: "",
    captcha: ""
  })
  const typeoptions = [
    'Select Type', 'New Admission', 'Enrollment'
  ];

  const defaultOptiontype = typeoptions[0];

  const [drptype, setdrptype] = useState("")
  const [classoptions, setclassoptions] = useState("")
  const [admissionfee, setadmissionfee] = useState(0)
  const [billingcharge, setbillingcharge] = useState(0)
  const [sessionfee, setsessionfee] = useState(0)
  const [tuitionfee, settuitionfee] = useState(0)
  const [classiddrp, setclassiddrp] = useState(null)

  const dropdownvalueclass = (e) => {
    // console.log(e.value)
    setclassoptions(e.value)
  }

  const handleInput = (e) => {
    setcontactformdata({ ...contactformdata, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(contactformdata)

  }


  const feenotedata = [
    {
      feenotedata: "For the past two years, we have waived our tuition fees by 50% and made no changes to the fee structure due to the pandemic in order to support our beloved community in such difficult times. As the pandemic has been under control this year, we have reverted back to our regular fee structure."
    },
    {
      feenotedata: "Text Books ,Exercise Books ,Stationery ,Diary ,ID Card & IT Charge are Excluded"
    },

  ]
  const optionsselecttype = [
    {
      opt: 'Select Type'
    },
    {
      opt: 'New Admission'
    },
    {
      opt: 'Enrollment'
    },

  ];



  const drpfunctype = (mysectionval) => {
    setdrptype(mysectionval)
  }
  const drpfuncClass = (myclassval, myid) => {
    setclassoptions(myclassval)
    setclassiddrp(myid)
    console.log(myid)
  }
  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [id, setid] = useState("");
  const [name, setname] = useState("");
  const [myresponse, setmyResponse] = useState([]);

  useEffect(() => {
    const fetchClassDropdown = async () => {
      try {

        const myresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/ClassList", {}, headers
        );
        setmyResponse(myresponse.data);
        console.log(myresponse);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchClassDropdown();
  }, []);

  const [classId, setclassId] = useState("");
  const [className, setclassName] = useState("");
  const [Admissionfee, setAdmissionfee] = useState("");
  const [Billingcharge, setBillingcharge] = useState("");
  const [Sessionfee, setSessionfee] = useState("");
  const [Tuitionfee, setTuitionfee] = useState("");
  const [FeeStructureRespone, setFeeStructureRespone] = useState([]);

  useEffect(() => {
    const fetchFeeStructureList = async () => {
      try {

        const FeeStructureRespone = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/FeeStructure", {}, headers
        );
        setFeeStructureRespone(FeeStructureRespone.data);
        console.log(FeeStructureRespone);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchFeeStructureList();
  }, []);

  return (
    <>
      <div className="myContainer totalAmountPopulate">
        <Row
          className="totalamounttitle"
          data-aos-delay="300"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Col lg={{ span: 6, offset: 6 }}>
            <h5>Total Amount</h5>
          </Col>
        </Row>
        <Row>
          <Col
            lg={6}
            data-aos-delay="300"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="dropdownwrapper">
              <Dropdown2
                func={drpfunctype}
                myplaceholder="Select Type"
                fontsize="12"
                fontfamily="'Poppins', sans-serif"
                options={optionsselecttype}
              />
            </div>
            <div className="dropdownwrapper">
              <Dropdown2
                func={drpfuncClass}
                myplaceholder="Select Class"
                fontsize="12"
                fontfamily="'Poppins', sans-serif"
                options={myresponse}
              />
            </div>
          </Col>
          <Col
            lg={6}
            data-aos-delay="300"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {drptype && classoptions ? (
              FeeStructureRespone.map((item, key) => {
                if (
                  drptype == "New Admission" &&
                  classiddrp == item.classId
                ) {
                  return (
                    <div className="populatesectiondiv" key={key}>
                      <FeeStructureDynamiccomp
                        admissionfeeprop={
                          //item.newadmission.starter.admissionfee
                          item.admissionfee
                        }
                        billingchargeprop={
                          item.billingcharge
                          //item.newadmission.starter.billingcharge
                        }
                        sessionfeeprop={item.sessionfee}
                        //sessionfeeprop={item.newadmission.starter.sessionfee}
                        tuitionfeeprop={item.tuitionfee}
                      //tuitionfeeprop={item.newadmission.starter.tuitionfee}
                      />
                    </div>
                  );
                } else if (
                  drptype == "Enrollment" &&
                  classiddrp == item.classId
                ) {
                  return (
                    <div className="populatesectiondiv" key={key}>
                      <FeeStructureDynamiccomp
                        admissionfeeprop={
                          //item.newadmission.starter.admissionfee
                          0
                        }
                        billingchargeprop={
                          item.billingcharge
                          //item.newadmission.starter.billingcharge
                        }
                        sessionfeeprop={item.sessionfee}
                        //sessionfeeprop={item.newadmission.starter.sessionfee}
                        tuitionfeeprop={item.tuitionfee}
                      //tuitionfeeprop={item.newadmission.starter.tuitionfee}
                      />
                    </div>
                  );
                }
                // else if (drptype === "Enrollment" &&
                // classoptions !== item.className){
                //   return(
                //     <div className="populatesectiondiv">
                //     <div className="populatebox">
                //       <p>Admission Fee</p>
                //       <p>{admissionfee}/=</p>
                //     </div>
                //     <div className="populatebox">
                //       <p>Billing Charge</p>
                //       <p>{billingcharge}/=</p>
                //     </div>
                //     <div className="populatebox">
                //       <p>Session Fee</p>
                //       <p>{sessionfee}/=</p>
                //     </div>
                //     <div className="populatebox">
                //       <p>Tuition Fee</p>
                //       <p>{tuitionfee}/=</p>
                //     </div>
                //     <div className="populatebox populateboxtotal">
                //       <p>Total</p>
                //       <p>0/=</p>
                //     </div>
                //   </div>
                //   );
                // }
                // else if (drptype == "New Admission" &&
                // classoptions != item.className){
                //   return(
                //     <div className="populatesectiondiv">
                //     <div className="populatebox">
                //       <p>Admission Fee</p>
                //       <p>{admissionfee}/=</p>
                //     </div>
                //     <div className="populatebox">
                //       <p>Billing Charge</p>
                //       <p>{billingcharge}/=</p>
                //     </div>
                //     <div className="populatebox">
                //       <p>Session Fee</p>
                //       <p>{sessionfee}/=</p>
                //     </div>
                //     <div className="populatebox">
                //       <p>Tuition Fee</p>
                //       <p>{tuitionfee}/=</p>
                //     </div>
                //     <div className="populatebox populateboxtotal">
                //       <p>Total</p>
                //       <p>0/=</p>
                //     </div>
                //   </div>
                //   );
                // }
                // else if(classoptions != item.className) {
                //   return(
                // <div className="populatesectiondiv" key={key.classId}>
                //   <div className="populatebox">
                //     <p>Admission Fee</p>
                //     <p>{admissionfee}/=</p>
                //   </div>
                //   <div className="populatebox">
                //     <p>Billing Charge</p>
                //     <p>{billingcharge}/=</p>
                //   </div>
                //   <div className="populatebox">
                //     <p>Session Fee</p>
                //     <p>{sessionfee}/=</p>
                //   </div>
                //   <div className="populatebox">
                //     <p>Tuition Fee</p>
                //     <p>{tuitionfee}/=</p>
                //   </div>
                //   <div className="populatebox populateboxtotal">
                //     <p>Total</p>
                //     <p>0/=</p>
                //   </div>
                // </div>
                //   );
                // }
              })
            ) : (
              <div className="populatesectiondiv">
                <div className="populatebox">
                  <p>Admission Fee</p>
                  <p>{admissionfee}/=</p>
                </div>
                <div className="populatebox">
                  <p>Billing Charge</p>
                  <p>{billingcharge}/=</p>
                </div>
                <div className="populatebox">
                  <p>Session Fee</p>
                  <p>{sessionfee}/=</p>
                </div>
                <div className="populatebox">
                  <p>Tuition Fee</p>
                  <p>{tuitionfee}/=</p>
                </div>
                <div className="populatebox populateboxtotal">
                  <p>Total</p>
                  <p>0/=</p>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <HeadlineOfOtherPage headline="Fee Information" />

      <div
        className="feeinfolist"
        data-aos-delay="300"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <ul>
          {feeinfodata.map((item, key) => {
            return <li key={key}>{item.feeinfodata}</li>;
          })}
        </ul>
      </div>
      <HeadlineOfOtherPage headline="Note" />

      <div
        className="feenote"
        data-aos-delay="300"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <ul>
          {feenotedata.map((item, key) => {
            return <li key={key}>{item.feenotedata}</li>;
          })}
        </ul>
      </div>

      <HeadlineOfOtherPage headline="Online Inquiry" />

      <div
        className="myContainer onlineinquiry"
        data-aos-delay="300"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={6} style={{ padding: "0 15px" }}>
              <div className="basicinputfield">
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  onChange={handleInput}
                />
                <input
                  type="emial"
                  placeholder="Email"
                  name="email"
                  onChange={handleInput}
                />
                <input
                  type="text"
                  placeholder="Phone"
                  name="phone"
                  onChange={handleInput}
                />
                <input
                  type="text"
                  placeholder="Info"
                  name="info"
                  onChange={handleInput}
                />
                <input
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  onChange={handleInput}
                />
              </div>
            </Col>
            <Col lg={6} style={{ padding: "0 15px" }}>
              <div className="basicinputfield contactpagerightsideform">
                <textarea
                  name="message"
                  placeholder="Message"
                  onChange={handleInput}
                ></textarea>
                <input
                  type="text"
                  placeholder="Captcha"
                  name="captcha"
                  onChange={handleInput}
                />
              </div>
            </Col>
          </Row>
          <CommonButton buttonTitle="Send Message" />
        </Form>
      </div>
    </>
  );
}

export default FeeStructureComp