import React,{useState,useEffect} from 'react'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import {Row,Col,Modal,Button,Form,Nav,Tab} from 'react-bootstrap'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { manageonlinetable } from '../../../utils/AdminDashApi/ManageOnlineAd'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { attachmentfilesapi } from '../../../utils/AdminDashApi/AttachmentFiles'
import {GrAttachment} from 'react-icons/gr'
import {RiCheckboxMultipleLine} from 'react-icons/ri'
import $ from 'jquery'
const ManageOnlineAdMbComp = () => {

    const [currentItems, setCurrentItems] = useState(null);
    const [pageCount, setPageCount] = useState(0);
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);
    const [itemsPerPage,setitemsPerPage] = useState(10);
    const [paginatenumselected,setpaginatenumselected] = useState(0)
    const [show,setShow] = useState("")
  
    const handleApprove=()=>{
        // Approval code
        setshowApprv(false)
    }
  
    useEffect(() => {
      // Fetch items from another resources.
      const endOffset = itemOffset + itemsPerPage;
      // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
      setCurrentItems(manageonlinetable.datatable.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(manageonlinetable.datatable.length / itemsPerPage));
    }, [itemOffset, itemsPerPage]);
  
    // Invoke when user click to request another page.
    const handlePageClick = (event) => {
      const newOffset = event.selected * itemsPerPage % manageonlinetable.datatable.length;
      console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
      setpaginatenumselected(event.selected);
      setItemOffset(newOffset);
    };
    const drpfuncshow=(myshow)=>{
      setitemsPerPage(myshow)
    }
    const optionsShowt = [
      {
        id:1,
        opt:10
      },
      {
        id:2,
        opt:20
      },
      {
        id:3,
        opt:30
      },
    ]
    const [drptoggler,setdrptoggler] = useState(false)
    const [drpshow,setdrpshow] = useState(null)
  
    function handleaction(myid,mytoggler){
      if(mytoggler==false){
        setdrptoggler(true)
        setdrpshow(myid)
      }else{
        setdrptoggler(false)
        setdrpshow(null)
      }
        
        
      
    }
      moment().format();
      const handleDateofbirth = (newvaldob)=>{
         
          
          console.log(newvaldob)
          // setdateofbirthnew(moment(newvaldob).format("YYYY-MM-DD"))
          if(newvaldob != null){
              
              var dtofbrth = newvaldob.$M + 1;
              var dtdobfinalc = newvaldob.$y + '-'+ dtofbrth +'-'+ newvaldob.$D;
              setdateofbirthnew(moment(dtdobfinalc).format("YYYY-MM-DD"))
              // setdateofbirthnew(newvaldob)
          }
          console.log(dateofbirthnew);
          
          
      }
    
    const [formdata,setformdata] = useState({
      firstName:"",
      middleName:"",
      lastName:"",
      nickName:"",
      birthCertificateNo:"",
      mobileNo:"",
      email:"",
      fatherFirstName:"",
      fatherMiddleName:"",
      fatherLastName:"",
      fatherNIDNo:"",
      motherFirstName:"",
      motherMiddleName:"",
      motherLastName:"",
      motherNIDNo:"",
      declReason:""
      
  })
  
    const [searchkeyword,setsearchkeyword] = useState("")
    const [firstnamelabel,setfirstnamelabel] = useState("")
    const [middlenamelabel,setmiddlenamelabel] = useState("")    
    const [labelbirthcert,setlabelbirthcert] = useState("")
    const [labeldob,setlabeldob] = useState("")
    const [labelmobileno,setlabelmobileno] = useState("")
    const [labelgender,setlabelgender] = useState("")
    const [labelemail,setlabelemail] = useState("")
    const [labeladDate,setlabeladDate] = useState("")
    const [labelclass,setlabelclass] = useState("")
    const [labelsession,setlabelsession] = useState("")
    const [fatherfirstnamelabel,setfatherfirstnamelabel] = useState("")
    const [fathermiddlenamelabel,setfathermiddlenamelabel] = useState("")   
    const [fathernidlabel,setfathernidlabel] = useState("")
    const [mothernidlabel,setmothernidlabel] = useState("")
    const [motherfirstnamelabel,setmotherfirstnamelabel] = useState("")
    const [mothermiddlenamelabel,setmothermiddlenamelabel] = useState("")  
    const [fnidlabel,setfnidlabel] = useState("")
    const [mnidlabel,setmnidlabel] = useState("")
    const [declineerrlabel,setdeclineerrlabel] = useState("")
    
    const [classoptions,setclassoptions]=useState("")
      const [drpsessionselect,setdrpsession] = useState("")
  
      const [bordererrordecl,setbordererrordecl] = useState("")
    const [bordererrorfirstname,setbordererrorfirstname] = useState(false)
      const [bordererrormiddlename,setbordererrormiddlename] = useState(false)   
      const [bordererrorlabelbirthcert,setbordererrorlabelbirthcert] = useState(false)
      const [bordererrormobileno,setbordererrormobileno] = useState(false)
      const [bordererroeemail,setbordererroeemail] = useState(false)
      const [bordergender,setbordergender] = useState(false)
      const [borderclass,setborderclass] = useState(false)
      const [bordersection,setbordersection] = useState(false)
      const [bordererrorfatherfirstname,setbordererrorfatherfirstname] = useState(false)
      const [bordererrorfathermiddlename,setbordererrorfathermiddlename] = useState(false)   
      const [bordererrorfathernid,setbordererrorfathernid] = useState(false)
      const [bordererrormothernid,setbordererrormothernid] = useState(false)
      const [bordererrormotherfirstname,setbordererrormotherfirstname] = useState(false)
      const [bordererrormothermiddlename,setbordererrormothermiddlename] = useState(false)           
      const [bordererrorfnid,setbordererrorfnid] = useState(false)
      const [bordererrormnid,setbordererrormnid] = useState(false)
      const [dateofbirthnew,setdateofbirthnew] = useState("")
  const [drpsectionselectgender,setdrpsectiongender] = useState("")
      const [showReviewApply,setshowReviewApply] = useState(false)
  
      const handleSearchkeyword = (e)=>{
        setsearchkeyword(e.target.value)
      }
       const optionsgender = [
          {
              id:1,
              opt:'Male'
          },
          {
              id:2,
              opt:'Female'
          },
          {
              id:3,
              opt:'Other'
          },
        ];
        const drpfunctiongender=(mysectionval)=>{
          setdrpsectiongender(mysectionval)
      }
        const optionsClass = [
          {
              opt:'Select Class'
          },
          {
              opt:'Starter'
          },
          {
              opt:'Playgroup'
          },
          {
              opt:'Nursery'
          },
          {
              opt:'Junior KG'
          },
          {
              opt:'Senior KG'
          },
          {
              opt:'Class 1'
          },
          {
              opt:'Class 2'
          },
          {
              opt:'Class 3'
          },
          {
              opt:'Class 4'
          },
          {
              opt:'Class 5'
          },
          {
              opt:'Class 6'
          },
          {
              opt:'Class 7'
          },
          {
              opt:'O Level'
          },
          {
              opt:'A Level'
          },
          {
              opt:'Pre- O level'
          },
        
        ];
        const optionssection = [
          {
              opt:'July 2019 - July 2020'
          },
          {
              opt:'July 2021 - July 2022'
          },
          {
              opt:'July 2022 - July 2023'
          },
        ];
         const drpfuncClass=(myclassval)=>{
          setclassoptions(myclassval)
      }
      const drpfunctionsession=(mysectionval)=>{
          setdrpsession(mysectionval)
      }
    const handleClosereviewapply = (e) => {
      
      setshowReviewApply(false);
    }
    const handleShowreviewapply = () => setshowReviewApply(true);
  
    const handleSubmit=(e)=>{
      e.preventDefault()
      if(formdata.firstName.length > 50){
        setfirstnamelabel("less than 50 characters required")
        setbordererrorfirstname(true)
      }else if(formdata.firstName.length==0){
          setfirstnamelabel("First Name Required")
          setbordererrorfirstname(true)
      }
      else{
          setfirstnamelabel("")
          setbordererrorfirstname(false)
      }
      if(formdata.middleName && formdata.middleName.length < 3 || formdata.middleName.length > 50){
        setmiddlenamelabel("3 to 50 characters please")
        setbordererrormiddlename(true)
    }else if(formdata.middleName.length==0){
        setmiddlenamelabel("Middle name Required")
        setbordererrormiddlename(true)
    }
    else{
        setmiddlenamelabel("")
        setbordererrormiddlename(false)
    }
    if(formdata.birthCertificateNo.length>0 && formdata.birthCertificateNo.length<17){
      setlabelbirthcert("17 digits required")
      setbordererrorlabelbirthcert(true)
    }
    else if(formdata.birthCertificateNo.length==0){
        setlabelbirthcert("Birth certificate number required")
        setbordererrorlabelbirthcert(true)
    }
    else{
        setlabelbirthcert("")
        setbordererrorlabelbirthcert(false)
    }
    if(formdata.fatherFirstName && formdata.fatherFirstName.length < 3 || formdata.fatherFirstName.length > 50){
      setfatherfirstnamelabel("3 to 50 characters required")
      setbordererrorfatherfirstname(true)
  }else if(formdata.fatherFirstName.length==0){
      setfatherfirstnamelabel("First Name Required")
      setbordererrorfatherfirstname(true)
  }
  else{
      setfatherfirstnamelabel("")
      setbordererrorfatherfirstname(false)
  }
  if(formdata.fatherMiddleName && formdata.fatherMiddleName.length < 3 || formdata.fatherMiddleName.length > 50){
      setfathermiddlenamelabel("3 to 50 characters please")
      setbordererrorfathermiddlename(true)
  }else if(formdata.fatherMiddleName.length==0){
      setfathermiddlenamelabel("Middle name Required")
      setbordererrorfathermiddlename(true)
  }
  else{
      setfathermiddlenamelabel("")
      setbordererrorfathermiddlename(false)
  }
  
  
  
  
  if(formdata.fatherNIDNo.length==0){
      setfathernidlabel("NID is required")
      setbordererrorfathernid(true)
  }
  else if(formdata.fatherNIDNo.length < 10){
      setfathernidlabel("NID should be 10 - 17 numbers")
      setbordererrorfathernid(true)
  }
  else{
      setfathernidlabel("")
      setbordererrorfathernid(false)
  }
  
  if(formdata.motherFirstName && formdata.motherFirstName.length < 3 || formdata.motherFirstName.length > 50){
    setmotherfirstnamelabel("3 to 50 characters required")
    setbordererrormotherfirstname(true)
  }else if(formdata.motherFirstName.length==0){
    setmotherfirstnamelabel("First Name Required")
    setbordererrormotherfirstname(true)
  }
  else{
    setmotherfirstnamelabel("")
    setbordererrormotherfirstname(false)
  }
  if(formdata.motherMiddleName && formdata.motherMiddleName.length < 3 || formdata.motherMiddleName.length > 50){
    setmothermiddlenamelabel("3 to 50 characters please")
    setbordererrormothermiddlename(true)
  }else if(formdata.motherMiddleName.length==0){
    setmothermiddlenamelabel("Middle name Required")
    setbordererrormothermiddlename(true)
  }
  else{
    setmothermiddlenamelabel("")
    setbordererrormothermiddlename(false)
  }
  if(formdata.motherNIDNo.length==0){
    setmothernidlabel("NID is required")
    setbordererrormothernid(true)
  }
  else if(formdata.motherNIDNo.length < 10){
    setmothernidlabel("NID should be 10 - 17 numbers")
    setbordererrormothernid(true)
  }
  else{
    setmothernidlabel("")
    setbordererrormothernid(false)
  }
    if(drpsectionselectgender.length==0){
              setlabelgender("Gender reauired")
              setbordergender(true)
          }
          else{
              setlabelgender("")
              setbordergender(false)
          }
          if(dateofbirthnew.length==0){
              setlabeldob("Date Required")
          }else{
              setlabeldob("")
          }
                  if(formdata.mobileNo.length>0 && formdata.mobileNo.length<11){
              setlabelmobileno("Mobile no must be 11 digits")
              setbordererrormobileno(true)
          }else if(formdata.mobileNo.length==0){
              setlabelmobileno("Mobile no is required")
              setbordererrormobileno(true)
          }
          else{
              setlabelmobileno("")
              setbordererrormobileno(false)
          }
          if (formdata.email.length==0){
              setlabelemail("Email is required")
              setbordererroeemail(true)
           }
           else if (formdata.email && !/\S+@\S+\.\S+/.test(formdata.email)) {
              setlabelemail('Email format is incorrect')
              setbordererroeemail(true)
            }
            else{
              setlabelemail('')
              setbordererroeemail(false)
            }
            if(classoptions.length==0){
              setlabelclass("Class reauired")
              setborderclass(true)
          }
          else{
              setlabelclass("")
              setborderclass(false)
          }
          if(drpsessionselect.length==0){
              setlabelsession("Session reauired")
              setbordersection(true)
          }
          else{
              setlabelsession("")
              setbordersection(false)
          }
  
          
   
    }
    const handleInput=(e)=>{    
      setformdata({...formdata,[e.target.name]: e.target.value} )
    }
    const [showAtt,setshowAtt]  = useState(false)
  
    const handleCloseAtt=()=>{
      setshowAtt(false)
    }
    const handleShowAtt=()=>{
      setshowAtt(true)
    }
    const [showApprv,setshowApprv] = useState(false)
  
    const handleCloseApprv=()=>{
      setdeclshow(false)
      setshowApprv(false)
    }
    const handleOpenApprv=()=>{
      setshowApprv(true)
    }
    const [showDecl,setshowDecl] = useState(false)
    const handleCloseDecl=()=>{
      setshowDecl(false)
    }
    const handleopenDecl = ()=>{
      setshowDecl(true)
    }
    const handleDecl=()=>{
      // decline code
      
    }
    const handleDeclineSubmit=(e)=>{
      e.preventDefault()
      if(formdata.declReason.length==0){
        setdeclineerrlabel("Reason is required")
        setbordererrordecl(true)
      }
      else if(formdata.declReason.length < 21){
        setdeclineerrlabel("Reason should be greater than 20 characters")
        setbordererrordecl(true)
      }
      else{
        setdeclineerrlabel("")
        setbordererrordecl(false)
        setshowDecl(false)
  
      }
      console.log(formdata.declReason)
    }
    const [declshow,setdeclshow] = useState(false)
    const handleDeclinenew = () =>{
      setdeclshow(true)
    }
  return (
    <>

<Modal dialogClassName="mymodal" show={showReviewApply} onHide={handleClosereviewapply} backdrop="static"
              keyboard={false}>
                  <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Review Apply</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                      <h5>Student Info</h5>
                      <Col lg={3} xs={6}>
                        <div className="basicinput">
                              <p>First Name*</p>
                              <div className="inputgap">
                              <input maxLength={50} style={bordererrorfirstname? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='First Name' name="firstName" value={formdata.firstName} onChange={handleInput}/>
                              <label>{firstnamelabel}</label>
                              </div>
                        </div>
                      </Col>
                      <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Middle Name*</p>
                            <div className="inputgap">
                            <input  maxLength={50} style={bordererrormiddlename? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='Middle Name'  name="middleName" value={formdata.middleName} onChange={handleInput}/>

                            <label>{middlenamelabel}</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Last Name</p>
                            <div className="inputgap">
                            <input maxLength={50} type="text" placeholder='Last Name' name="lastName" value={formdata.lastName} onChange={handleInput}/>
                            
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Nick Name</p>
                            <div className="inputgap">
                            <input maxLength={50} type="text" placeholder='Nick Name'  name="nickName" value={formdata.nickName} onChange={handleInput}/>
                            
                            </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                        <div className="basicinput">
                            <p>Birth Certificate No*</p>
                            <div className="inputgap">
                            <input style={bordererrorlabelbirthcert? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} maxLength={17} type="text" placeholder='Birth Certificate No'  name="birthCertificateNo" value={formdata.birthCertificateNo} onChange={handleInput}/>
                            <label>{labelbirthcert}</label>
                            </div>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                      <h5>Father Info</h5>
                      <Col lg={3} xs={6}>
                    <div className="basicinput">
                            <p>First Name*</p>
                            <div className="inputgap">
                            <input maxLength={50} style={bordererrorfatherfirstname? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='First Name' name="fatherFirstName" value={formdata.fatherFirstName} onChange={handleInput}/>
                            <label>{fatherfirstnamelabel}</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Middle Name*</p>
                            <div className="inputgap">
                            <input  maxLength={50} style={bordererrorfathermiddlename? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='Middle Name'  name="fatherMiddleName" value={formdata.fatherMiddleName} onChange={handleInput}/>

                            <label>{fathermiddlenamelabel}</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Last Name</p>
                            <div className="inputgap">
                            <input maxLength={50} type="text" placeholder='Last Name' name="fatherLastName" value={formdata.fatherLastName} onChange={handleInput}/>
                           
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                    <div className="basicinput">
                            <p>NID No*</p>
                            <div className="inputgap">
                            <input onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} maxLength={17} style={bordererrorfathernid? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='NID No'  name="fatherNIDNo" value={formdata.fatherNIDNo} onChange={handleInput}/>
                            <label>{fathernidlabel}</label>
                            </div>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                      <h5>Mother Info</h5>
                      <Col lg={3} xs={6}>
                    <div className="basicinput">
                            <p>First Name*</p>
                            <div className="inputgap">
                            <input maxLength={50} style={bordererrormotherfirstname? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='First Name' name="motherFirstName" value={formdata.motherFirstName} onChange={handleInput}/>
                            <label>{motherfirstnamelabel}</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Middle Name*</p>
                            <div className="inputgap">
                            <input  maxLength={50} style={bordererrormothermiddlename? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='Middle Name'  name="motherMiddleName" value={formdata.motherMiddleName} onChange={handleInput}/>

                            <label>{mothermiddlenamelabel}</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Last Name</p>
                            <div className="inputgap">
                            <input maxLength={50} type="text" placeholder='Last Name' name="motherLastName" value={formdata.motherLastName} onChange={handleInput}/>
                           
                            </div>
                        </div>
                    </Col>
                    <Col lg={3}>
                    <div className="basicinput">
                            <p>NID No*</p>
                            <div className="inputgap">
                            <input onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} maxLength={17} style={bordererrormothernid? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='NID No'  name="motherNIDNo" value={formdata.motherNIDNo} onChange={handleInput}/>
                            <label>{mothernidlabel}</label>
                            </div>
                        </div>
                    </Col>
                    </Row>
                    <Row>
                        <h5>Other Info</h5>
                        <Col lg={3} xs={6}>
                        <div className="basicinput">
                            <p>Gender*</p>
                            <div className="inputgap2">
                            <Dropdown2 func={drpfunctiongender} myplaceholder="Select Gender" fontsize="18" fontfamily="'Poppins', sans-serif"  options={optionsgender}/>
                            <label>{labelgender}</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={6}>
                    <div className="basicinput">
                            <p>Mobile No*</p>
                            <div className="inputgap">
                            <input onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} maxLength={11} style={bordererrormobileno? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" placeholder='Mobile No'  name="mobileNo" value={formdata.mobileNo} onChange={handleInput}  />
                            <label>{labelmobileno}</label>
                            </div>
                        </div>
                        
                    </Col>
                    <Col lg={3} xs={12}>
                    <div className="basicinput">
                            
                            <p>Date of Birth*</p>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <div className="inputgap2">
                            <DatePicker
                                
                                
                                value={dateofbirthnew}
                                onChange={(newvaldob)=>handleDateofbirth(newvaldob)}
                                renderInput={(params) => <TextField {...params}inputProps={{
                                    ...params.inputProps,
                                    placeholder: "Date of Birth"
                                  }} error={false} />}
                            />
                                <label>{labeldob}</label>
                                </div>
                            </LocalizationProvider>
                        </div>
                    </Col>
                    <Col lg={3} xs={12}>
                        <div className="basicinput">
                            <p>Email Address*</p>
                            <div className="inputgap">
                            <input type="text" style={bordererroeemail? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} placeholder='Email Address'  name="email" value={formdata.email} onChange={handleInput}/>
                            <label>{labelemail}</label>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} xs={12}>
                        <div className="basicinput">
                            <p>Session</p>
                            <div className="inputgap">
                            <div className="dropdownwrapper">
                            <Dropdown2 func={drpfunctionsession} myplaceholder="Select Session" fontsize="18" fontfamily="'Poppins', sans-serif"  options={optionssection}/>
                            </div>
                            <label>{labelsession}</label>
                            </div>
                        </div>
                    
                    </Col>
                    <Col lg={3} xs={12}>
                    <div className="basicinput">
                        <p>Class</p>
                        <div className="inputgap">
                            <div className="dropdownwrapper scrollabledrp">
                                <Dropdown2 func={drpfuncClass} myplaceholder="Select Class" fontsize="18" fontfamily="'Poppins', sans-serif"  options={optionsClass}/>
                            </div>
                                <label>{labelclass}</label>
                        </div>
                    </div>
                    
                    </Col>
                    
                    </Row>
                </Modal.Body>
                <Modal.Footer className="m-auto justify-content-center" style={{borderTop:"0"}}>
                  <Row>
                    <Col xs={6}>
                        <p type="text" variant="secondary" onClick={handleClosereviewapply}>
                        Close
                      </p>
                    </Col>
                    <Col xs={6}>
                      <button type="submit">
                        Save
                      </button>
                    </Col>
                  </Row>
                  
                  
                </Modal.Footer>
                  </Form>
              </Modal>

              <Modal className="attachmentmodal" dialogClassName="mymodal2" show={showAtt} onHide={handleCloseAtt} backdrop="static"
              keyboard={false}>
                  
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Attachment Files</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Tab.Container className="wholetabofatt" id="left-tabs-example" defaultActiveKey="first">
                    <Row>
                      <Col sm={12} lg={3}>
                        <Nav variant="pills" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="first">Student Image</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="second">Student Transcript</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="third">Birth Certificate</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fourth">Father NID</Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="fifth">Mother NID</Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col sm={12} lg={9}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first">
                            {
                              attachmentfilesapi.stupic.length !==0 ?
                                <img src={attachmentfilesapi.stupic}/>
                              :"no image to show"
                            }
                          </Tab.Pane>
                          <Tab.Pane eventKey="second">
                          {
                              attachmentfilesapi.transcript.length !==0 ?
                                <img  src={attachmentfilesapi.transcript}/>
                              :"no image to show"
                            }
                          </Tab.Pane>
                          <Tab.Pane eventKey="third">
                          {
                              attachmentfilesapi.bcertificate.length !==0 ?
                                <img  src={attachmentfilesapi.bcertificate}/>
                              :"no image to show"
                            }
                          </Tab.Pane>
                          <Tab.Pane eventKey="fourth">
                          {
                              attachmentfilesapi.fnid.length !==0 ?
                                <img  src={attachmentfilesapi.fnid}/>
                              :"no image to show"
                            }
                          </Tab.Pane>
                          <Tab.Pane eventKey="fifth">
                          {
                              attachmentfilesapi.mnid.length !==0 ?
                                <img  src={attachmentfilesapi.mnid}/>
                              :"no image to show"
                            }
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Modal.Body>
                <Modal.Footer className="m-auto justify-content-center" style={{borderTop:"0"}}>
                  <p type="text" variant="secondary" onClick={handleCloseAtt}>
                    Close
                  </p>
                </Modal.Footer>
                 
              </Modal>
              <div className="apprvmodalwrap">
              <Modal className="apprvmodal" dialogClassName="mymodal3" show={showApprv} onHide={handleCloseApprv} backdrop="static"
              keyboard={false}>
                  
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">{declshow==false? "Approve/Decline Application":"Decline Application"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="pdiv text-center">
                    {
                      declshow==false?
                      <p>Click approve if all document is correct</p>
                      :
                      <div className="basicinput smalltext">
                      <p>Set a reason for decline applicaton</p>
                      <div className="inputgap">
                      <textarea name="declReason" value={formdata.declReason} onChange={handleInput} style={bordererrordecl? {borderColor:"rgb(237, 28, 36)",height:"138px",width:"100%",border:"1px solid rgba(0,0,0,0.2)",borderRadius:"10px",padding:"10px"}:{borderColor:"rgba(0,0,0,0.2)",height:"138px",width:"100%",border:"1px solid rgba(0,0,0,0.2)",borderRadius:"10px",padding:"10px"}} id=""></textarea>
                      <label>{declineerrlabel}</label>
                      </div>
                      </div>

                    }
                  </div>
                </Modal.Body>
                <Modal.Footer className="m-auto justify-content-center" style={{borderTop:"0"}}>
                  {
                    declshow==false?
                    <>
                    <div className="wr">

                      <p type="text" variant="secondary" onClick={handleDeclinenew}>
                        Decline
                      </p>
                      <button variant="primary" onClick={handleApprove}>Approve</button>
                    </div>
                    </>
                  :
                    <>
                    <div className="wr">

                      <p type="text" variant="secondary" onClick={handleCloseApprv}>
                    Close
                  </p>
                  <button type="submit" variant="primary">Save</button>
                    </div>
                    </>
                  }
                  
                </Modal.Footer>
                 
              </Modal>
              <Modal className="declmodal" dialogClassName="mymodaldecl" show={showDecl} onHide={handleCloseDecl} backdrop="static"
              keyboard={false}>
                  <Form onSubmit={handleDeclineSubmit}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Decline Application</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="pdiv ">
                    <div className="basicinput">
                    <p>Set a reason for decline applicaton</p>
                    <div className="inputgap">
                    <textarea name="declReason" value={formdata.declReason} onChange={handleInput} style={bordererrordecl? {borderColor:"rgb(237, 28, 36)",height:"138px",width:"100%",border:"1px solid rgba(0,0,0,0.2)",borderRadius:"10px",padding:"10px"}:{borderColor:"rgba(0,0,0,0.2)",height:"138px",width:"100%",border:"1px solid rgba(0,0,0,0.2)",borderRadius:"10px",padding:"10px"}} id=""></textarea>
                    <label>{declineerrlabel}</label>
                    </div>
                    </div>
                    
                  </div>
                </Modal.Body>
                <Modal.Footer className="m-auto justify-content-center" style={{borderTop:"0"}}>
                  <p type="text" variant="secondary" onClick={handleCloseDecl}>
                    Close
                  </p>
                  <button type="submit" variant="primary">Save</button>
                </Modal.Footer>

                  </Form>
                 
              </Modal>
              </div>
        <div className="contentofadmindash">
          <Row style={{marginTop:"20px"}}>
                <Col className="m-auto" xs={12}>
                  <Row>
                    <Col xs={4} lg={3}>
                    <div className="basicinput">
                      <p>Show</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="12px" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                      </div>
                      </div>
                    </Col>
                    <Col xs={8} lg={9}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" value={searchkeyword} onChange={handleSearchkeyword} placeholder="Search" />
                              <img src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <div className="manageonlineadtable">
                  <table>
                      <thead>
                        <tr>
                            {
                              manageonlinetable.heading.map((item,key)=>{
                                return(
                                  <th key={key}><div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItems?

                          currentItems.map((item,key)=>{
                            if(searchkeyword==item.id){
                              return(
                                <tr key={key}>
                                  <td>{item.id}</td>
                                  <td>{item.studentname}</td>
                                  <td>{item.session}</td>
                                  <td>{item.class}</td>
                                  <td>{item.mobile}</td>
                                  <td>{item.bcertificate}</td>
                                  <td>{item.applydate}</td>
                                  <td>{item.pstatus}</td>
                                  <td>{item.astatus}</td>                                
                                  <td>
                                    <div className="img text-center">
                                      <img onClick={handleShowreviewapply} src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" />  
                                      <GrAttachment/>
                                      {/* {
                                        onClick={()=>handleaction(item.id,drptoggler)}
                                        drpshow==item.id?
                                        <div className="drpdwn">
                                            <ul>
                                              <li><p onClick={handleShowreviewapply}>Review Apply</p></li>
                                              <li><p onClick={handleShowAtt}>Attachment Files</p></li>
                                              <li><p onClick={handleOpenApprv}>Approve Application</p></li>
                                              <li><p onClick={handleopenDecl}>Decline Application</p></li>
                                            </ul>
                                        </div>
                                        :""
                                      } */}
                                    </div>  
                                  </td>
                                </tr>
                              )

                            }
                            if(searchkeyword==""){
                              return(
                                <tr key={key}>
                                  <td>{item.id}</td>
                                  <td>{item.studentname}</td>
                                  <td>{item.session}</td>
                                  <td>{item.class}</td>
                                  <td>{item.mobile}</td>
                                  <td>{item.bcertificate}</td>
                                  <td>{item.applydate}</td>
                                  <td>{item.pstatus}</td>
                                  <td>{item.astatus}</td>                                
                                  <td>
                                  <div className="img text-center">
                                      <div className="eachicon">
                                      <img onClick={handleShowreviewapply} src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" />  

                                      </div>
                                      <div className="eachicon">
                                      <GrAttachment onClick={handleShowAtt}/>

                                      </div>
                                      <div className="eachicon">
                                        <RiCheckboxMultipleLine onClick={handleOpenApprv}/>
                                      </div>
                                      {/* {
                                        onClick={()=>handleaction(item.id,drptoggler)}
                                        drpshow==item.id?
                                        <div className="drpdwn">
                                            <ul>
                                              <li><p onClick={handleShowreviewapply}>Review Apply</p></li>
                                              <li><p onClick={handleShowAtt}>Attachment Files</p></li>
                                              <li><p onClick={handleOpenApprv}>Approve Application</p></li>
                                              <li><p onClick={handleopenDecl}>Decline Application</p></li>
                                            </ul>
                                        </div>
                                        :""
                                      } */}
                                    </div>  
                                  </td>
                                </tr>
                              )

                            }
                            
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">

                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffset+1} to {itemOffset+10} of {manageonlinetable.datatable.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                </Col>
               
              </Row>
          </div>
    </>
  )
}

export default ManageOnlineAdMbComp