import React from 'react'
import $ from 'jquery';

const Datepicker2 = () => {

  // var hello = new Datepicker('#datepicker');
  return (
    <>
         {/* <input type="text" id="datepicker"/> */}
    </>
  )
}

export default Datepicker2