import React,{useState} from 'react'
import HeaderOfTDashComp from './subComponents/HeaderOfTDashComp'
import SidebarofTdash from './subComponents/SidebarofTdash'
import { attendanceDatateacher, headerpropst } from '../../../utils/DashboardApi/TeacherDashboardApi'
import { Button,Row,Col } from 'react-bootstrap'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import PillSmall from '../../subComponents/CustomSubComponents/PillSmall'
const handleReqCorrection=()=>{

}
const TeaAttendanceComp = () => {
    const [startDate,setStartDate] = useState("")
    const handleStartDate=(myvalofstartdate)=>{
        
        
        if(myvalofstartdate != null){

            var stdtmonth = myvalofstartdate.$M + 1;
            var stdtfinal = myvalofstartdate.$y + '-'+ stdtmonth +'-'+ myvalofstartdate.$D;
            setStartDate(stdtfinal)
        }
      }
      const [endDate,setEndtDate] = useState("")
      const handleEndDate=(myvalenddate)=>{
        if(myvalenddate != null){

            var stdtmonth = myvalenddate.$M + 1;
            var edtfinal = myvalenddate.$y + '-'+ stdtmonth +'-'+ myvalenddate.$D;
            setEndtDate(edtfinal)
        }
      }
  return (
    <>
    <div className="teadashcomp">
            <SidebarofTdash/>
            <div className="rightpartcontentwrap">
            <HeaderOfTDashComp heading="Good Morning Farhana" myprops={headerpropst}/>
            <div className="tabPane">
            <div className="tabpaneheightadjust tabpanerightattandancetab">
                    <div className="atttabbox">
                    <Row>
                        <Col lg={8} className="m-auto">
                        <Row className='firstrow'>
                            
                            <Col  className="d-flex align-items-end">
                            
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={startDate}
                                    
                                    onChange={(newValue) => {
                                        handleStartDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} error={false} />}
                                />
                                </LocalizationProvider>
                           
                            </Col>
                            <Col className="d-flex align-items-end">
                            
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    value={endDate}
                                    
                                    onChange={(newValue) => {
                                        handleEndDate(newValue);
                                    }}
                                    renderInput={(params) => <TextField {...params} error={false} />}
                                />
                                </LocalizationProvider>
                            </Col>
                            <Col className="d-flex align-items-end">
                                <Button className='reqcorrectionbutton' onClick={handleReqCorrection}>Request for correction</Button>
                            </Col>
                        </Row>
                        </Col>
                    </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="buttonview">
                                    <div className="flexwrap">
                                        <div className="totalpresentpill">
                                            <p>Total Present : 00</p>
                                        </div>
                                        <div className="totalpresentpill totalabsentpill">
                                            <p>Total Absent : 00</p>
                                        </div>
                                        <div className="totalpresentpill totallatepill">
                                            <p>Total Late : 00</p>
                                        </div>
                                        <div className="totalpresentpill totalearlyleave">
                                            <p>Total Present this : 00</p>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                
                                                attendanceDatateacher.header.map((item,key)=>{
                                                    return (
                                                        <th>
                                                            {item.headeritem}
                                                            {
                                                                item.img?
                                                                <img src={item.img} alt="" />
                                                                
                                                                :
                                                                ""
                                                            }
                                                        </th>
                                                    )
                                                })
                                            }                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            attendanceDatateacher.innerTableData.map((item,key)=>{
                                                return (
                                                    <tr>
                                                        <td>
                                                            {item.SL}
                                                        </td>
                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            {item.schooltime}
                                                        </td>
                                                        <td>
                                                            {item.intime}
                                                        </td>
                                                        <td>
                                                            {item.outtime}
                                                        </td>
                                                        
                                                        
                                                        <td>
                                                            
                                                            {
                                                            item.type=="Present"?
                                                            <PillSmall class={item.type} fontsize="12px" text={item.type} bg="rgba(0, 202, 85, 0.05)" textcolor="rgba(0,202,85, 1)" />
                                                            :
                                                            
                                                            item.type=="Late"?
                                                            <PillSmall class={item.type} fontsize="12px" text={item.type} bg=" rgba(255, 173, 0, 0.05)" textcolor="rgba(255, 173, 0, 1)" />
                                                            :
                                                            item.type=="Early Leave"?
                                                            <PillSmall class="Earlyleave" fontsize="12px" text={item.type} bg="rgba(100, 158, 255, 0.05)" textcolor="rgba(100, 158, 255, 1)" />
                                                            :
                                                            ""    
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default TeaAttendanceComp