import React,{useState,useEffect} from 'react'
import CommonButton from '../../subComponents/CommonButton'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import {Row,Col,Modal,Button} from 'react-bootstrap'
import {AiOutlinePlus} from 'react-icons/ai'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { flexValueTable } from '../../../utils/AdminDashApi/FlexValue'
import ReactPaginate from 'react-paginate';
import { roleMgmtApi, rolemgmtmodelApi } from '../../../utils/AdminDashApi/RoleMgmtApi'
import { optionsStatus,roledrp } from '../../Helper'
import { roleApi } from '../../../utils/AdminDashApi/RoleApi'
const RoleMgmtComp = () => {
    const [activefrom,setactivefrom] = useState("")
  const [activeto,setactiveTo] = useState("")
  const [show,setShow] = useState("")
  const [show2,setShow2] = useState("")
  const [roleDrp,setRoledrp] = useState("")
  const handleShow = () => setShow2(true);
  const optionsRole = [
    {
      id:1,
      opt:"Admin"
    },
    {
      id:2,
      opt:"Admin Academic"
    },
    {
      id:3,
      opt:"Employee"
    },
  ]
  const optionsShowt = [
    {
      id:1,
      opt:1
    },
    {
      id:2,
      opt:2
    },
    {
      id:3,
      opt:3
    },
  ]
  const [statusvalueerr,setstatusvalueerr] = useState("")
 
  const drpfuncRolevalue=(myflexval)=>{
    setRoledrp(myflexval)
  }
    const handleActiveFrom = (activefromval)=>{

        if(activefromval != null){
    
            var dtmnt = activefromval.$M + 1;
            var dtfinalc = activefromval.$y + '-'+ dtmnt +'-'+ activefromval.$D;
            setactivefrom(dtfinalc)
        }
        
        
    }
      const handleActiveTo = (activetoval)=>{
    
        if(activetoval != null){
    
            var dtmnt = activetoval.$M + 1;
            var dtfinalc = activetoval.$y + '-'+ dtmnt +'-'+ activetoval.$D;
            setactiveTo(dtfinalc)
        }
        
        
    }
    const [show3, setShow3] = useState(false);
    const handleShow3 = () => setShow3(true);
  const [currentItems, setCurrentItems] = useState(null);
  const [currentItemsmodel, setCurrentItemsmodel] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage,setitemsPerPage] = useState(10);
  const [paginatenumselected,setpaginatenumselected] = useState(0)
  const [pageCount2, setPageCount2] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset2, setItemOffset2] = useState(0);
  const [itemsPerPage2,setitemsPerPage2] = useState(10);
  const [paginatenumselected2,setpaginatenumselected2] = useState(0)
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(roleMgmtApi.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(roleMgmtApi.data.length / itemsPerPage));
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % roleMgmtApi.data.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setpaginatenumselected(event.selected);
        setItemOffset(newOffset);
      };
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset2 = itemOffset2 + itemsPerPage2;
        console.log(`Loading items from ${itemOffset2} to ${endOffset2}`);
        setCurrentItemsmodel(rolemgmtmodelApi.admindata.slice(itemOffset2, endOffset2));
        setPageCount(Math.ceil(rolemgmtmodelApi.admindata.length / itemsPerPage2));
      }, [itemOffset2, itemsPerPage2]);
    
      // Invoke when user click to request another page.
      const handlePageClickmodel = (event) => {
        const newOffset2 = event.selected * itemsPerPage2 % rolemgmtmodelApi.admindata.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset2}`);
        setpaginatenumselected2(event.selected);
        setItemOffset2(newOffset2);
      };
      const handleClose=()=>{
        setShow2(false)
      }
      const [rolemodalvalue,setrolemodalvalue] = useState("")
      const drpfuncrolemodal=(myid,mystatus)=>{
        setrolemodalvalue(mystatus)
        
          
      }
      
      const handlesearch =()=>{
    
        setactivefrom("")
      
    }
    const [searchinputmodal,setsearchinputmodal] = useState("")
    const [rolesearchkey,setrolesearchkey] = useState("")
    const handlesearchinputmodal=(e)=>{
      setsearchinputmodal(e.target.value)
      console.log(e.target.value)
    }
    const handlesearchmodal=()=>{
      setrolesearchkey(searchinputmodal)
    }
    const drpfuncshow=(myshow)=>{
      setShow(myshow)
    }
    const [ischkmodal,setischkmodal] = useState(false)
    const handleChangechk = event => {
      if (event.target.checked) {
        console.log('✅ Checkbox is checked');
      } else {
        console.log('⛔️ Checkbox is NOT checked');
      }
      setischkmodal(current => !current);
    };
  return (
    <div className="d-flex flexvaluecomp">

       <SidebarOfAdash/>
       <div className="contentpartwidthwrapper">
          <HeaderofAdash head="Role Management"/>
          <div className="contentofadmindash contentflexvalue">
            <Row>
                <Col lg={10} className="m-auto">
              <Row>
                <Col lg={3}>
                  <div className="basicinput">
                    <p></p>
                  <button className='cmbutton' onClick={handleShow}> <AiOutlinePlus/> Assign Role</button>
                  </div>
                </Col> 
                <Col lg={3}>
                <div className="basicinput">
                <p>Role</p>
                <div className="dropdownwrapper">
                  
                    <Dropdown2 func={drpfuncRolevalue} myplaceholder="Select Role" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsRole}/>
                </div>
                </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Active from</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activefrom}
                          onChange={(newvaldob)=>handleActiveFrom(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active From"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Active To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activeto}
                          onChange={(newvaldob)=>handleActiveTo(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active To"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
              </Row>
                
                </Col>
            </Row>
              <Row style={{marginTop:"20px"}}>
                <Col className="m-auto" lg={4}>
                  <Row>
                    <Col lg={3}>
                    <div className="basicinput">
                      <p>Show</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                      </div>
                      </div>
                    </Col>
                    <Col lg={9}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" />
                              <img src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={10} className="m-auto">
                  <div className="flexvaluetable">
                    <table>
                      <thead>
                        <tr>
                            {
                              roleMgmtApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItems?

                          currentItems.map((item,key)=>{
                            return(
                              <tr>
                                <td>{item.username}</td>
                                <td>{item.usertype}</td>
                                <td>{item.rolename}</td>
                                <td>{item.activefrom}</td>
                                <td>{item.activeto}</td>
                                <td>
                                  <div className="img text-center">
                                    <img onClick={handleShow3} src={item.action} alt="" />  
                                  </div>  
                                </td>
                              </tr>
                            )
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">

                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffset+1} to {itemOffset+10} of {flexValueTable.data.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                </Col>
               
              </Row>

              <Modal dialogClassName="mymodalrolemgmt" show={show2} onHide={handleClose} backdrop="static"
              keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">User Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col lg={8}>
                      <Row>
                        <Col lg={4}>
                          <div className="basicinput">
                          <p>Role</p>
                          <div className="dropdownwrapper">
                            
                              <Dropdown2 err={statusvalueerr=="err"?"err":""} func={drpfuncrolemodal} myplaceholder="Select Type" fontsize="16" fontfamily="'Poppins', sans-serif"  options={roledrp}/>
                          </div>
                          </div>
                        </Col>
                        <Col lg={5}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" value={searchinputmodal} onChange={handlesearchinputmodal}/>
                              <img onClick={handlesearchmodal} src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                        </Col>
                        <Col lg={3}>
                        <div className="basicinput">
                          <p>Show</p>
                          <div className="dropdownwrapper">
                            
                              <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                          </div>
                          </div>
                        </Col>
                      </Row>
                   
                      <div className="flexvaluetable">
                    <table>
                      <thead>
                        <tr>
                            {
                              rolemgmtmodelApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItemsmodel?

                          currentItemsmodel.map((item,key)=>{
                            if((rolemodalvalue=="" && searchinputmodal=="") || (rolemodalvalue=="Select" && searchinputmodal=="")){
                              return(
                                <>
                                
                                <tr>
                                  <td>
                                      <input
                                      type="checkbox"
                                      value={ischkmodal}
                                      onChange={handleChangechk}
                                      id="modalchk"
                                      name="modalchk"                        
                                  />
                                  </td>
                                  <td>{item.username}</td>
                                  <td>{item.usertype}</td>
                                  <td>{item.status}</td>
                                </tr>
                              
                                </>
                              )
                            }
                            else if(rolemodalvalue=="Select" && searchinputmodal!=="" && item.username.includes(searchinputmodal)){
                              return(
                                <>
                                
                                <tr>
                                  <td>
                                      <input
                                      type="checkbox"
                                      value={ischkmodal}
                                      onChange={handleChangechk}
                                      id="modalchk"
                                      name="modalchk"                        
                                  />
                                  </td>
                                  <td>{item.username}</td>
                                  <td>{item.usertype}</td>
                                  <td>{item.status}</td>
                                </tr>
                              
                                </>
                              )
                            }
                            else if(rolemodalvalue.toLowerCase()==item.usertype.toLowerCase() && searchinputmodal==""){

                              return(
                                <tr>
                                  <td>
                                      <input
                                      type="checkbox"
                                      value={ischkmodal}
                                      onChange={handleChangechk}
                                      id="modalchk"
                                      name="modalchk"                        
                                  />
                                  </td>
                                  <td>{item.username}</td>
                                  <td>{item.usertype}</td>
                                  <td>{item.status}</td>
                                </tr>
                              )
                            }
                            else if(rolemodalvalue!=="" && searchinputmodal!=="" && item.username.includes(searchinputmodal) && rolemodalvalue.toLowerCase()==item.usertype.toLowerCase()){
                              return(
                                <>
                                
                                <tr>
                                  <td>
                                      <input
                                      type="checkbox"
                                      value={ischkmodal}
                                      onChange={handleChangechk}
                                      id="modalchk"
                                      name="modalchk"                        
                                  />
                                  </td>
                                  <td>{item.username}</td>
                                  <td>{item.usertype}</td>
                                  <td>{item.status}</td>
                                </tr>
                              
                                </>
                              )
                            }
                            
                            
                             
                            
                            
                               else if(searchinputmodal!=="" && item.username.includes(searchinputmodal) && rolemodalvalue==""){
                                  return(
                                    <>
                                  
                                  <tr>
                                    <td>
                                        <input
                                        type="checkbox"
                                        value={ischkmodal}
                                        onChange={handleChangechk}
                                        id="modalchk"
                                        name="modalchk"                        
                                    />
                                    </td>
                                    <td>{item.username}</td>
                                    <td>{item.usertype}</td>
                                    <td>{item.status}</td>
                                  </tr>
                                
                                  </>
                                  )
                                }
                              
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                    <Row>
                  <Col lg={12} style={{textAlign:"center",margin:"20px 0"}}>
                        <p className='pagileft'>Showing {itemOffset2+1} to {itemOffset2+10} of {rolemgmtmodelApi.admindata.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={12} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClickmodel}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                    </Col>
                    <Col lg={4}>
                    <div className="flexvaluetable rightmodaltable">
                    <table>
                      <thead>
                        <tr>
                            {
                              rolemgmtmodelApi.selectRoles.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          rolemgmtmodelApi?

                          rolemgmtmodelApi.selectRoles.data.map((item,key)=>{
                         
                              return(
                                <>
                                
                                <tr>
                                  <td>
                                      <input
                                      type="checkbox"
                                      value={ischkmodal}
                                      onChange={handleChangechk}
                                      id="modalchk"
                                      name="modalchk"                        
                                  />
                                  </td>
                                  <td>{item.usertype}</td>
                                </tr>
                              
                                </>
                              )
                            
                           
                           
                              
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                  <Row>
                  <Col lg={6} className='mt-2'>
                  <div className="basicinput">
                    <p>Active from</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activefrom}
                          onChange={(newvaldob)=>handleActiveFrom(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active From"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={6} className='mt-2'>
                  <div className="basicinput">
                    <p>Active To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activeto}
                          onChange={(newvaldob)=>handleActiveTo(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active To"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                  </Row>
                    </Col>
                  </Row>

                </Modal.Body>
                <Modal.Footer className="m-auto" style={{borderTop:"0"}}>
                  <button variant="secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button variant="primary" onClick={handleClose}>
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
              </div>
              </div>
              </div>
  )
}

export default RoleMgmtComp