import React from 'react'
import NoticeMobile from '../NoticeMobile'
import OlderPartSlideNoticepage from './OlderPartSlideNoticepage'
const OlderPartMb = () => {
  return (
    <>
        <div className="olderpartNoticePagemb">
                <OlderPartSlideNoticepage subtitle="Older"/>
                
                
            </div>
    </>
  )
}

export default OlderPartMb