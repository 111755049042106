import React from 'react'
import '../../sassFiles/errorpages/notFoundPage.scss'
import { Link } from 'react-router-dom'
const PageisGone410 = () => {
  return (
    <div className="desktop">
    <div className="errorpage404wrap">
        <img style={{transform:"translateX(-11%)"}} src="./assets/images/errorPages/404/410.png" alt="" />
        <h4>THIS PAGE IS GONE</h4>
        <p>You tried to view a page that was deleted and will <br/> most likely never come back</p>
        <Link to="/">Go To Homepage</Link>
    </div>

</div>
  )
}

export default PageisGone410