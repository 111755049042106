import React from 'react'
import MenuComp from '../../components/adminDashboard/desktop/MenuComp'
import MenuMgmtComp from '../../components/adminDashboard/desktop/MenuMgmtComp'

const MenuMgmtPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>  
<div className="desktop">
        <div className="overlayfordrpdwn"></div>
            <MenuMgmtComp/>
        </div>
    </>
  )
}

export default MenuMgmtPage