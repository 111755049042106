import React from 'react'
import { useNavigate } from 'react-router-dom'
const NotificationboxComp = (props) => {
    const navigate = useNavigate()

    const handleLink=(e,mylink)=>{
        navigate(mylink)
        console.log(mylink)
    }
  return (
    <>
        <div className="notificationbox">
            <div className="after"></div>
            <div className="scrollablewrapper">
                {
                    props.api.map((item,key)=>{
                        return(
                            <>
                                 <div className="maintitle">
                                    <h3>{item.heading}</h3>
                                </div>
                                {item.data.map((item2,key2)=>{
                                    return(
                                        <div className="commoncard" onClick={(e)=>handleLink(e,item2.link)}>
                                            <h5>{item2.head}</h5>
                                            <h6>{item2.date}</h6>
                                        </div>
                                    )
                                })}
                            </>
                        )
                    })
                }
                
           
            
            
          

            </div>
        </div>
    </>
  )
}

export default NotificationboxComp