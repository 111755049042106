import React,{useState,useEffect} from 'react'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import {Row,Col,Modal,Button} from 'react-bootstrap'
import {AiOutlinePlus} from 'react-icons/ai'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import ReactPaginate from 'react-paginate';
import { roledrp } from '../../Helper'

import { menumgmtApi,menumgmtmodelApi } from '../../../utils/AdminDashApi/MenuMgmtApi'
const MenuMgmtComp = () => {
    const [activefrom,setactivefrom] = useState("")
  const [activeto,setactiveTo] = useState("")
  const [show,setShow] = useState("")
  const [show2,setShow2] = useState("")
  
 
  const [roleDrp,setRoledrp] = useState("")
  const handleShow = () => setShow2(true);
  const optionsRole = [
    {
      id:1,
      opt:"Admin"
    },
    {
      id:2,
      opt:"Admin Academic"
    },
    {
      id:3,
      opt:"Employee"
    },
  ]
  const optionsShowt = [
    {
      id:0,
      opt:"Select"
    }
    ,{
      id:1,
      opt:1
    },
    {
      id:2,
      opt:2
    },
    {
      id:3,
      opt:3
    },
  ]
  const [mainpagesearch,setmainpagesearch] = useState("")
  const handlemainpagesearch=(e)=>{
    setmainpagesearch(e.target.value)
  }
  const [statusvalueerr,setstatusvalueerr] = useState("")
 
  const drpfuncRolevalue=(myroleval,myid)=>{
    setRoledrp(myroleval)    
  }
    const handleActiveFrom = (activefromval)=>{

        if(activefromval != null){
    
            var dtmnt = activefromval.$M + 1;
            var dtfinalc = activefromval.$y + '-'+ dtmnt +'-'+ activefromval.$D;
            setactivefrom(dtfinalc)
        }
        
        
    }
      const handleActiveTo = (activetoval)=>{
    
        if(activetoval != null){
    
            var dtmnt = activetoval.$M + 1;
            var dtfinalc = activetoval.$y + '-'+ dtmnt +'-'+ activetoval.$D;
            setactiveTo(dtfinalc)
        }
        
        
    }
    const [show3, setShow3] = useState(false);
    const [modalitem,setmodalitem] = useState(null)
    const handleShow3 = (e,myitem) =>{
      setShow3(true)
      setmodalitem(myitem)
      setrolemodalvalue2(myitem.rolename)
      console.log(rolemodalvalue2)
    };
    const handleClosemenumodal= ()=>{
      setShow3(false)
    }
  const [currentItems, setCurrentItems] = useState(null);
  const [currentItemsmodel, setCurrentItemsmodel] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageCountfiltered, setPageCountfiltered] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemOffsetfiltered, setItemOffsetfiltered] = useState(0);
  const [itemsPerPage,setitemsPerPage] = useState(10);
  const [paginatenumselected,setpaginatenumselected] = useState(0)
  const [paginatenumselectedfiltered,setpaginatenumselectedfiltered] = useState(0)
  const [pageCount2, setPageCount2] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset2, setItemOffset2] = useState(0);
  const [itemsPerPage2,setitemsPerPage2] = useState(10);
  
  const [itemsPerPagefiltered,setitemsPerPagefiltered] = useState(10);
  const [paginatenumselected2,setpaginatenumselected2] = useState(0)
  const [filteredArr,setfilteredArr] = useState([])
  const mainfilter=(searchkey,arr)=>{    
    let blankarr =[]
    arr.map((item,key)=>{
    
        if(item.rolename.includes(searchkey) && searchkey!==""){
                  
          blankarr.push(item)
        }
        if(item.rolename.toLowerCase()==roleDrp.toLowerCase() && searchkey==""){
          blankarr.push(item)
        }
      })
      setfilteredArr(blankarr)
    
  }
  const [tempfilteredarr,settempfilteredarr] = useState([])
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;        
        setCurrentItems(menumgmtApi.data.slice(itemOffset, endOffset));        
        setPageCount(Math.ceil(menumgmtApi.data.length / itemsPerPage));
        
      }, [itemOffset, itemsPerPage]);
      let [pagecountoffilter,setpagecountoffilter] = useState(filteredArr.length)
      useEffect(()=>{
        const endOffsetfiltered = itemOffsetfiltered + itemsPerPagefiltered;
        settempfilteredarr(filteredArr.slice(itemOffsetfiltered, endOffsetfiltered));
        setPageCountfiltered(Math.ceil(pagecountoffilter / itemsPerPagefiltered));
        setpagecountoffilter(filteredArr.length)
      },[itemOffsetfiltered,itemsPerPagefiltered,filteredArr,tempfilteredarr])
    
      // Invoke when user click to request another page.
      const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % menumgmtApi.data.length;        
        setpaginatenumselected(event.selected);
        setItemOffset(newOffset);
      };
      const handlePageClickfiltered = (event) => {
        const newOffsetf = event.selected * itemsPerPagefiltered % pagecountoffilter;        
        setpaginatenumselectedfiltered(event.selected);
        setItemOffsetfiltered(newOffsetf);
      };
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset2 = itemOffset2 + itemsPerPage2;        
        setCurrentItemsmodel(menumgmtApi.data.slice(itemOffset2, endOffset2));
        setPageCount2(Math.ceil(menumgmtApi.data.length / itemsPerPage2));
      }, [itemOffset2, itemsPerPage2]);
    
      // Invoke when user click to request another page.
      const handlePageClickmodel = (event) => {
        const newOffset2 = event.selected * itemsPerPage2 % menumgmtApi.data.length;
        
        setpaginatenumselected2(event.selected);
        setItemOffset2(newOffset2);
      };
      const handleClose=()=>{
        setShow2(false)
      }
      const [rolemodalvalue,setrolemodalvalue] = useState("")
      const [rolemodalvalue2,setrolemodalvalue2] = useState("")
      const drpfuncrolemodal=(myid,mystatus)=>{
        setrolemodalvalue(mystatus)
        
          
      }
      const drpfuncrolemodal2=(mystatus,myid)=>{
        setrolemodalvalue2(mystatus)
        console.log("manualselect" , mystatus)
          
      }
      
      const handlesearch =()=>{
    
        setactivefrom("")
      
    }
    const [searchinputmodal,setsearchinputmodal] = useState("")
    const [rolesearchkey,setrolesearchkey] = useState("")
    const handlesearchinputmodal=(e)=>{
      setsearchinputmodal(e.target.value)      
    }
    const handlesearchmodal=()=>{
      setrolesearchkey(searchinputmodal)
    }
    const drpfuncshow=(id,myshow)=>{
      setShow(id)
      console.log(id)
    }
    
    const [ischkmodal,setischkmodal] = useState(false)
    const handleChangechk = event => {
      if (event.target.checked) {
        console.log('✅ Checkbox is checked');
      } else {
        console.log('⛔️ Checkbox is NOT checked');
      }
      setischkmodal(current => !current);
    };
    const handleModaleditsave=()=>{
      console.log(rolemodalvalue2)
      setShow3(false)
    }

   
   

    useEffect(()=>{
      mainfilter(mainpagesearch,menumgmtApi.data)      
    },[mainpagesearch])
  
  return (
    <div className="d-flex flexvaluecomp">

       <SidebarOfAdash/>
       <div className="contentpartwidthwrapper">
          <HeaderofAdash head="Menu Role"/>
          <div className="contentofadmindash contentflexvalue">
            <Row>
                <Col lg={10} className="m-auto">
              <Row>
                <Col lg={3}>
                  <div className="basicinput">
                    <p></p>
                  <button className='cmbutton' onClick={handleShow}> <AiOutlinePlus/> Assign Role</button>
                  </div>
                </Col> 
                <Col lg={3}>
                <div className="basicinput">
                <p>Role</p>
                <div className="dropdownwrapper">
                  
                    <Dropdown2 func={drpfuncRolevalue} myplaceholder="Select Role" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsRole}/>
                </div>
                </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Active from</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activefrom}
                          onChange={(newvaldob)=>handleActiveFrom(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active From"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Active To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activeto}
                          onChange={(newvaldob)=>handleActiveTo(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active To"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
              </Row>
                
                </Col>
            </Row>
              <Row style={{marginTop:"20px"}}>
                <Col className="m-auto" lg={4}>
                  <Row>
                    <Col lg={3}>
                    <div className="basicinput">
                      <p>Show</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                      </div>
                      </div>
                    </Col>
                    <Col lg={9}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" value={mainpagesearch} onChange={handlemainpagesearch}/>
                              <img src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={10} className="m-auto">
                  <div className="flexvaluetable">
                    <table>
                      <thead>
                        <tr>
                            {
                              menumgmtApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      {
                        mainpagesearch==""?
                        <tbody>
                        {
                          roleDrp?
                         

                          tempfilteredarr.map((item,key)=>{
                            return(
                              <tr key={key}>
                                <td>{item.menuname}</td>
                                <td>{item.url}</td>
                                <td>{item.rolename}</td>
                                <td>{item.activefrom}</td>
                                <td>{item.activeto}</td>
                                <td>
                                  <div className="img text-center">
                                    <img onClick={(e)=> handleShow3(e,item)} src={item.action} alt="" />  
                                  </div>  
                                </td>
                              </tr>
                            )
                          })
                          :
                          
                          (currentItems?

                          currentItems.map((item,key)=>{
                            return(
                              <tr key={key}>
                                <td>{item.menuname}</td>
                                <td>{item.url}</td>
                                <td>{item.rolename}</td>
                                <td>{item.activefrom}</td>
                                <td>{item.activeto}</td>
                                <td>
                                  <div className="img text-center">
                                    <img onClick={(e)=> handleShow3(e,item)} src={item.action} alt="" />  
                                  </div>  
                                </td>
                              </tr>
                            )
                          })
                          :"")
                        }
                      </tbody>
                        :
                        <tbody>
                        {
                          filteredArr?

                          tempfilteredarr.map((item,key)=>{
                            return(
                              <tr key={key}>
                                <td>{item.menuname}</td>
                                <td>{item.url}</td>
                                <td>{item.rolename}</td>
                                <td>{item.activefrom}</td>
                                <td>{item.activeto}</td>
                                <td>
                                  <div className="img text-center">
                                    <img onClick={(e)=> handleShow3(e,item)} src={item.action} alt="" />  
                                  </div>  
                                </td>
                              </tr>
                            )
                          })
                          :""
                        }
                        
                        </tbody>
                        
                      }
                      
                         
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">
                    {
                      mainpagesearch?
                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffsetfiltered+1} to {itemOffsetfiltered+10} of {filteredArr.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClickfiltered}
                      pageRangeDisplayed={5}
                      pageCount={pageCountfiltered}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                      
                      :
                      
                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffset+1} to {itemOffset+10} of {menumgmtApi.data.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                    }
                </Col>
               
              </Row>

              <Modal dialogClassName="mymodalrolemgmt" show={show2} onHide={handleClose} backdrop="static"
              keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Menu Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col lg={10} className="m-auto">
                      <Row>
                        <Col lg={4}>
                          <div className="basicinput">
                          <p>Role</p>
                          <div className="dropdownwrapper">
                            
                              <Dropdown2 err={statusvalueerr=="err"?"err":""} func={drpfuncrolemodal} myplaceholder="Select Type" fontsize="16" fontfamily="'Poppins', sans-serif"  options={roledrp}/>
                          </div>
                          </div>
                        </Col>
                        <Col lg={5}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" value={searchinputmodal} onChange={handlesearchinputmodal}/>
                              <img onClick={handlesearchmodal} src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                        </Col>
                        <Col lg={3}>
                        <div className="basicinput">
                          <p>Show</p>
                          <div className="dropdownwrapper">
                            
                              <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                          </div>
                          </div>
                        </Col>
                      </Row>
                   
                      <div className="flexvaluetable">
                    <table>
                      <thead>
                        <tr>
                            {
                              menumgmtmodelApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItemsmodel?

                          currentItemsmodel.map((item,key)=>{
                            if((rolemodalvalue=="" && searchinputmodal=="") || (rolemodalvalue=="Select" && searchinputmodal=="")){
                              return(
                                <>
                                
                                <tr key={key}>
                                  <td>
                                      <input
                                      type="checkbox"
                                      value={ischkmodal}
                                      onChange={handleChangechk}
                                      id="modalchk"
                                      name="modalchk"                        
                                  />
                                  </td>
                                  <td>{item.menuname}</td>
                                  <td>{item.url}</td>
                                </tr>
                              
                                </>
                              )
                            }
                            else if(rolemodalvalue=="Select" && searchinputmodal!=="" && item.menuname.includes(searchinputmodal)){
                              return(
                                <>
                                
                                <tr key={key}>
                                  <td>
                                      <input
                                      type="checkbox"
                                      value={ischkmodal}
                                      onChange={handleChangechk}
                                      id="modalchk"
                                      name="modalchk"                        
                                  />
                                  </td>
                                  <td>{item.username}</td>
                                  <td>{item.usertype}</td>
                                  <td>{item.status}</td>
                                </tr>
                              
                                </>
                              )
                            }
                            // else if(rolemodalvalue.toLowerCase()==item.usertype.toLowerCase() && searchinputmodal==""){

                            //   return(
                            //     <tr>
                            //       <td>
                            //           <input
                            //           type="checkbox"
                            //           value={ischkmodal}
                            //           onChange={handleChangechk}
                            //           id="modalchk"
                            //           name="modalchk"                        
                            //       />
                            //       </td>
                            //       <td>{item.username}</td>
                            //       <td>{item.usertype}</td>
                            //       <td>{item.status}</td>
                            //     </tr>
                            //   )
                            // }
                            // else if(rolemodalvalue!=="" && searchinputmodal!=="" && item.menuname.includes(searchinputmodal) && rolemodalvalue.toLowerCase()==item.usertype.toLowerCase()){
                            //   return(
                            //     <>
                                
                            //     <tr>
                            //       <td>
                            //           <input
                            //           type="checkbox"
                            //           value={ischkmodal}
                            //           onChange={handleChangechk}
                            //           id="modalchk"
                            //           name="modalchk"                        
                            //       />
                            //       </td>
                            //       <td>{item.username}</td>
                            //       <td>{item.usertype}</td>
                            //       <td>{item.status}</td>
                            //     </tr>
                              
                            //     </>
                            //   )
                            // }
                            
                            
                             
                            
                            
                               else if(searchinputmodal!=="" && item.menuname.includes(searchinputmodal) && rolemodalvalue==""){
                                  return(
                                    <>
                                  
                                  <tr key={key}>
                                    <td>
                                        <input
                                        type="checkbox"
                                        value={ischkmodal}
                                        onChange={handleChangechk}
                                        id="modalchk"
                                        name="modalchk"                        
                                    />
                                    </td>
                                    <td>{item.username}</td>
                                    <td>{item.usertype}</td>
                                    <td>{item.status}</td>
                                  </tr>
                                
                                  </>
                                  )
                                }
                              
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                    <Row>
                  <Col lg={12} style={{textAlign:"center",margin:"20px 0"}}>
                        <p className='pagileft'>Showing {itemOffset2+1} to {itemOffset2+10} of {menumgmtmodelApi.admindata.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={12} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClickmodel}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                    </Col>
                    
                  </Row>

                </Modal.Body>
                <Modal.Footer className="m-auto" style={{borderTop:"0"}}>
                  <button variant="secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button variant="primary" onClick={handleClose}>
                    Save
                  </button>
                </Modal.Footer>
              </Modal>


              <Modal dialogClassName="mymodalmenueid" show={show3} onHide={handleClosemenumodal} backdrop="static"
              keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Menu Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col lg={10} className="m-auto">
                      <Row>
                        <Col lg={4}>
                          <div className="basicinput">
                          <p>Role</p>
                          <div className="dropdownwrapper">
                            
                              <Dropdown2 err={statusvalueerr=="err"?"err":""} func={drpfuncrolemodal2} myplaceholder={modalitem?modalitem.rolename:"Select role"} fontsize="16" fontfamily="'Poppins', sans-serif"  options={roledrp}/>
                          </div>
                          </div>
                        </Col>
                        <Col lg={5}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" value={searchinputmodal} onChange={handlesearchinputmodal}/>
                              <img onClick={handlesearchmodal} src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                        </Col>
                        <Col lg={3}>
                        <div className="basicinput">
                          <p>Show</p>
                          <div className="dropdownwrapper">
                            
                              <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                          </div>
                          </div>
                        </Col>
                      </Row>
                   
                      <div className="flexvaluetable">
                    <table>
                      <thead>
                        <tr>
                            {
                              menumgmtmodelApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItemsmodel?
                          
                          
                            currentItemsmodel.map((item,key)=>{
                             
                              if(((rolemodalvalue2=="" && searchinputmodal=="")) && rolemodalvalue2.toLowerCase()==item.rolename.toLowerCase()){
                                return(
                                  <>
                                  
                                  <tr key={key}>
                                    <td>
                                        <input
                                        type="checkbox"
                                        value={ischkmodal}
                                        onChange={handleChangechk}
                                        id="modalchk"
                                        name="modalchk"                        
                                    />
                                    </td>
                                    <td>{item.menuname}</td>
                                    <td>{item.url}</td>
                                  </tr>
                                
                                  </>
                                )
                              }
                              
                              else if(searchinputmodal!=="" && item.menuname.toLowerCase().includes(searchinputmodal) && rolemodalvalue2==item.rolename){
                                return(
                                  <>
                                  
                                  <tr key={key}>
                                    <td>
                                        <input
                                        type="checkbox"
                                        value={ischkmodal}
                                        onChange={handleChangechk}
                                        id="modalchk"
                                        name="modalchk"                        
                                    />
                                    </td>
                                    <td>{item.menuname}</td>
                                    <td>{item.url}</td>
                                  </tr>
                                
                                  </>
                                )
                              }
                              else if(rolemodalvalue2=="Select" && searchinputmodal==""){
                                <>
                                  
                                  <tr key={key}>
                                    <td>
                                        <input
                                        type="checkbox"
                                        value={ischkmodal}
                                        onChange={handleChangechk}
                                        id="modalchk"
                                        name="modalchk"                        
                                    />
                                    </td>
                                    <td>{item.menuname}</td>
                                    <td>{item.url}</td>
                                  </tr>
                                
                                  </>
                              }
                              else if(rolemodalvalue2.toLowerCase()==item.rolename.toLowerCase() && rolemodalvalue2!=="Select" && searchinputmodal==""){
  
                                return(
                                  <>
                                  
                                  <tr key={key}>
                                    <td>
                                        <input
                                        type="checkbox"
                                        value={ischkmodal}
                                        onChange={handleChangechk}
                                        id="modalchk"
                                        name="modalchk"                        
                                    />
                                    </td>
                                    <td>{item.menuname}</td>
                                    <td>{item.url}</td>
                                  </tr>
                                
                                  </>
                                )
                              }
                              
                              // else if(rolemodalvalue!=="" && searchinputmodal!=="" && item.menuname.includes(searchinputmodal) && rolemodalvalue.toLowerCase()==item.usertype.toLowerCase()){
                              //   return(
                              //     <>
                                  
                              //     <tr>
                              //       <td>
                              //           <input
                              //           type="checkbox"
                              //           value={ischkmodal}
                              //           onChange={handleChangechk}
                              //           id="modalchk"
                              //           name="modalchk"                        
                              //       />
                              //       </td>
                              //       <td>{item.username}</td>
                              //       <td>{item.usertype}</td>
                              //       <td>{item.status}</td>
                              //     </tr>
                                
                              //     </>
                              //   )
                              // }
                              
                              
                               
                              
                              
                                 else if(searchinputmodal!=="" && item.menuname.includes(searchinputmodal) && rolemodalvalue2==""){
                                    return(
                                      <>
                                    
                                    <tr key={key}>
                                    <td>
                                        <input
                                        type="checkbox"
                                        value={ischkmodal}
                                        onChange={handleChangechk}
                                        id="modalchk"
                                        name="modalchk"                        
                                    />
                                    </td>
                                    <td>{item.menuname}</td>
                                    <td>{item.url}</td>
                                  </tr>
                                  
                                    </>
                                    )
                                  }
                                
                            })
                          

                          
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                    <Row>
                  <Col lg={12} style={{textAlign:"center",margin:"20px 0"}}>
                        <p className='pagileft'>Showing {itemOffset2+1} to {itemOffset2+10} of {menumgmtmodelApi.admindata.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={12} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClickmodel}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                    </Col>
                    
                  </Row>

                </Modal.Body>
                <Modal.Footer className="m-auto" style={{borderTop:"0"}}>
                  <button variant="secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button variant="primary" onClick={handleModaleditsave}>
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
              </div>
              </div>
              </div>
  )
}

export default MenuMgmtComp