import React,{useState} from 'react'
import HeaderOfstuDashComp from './subComponents/HeaderOfstuDashComp'
import { headerprops, resulttabdata } from '../../../utils/DashboardApi/StudentDashboardApi'
import SidebarOfsDash from './subComponents/SidebarOfsDash'
import { Row,Col,Button } from 'react-bootstrap'
import Dropdown from 'react-dropdown';
import {BsChevronDown} from 'react-icons/bs'
const StuDashResultComp = () => {
    const optionsemsterresulttab = [
        'First Semester','Second Semester'
      ];
      const defaultOptionsemestertype = optionsemsterresulttab[0];
      const [semesterselectresult,setsemesterselectresult] = useState("")
    
      const drpdwnsemesterselecthandler=(e)=>{        
          setsemesterselectresult(e.value)
          
        }
        const optiontyperesulttab = [
            'Assessment','Result'
          ];
          const defaultOptionstyperesulttab = optiontyperesulttab[0];
          const [typeresulttab,settyperesulttab] = useState("")
        
        const drpdwntyperesulttabhandler=(e)=>{        
            settyperesulttab(e.value)
            
          }
  return (
    <>
            <div className="stu_dash_mainWrap">
        <SidebarOfsDash activeID="2"/>
        <div className="rightpartcontentwrap">
            <HeaderOfstuDashComp heading="Result" myprops={headerprops}/>
            <div className="tabPane">
            <div className="tabpaneheightadjust resulttab">
                    <div className="resulttabbox">
                        <Row>
                            <Col lg={5} style={{margin:"0 auto"}}>
                                <Row>
                                <Col lg={6}>
                                    <div className="basicinfopopulate">
                                        <p>
                                            Select Semeseter
                                        </p>
                                        <div className="dropdownwrapper" id="routinedrp">
                                            <Dropdown open={true} className='filterdropone'  options={optionsemsterresulttab} onChange={(e)=>drpdwnsemesterselecthandler(e)} value={defaultOptionsemestertype} placeholder="Select an option" />
                                            <BsChevronDown/>
                                        </div>
                                    </div>
                                
                                </Col>
                                <Col lg={6}>
                                    <div className="basicinfopopulate">
                                        <p>
                                            Select Type
                                        </p>
                                        <div className="dropdownwrapper" id="routinedrp">
                                            <Dropdown open={true} className='filterdropone'  options={optiontyperesulttab} onChange={(e)=>drpdwntyperesulttabhandler(e)} value={defaultOptionstyperesulttab} placeholder="Select an option" />
                                            <BsChevronDown/>
                                        </div>
                                    </div>
                                
                                </Col>
                                </Row>
                            </Col>
                            
                        </Row>
                        {
                            typeresulttab=="Assessment"?
                            <div className="resulttable">
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                resulttabdata.firstSemester.dataTable.heading.map((item,key)=>{
                                                    return(
                                                        <th key={key}>{item.headline}</th>
                                                    )
                                                })
                                            }
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            resulttabdata.firstSemester.dataTable.tableData.map((item,key)=>{
                                                return(
                                                    <tr key={key}>
                                                        <td>{item.subject}</td>
                                                        <td>{item.subgroup}</td>
                                                        <td>{item.totaltest}</td>
                                                        <td>{item.bestcount}</td>
                                                        <td style={{width:"60%"}}>
                                                            {/* <table style={{marginTop:"0"}}> */}
                                                                <tr>
                                                                {
                                                                    item.assessment.map((item2,key2)=>{
                                                                        return(
                                                                            <td style={{width:"34px"}} key={key2}>{item2.value==0?"-":item2.value}</td>
                                                                        )
                                                                    })
                                                                }
                                                                </tr>
                                                            {/* </table> */}
                                                        </td>
                                                        <td className='customsumtd'><p>10+20+30</p></td>
                                                        <td><p>46.00</p></td>
                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                            :
                            typeresulttab=="Result"?
                            <div className="resulttable resultresulttable">
                            <table >
                                <thead>
                                    <tr>
                                        {
                                            resulttabdata.firstSemester.resultTable.heading.map((item,key)=>{
                                                return(
                                                    <th className="text-center" key={key}>{item.head}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                         resulttabdata.firstSemester.resultTable.tableData.map((item,key)=>{
                                            return(
                                                <tr key={key}>
                                                    <td>{item.subject}</td>    
                                                    <td>{item.assessment}</td>    
                                                    <td>{item.firstsemesterexdam}</td>    
                                                    <td>{item.totalmarkobtained}</td>    
                                                    <td>{item.percentage}</td>    
                                                    <td>{item.grade}</td>    
                                                </tr>
                                            )
                                         })
                                    }
                                    <tr style={{borderBottom:"0"}}>
                                        <td colSpan={2}>Grand Total: 900.00</td>
                                        <td colSpan={2}>Mark Obtained: 805.50</td>
                                        <td>89.50%</td>
                                        <td>A</td>
                                    </tr>
                                </tbody>
                            </table>

                            <div className="stats">
                                <Row>
                                    <Col lg={6} style={{paddingRight:"15px"}}>
                                        <div className="statsboxresulttab">
                                            <h5>Rank</h5>
                                            <div className="centerwrapper">                                          
                                            <p>Total Percentage Obtained = 89.50</p>
                                            <p>Position in section = 14</p>
                                            <p>Position in class = 30</p>                                           
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={6} style={{paddingLeft:"0"}}>
                                        <div className="statsboxresulttab">
                                        <h5>Rank</h5>
                                            <div className="centerwrapper centerwrapperright">                                          
                                            <p>Highest Total Percentage Obtained in Class= 99.50</p>
                                            <p>Highest Total Percentage Obtained in Section = 99.53</p>
                                            <p>2nd Highest Total Percentage Obtained in Class = 98.72</p>                                           
                                            <p>3rd Highest Total Percentage Obtained in Class= 98.71</p>                                           
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="downloadbuttonforresult">
                                    <Button>
                                        Download Now
                                    </Button>
                                </div>
                            </div>
                            </div>
                            :
                            <div className="resulttable resdef">
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                resulttabdata.firstSemester.dataTable.heading.map((item,key)=>{
                                                    return(
                                                        <th key={key}>{item.headline}</th>
                                                    )
                                                })
                                            }
                                            
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            resulttabdata.firstSemester.dataTable.tableData.map((item,key)=>{
                                                return(
                                                    <tr key={key}>
                                                        <td>{item.subject}</td>
                                                        <td>{item.subgroup}</td>
                                                        <td>{item.totaltest}</td>
                                                        <td>{item.bestcount}</td>
                                                        <td style={{width:"50%!important"}}>
                                                            <table style={{marginTop:"0",width:"100%"}}>
                                                                <tr>
                                                                {
                                                                    item.assessment.map((item2,key2)=>{
                                                                        return(
                                                                            <td style={{width:"34px"}} key={key2}>{item2.value==0?"-":item2.value}</td>
                                                                        )
                                                                    })
                                                                }
                                                                </tr>
                                                            </table>
                                                        </td>
                                                        <td><p style={{padding:"0 53px"}}>10+20+30+45+99</p></td>
                                                        <td><p>46.00</p></td>
                                                        
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        }
                        
                    </div>
                </div> 
       
            </div>
        </div>
    </div>
    </>
  )
}

export default StuDashResultComp