import React,{useState} from 'react'
import { Button,Row,Col } from 'react-bootstrap'
import { leavereqstatusData } from '../../../../utils/DashboardApi/TeacherDashboardApi'
import PillSmall from '../../../subComponents/CustomSubComponents/PillSmall'
import Dropdown2 from '../../../subComponents/CustomSubComponents/Dropdown2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import moment from 'moment';
const TeaLeaveFormTab = () => {
    const optionme = [
        {
            opt:'Personal'
        },
        {
            opt:'Casual'
        }
    ]

    const [leavedatefrom, setleavedatefrom] = useState(null);    

    const [leavedateto,setleavedateto] = useState(null)
    
    const [leavetimefrom,setleavetimefrom] = useState(null)
    
    const [leavetimeto,setleavetimeto] = useState(null)
    
    const [reqsubmissiondate,setreqsubmissiondate] = useState(null)

    const [validreason,setvalidreason]= useState(null)

    const [docfile,setdocfile]=useState()

    const [typeofleaverequest,settypeofleaverequest]=useState("")

    const [labelLeavedatafrom,setlabelLeavedatafrom] = useState("")
    
    const [labelLeavetimefrom,setlabelLeavetimefrom] = useState("")
    
    const [labelLeavedatato,setlabelLeavedatato] = useState("")
    
    const [labelLeavetimeto,setlabelLeavetimeto] = useState("")
    
    const [labelreqsubdate,setlabelreqsubdate] = useState("")

    const [labelvalidreason,setlabelvalidreason] = useState("")

    const [labeltypeofleave,setlabeltypeofleave] = useState("")

    const typeleavereq=(myval)=>{
        settypeofleaverequest(myval)
    }

    const docfileuploadhandler = (e)=>{
        
        setdocfile(e.target.files[0]);
    }

    // const handleSubmit=()=>{
    //     // console.log(typeof(value))
    //     if(leavedateto != null){

    //         var lvdtmonth = leavedateto.$M + 1;
    //         var leavedatetost = leavedateto.$y + '-'+ lvdtmonth +'-'+ leavedateto.$D;
    //          console.log(leavedatetost)
    //     }
       
    //     if(leavedatefrom != null){
    //         var lvdfrmonth = leavedatefrom.$M + 1;
    //         var leavedatefrst = leavedatefrom.$y + '-'+ lvdfrmonth +'-'+leavedatefrom.$D ;
            
    //         console.log(leavedatefrst)
            

    //     }
    //     if(reqsubmissiondate != null){
    //         var reqsubmonth = reqsubmissiondate.$M + 1;
    //         var reqsubdaatefinal = reqsubmissiondate.$y + '-'+ reqsubmonth +'-'+reqsubmissiondate.$D ;
            
    //         console.log(reqsubdaatefinal)
            

    //     }
    //     if(leavetimefrom != null){
    //         var leavetimefromtmp = leavetimefrom.$H + ':' + leavetimefrom.$m
    //         console.log('leave time from ' +leavetimefromtmp)

    //     }
    //     if(leavetimeto != null){
    //         var leavetimetotmp = leavetimeto.$H + ':' + leavetimeto.$m
    //         console.log('leave time to ' +leavetimetotmp)

    //     }
           
        
    //     const leaveformdataall={
    //         typeofleavereq:typeofleaverequest,
    //         leavedateto:leavedatetost,
    //         leavedatefrom:leavedatefrst,
    //         leavetimefrom:leavetimefromtmp,
    //         leavetimeto:leavetimetotmp,
    //         reqsubmissiondate:reqsubdaatefinal,
    //         validreason:validreason,
    //         documentfile:docfile,
            
    //     }

    //     console.dir(leaveformdataall)
        
    //   }
    const handleSubmit=(e)=>{
        e.preventDefault()
         console.log(docfile)
         console.log(typeofleaverequest)
         console.log(validreason)
         if(leavedateto != null){
 
             var lvdtmonth = leavedateto.$M + 1;
             var leavedatetost = leavedateto.$y + '-'+ lvdtmonth +'-'+ leavedateto.$D;
              console.log(leavedatetost)
              setlabelLeavedatato("")
 
             
         }else{
             setlabelLeavedatato("Date required")
         }
        
         if(leavedatefrom != null){
             var lvdfrmonth = leavedatefrom.$M + 1;
             var leavedatefrst = leavedatefrom.$y + '-'+ lvdfrmonth +'-'+leavedatefrom.$D ;
             
             console.log(leavedatefrst)
             setlabelLeavedatafrom("")
 
         }else{
             setlabelLeavedatafrom("Date required")
         }
         if(reqsubmissiondate != null){
             var reqsubmonth = reqsubmissiondate.$M + 1;
             var reqsubdaatefinal = reqsubmissiondate.$y + '-'+ reqsubmonth +'-'+reqsubmissiondate.$D ;
             setlabelreqsubdate("")
             console.log(reqsubdaatefinal)
             
             
 
         }else{
             setlabelreqsubdate("Date required")
         }
         if(leavetimefrom != null){
             var leavetimefromtmp = leavetimefrom.$H + ':' + leavetimefrom.$m
             console.log('leave time from ' +leavetimefromtmp)
             setlabelLeavetimefrom("")
         }else{
             setlabelLeavetimefrom("Time required")
         }
         if(leavetimeto != null){
             var leavetimetotmp = leavetimeto.$H + ':' + leavetimeto.$m
             console.log('leave time to ' +leavetimetotmp)
             setlabelLeavetimeto("")
 
         }else{
             setlabelLeavetimeto("Time required")
         }
         if(typeofleaverequest!=""){
             setlabeltypeofleave("")
         }else{
             setlabeltypeofleave("request type required")
         }
         const leaveformdataall={
             typeofleavereq:typeofleaverequest,
             leavedateto:leavedatetost,
             leavedatefrom:leavedatefrst,
             leavetimefrom:leavetimefromtmp,
             leavetimeto:leavetimetotmp,
             reqsubmissiondate:reqsubdaatefinal,
             validreason:validreason,
             documentfile:docfile,
             
         }
         console.log(leaveformdataall.leavetimefrom)
         // if(leaveformdataall.leavedatefrom - leaveformdataall.leavedateto){
         //     console.dir(leaveformdataall)
         // }
         
         if(leaveformdataall.leavedatefrom!=null && leaveformdataall.leavedateto!=null){
             console.log(moment.duration(leaveformdataall.leavetimefrom).asMinutes()-moment.duration(leaveformdataall.leavetimeto).asMinutes())
             // console.log(moment(leaveformdataall.leavetimefrom).diff(moment(leaveformdataall.leavetimeto),'seconds'))
 
             if(moment(leaveformdataall.leavedatefrom).diff(moment(leaveformdataall.leavedateto),'days') < 0){
                 setlabelLeavetimefrom("")
                 setlabelLeavetimeto("")
                 setlabelLeavedatafrom("")
                 setlabelLeavedatato("")
             }
             
             else if(moment(leaveformdataall.leavedatefrom).diff(moment(leaveformdataall.leavedateto),'days') > 0){
                 setlabelLeavedatafrom("date from is greater than date to")
                 setlabelLeavedatato("date to is less than date from")
                 setlabelLeavetimefrom("fix the dates first")
                     setlabelLeavetimeto("fix the date first")
             } 
             
             else if(moment(leaveformdataall.leavedatefrom).diff(moment(leaveformdataall.leavedateto),'days') == 0){
                 setlabelLeavedatafrom("")
                 setlabelLeavedatato("")
                 
                 if((moment.duration(leaveformdataall.leavetimefrom).asMinutes()-moment.duration(leaveformdataall.leavetimeto).asMinutes())>0){
                     setlabelLeavetimefrom("must be greater than TO TIME")
                     setlabelLeavetimeto("must be less than FROM TIME")
                     
                     
                 }else if(  leaveformdataall.leavetimefrom=="" && leaveformdataall.leavetimeto ==""){
                     setlabelLeavetimefrom("time required")
                     setlabelLeavetimeto("time required")  
                 }
                 else if (moment.duration(leaveformdataall.leavetimefrom).asMinutes()== "" && moment.duration(leaveformdataall.leavetimeto).asMinutes()==""){
                     setlabelLeavetimefrom("time required")
                     setlabelLeavetimeto("time required")
                 }
                 else if(moment.duration(leaveformdataall.leavetimefrom).asMinutes()-moment.duration(leaveformdataall.leavetimeto).asMinutes()==0){
                     setlabelLeavetimefrom("time from and to can't be equal")
                     setlabelLeavetimeto("time from and to can't be equal")
                 }else if((moment.duration(leaveformdataall.leavetimefrom).asMinutes()-moment.duration(leaveformdataall.leavetimeto).asMinutes())<0){
                     setlabelLeavetimefrom("")
                     setlabelLeavetimeto("")
                     
                     
                 }
             }
         }else if((leaveformdataall.leavedatefrom)!=null && leaveformdataall.leavedateto==null){
             setlabelLeavedatafrom("")
             setlabelLeavedatato("date required")
         }else if(leaveformdataall.leavedatefrom==null && leaveformdataall.leavedateto!=null){
             setlabelLeavedatafrom("date required")
             setlabelLeavedatato("")
         }else{
             // setlabelLeavedatafrom("")
             // setlabelLeavedatato("")
             // setlabelLeavedatafrom("")
             //     setlabelLeavedatato("")
             setlabelLeavedatafrom("date required")
                 setlabelLeavedatato("date required")
         }
         
         if(validreason!=null){
             setlabelvalidreason("")
         }else{
             setlabelvalidreason("Valid Reason required")
         }
 
         if(leavedatefrom && leavedateto && reqsubmissiondate && leavetimefrom && leavetimeto && typeofleaverequest && validreason &&  labelLeavedatafrom=="" && labelLeavetimefrom=="" && labelLeavedatato=="" && labelLeavetimeto=="" && labelreqsubdate=="" && labelvalidreason=="" && labeltypeofleave==""){
             console.log("Done")
         }
         
     }
     const optionmeleavetype = [
        {
            opt:'Personal'
        },
        {
            opt:'Casual'
        }
    ]


  return (
    <>
        <div className="bodycolorbg">
        <div className="mobilecontainer">
                <div className="heading">
                    <h3>Leave Form</h3>
                </div>
                <div className="samebox leaveformpart leaveformpartteachermb">
                        
                        <div className="basicinfopopulate">
                        <Row>
                            <Col lg={4}>                            
                                <p>Name</p>
                                <input type="text" name="name" placeholder='-'/>
                            </Col>
                            <Col xs={6} className="pdrightcol5">
                                <p>ID</p>
                                <input type="text" name="name" placeholder='-'/>
                            </Col>
                            <Col xs={6} className="pdleftcol5">
                                <p>Class</p>
                                <input type="text" name="name" placeholder='-'/>
                            </Col>
                            <Col xs={12}>
                                <p>Mobile Number</p>
                                <div className="inputgap">
                                <input type="text" name="name" placeholder='-'/>

                                </div>
                            </Col>
                        </Row>
                        <Row>
                        <Col lg={6}>
                                <Row>
                                    <Col lg={12}>
                                        <p>Type of leave request</p>
                                        <div className="inputgap">
                                        <div className="dropdownwrapper">
                                        <Dropdown2 func={typeleavereq} myplaceholder="Select Leave Type" fontsize="16" fontfamily="'Poppins', sans-serif" options={optionmeleavetype}/>

                                        </div>
                                        <label>{labeltypeofleave}</label>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <p>Leave Date From</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="inputgap">
                                                <DatePicker
                                                    value={leavedatefrom}
                                                    onChange={(newValue) => {
                                                        setleavedatefrom(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "Leave date from"
                                                      }} error={false}/>}
                                                />
                                                <label>{labelLeavedatafrom}</label>
                                            </div>
                                                </LocalizationProvider>
                                    </Col>
                                    <Col lg={6}>
                                        <p>Leave Date To</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="inputgap">
                                                <DatePicker
                                                    value={leavedateto}
                                                    onChange={(newValue) => {
                                                        setleavedateto(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "Leave date to"
                                                      }} error={false} />}
                                                />
                                                <label>{labelLeavedatato}</label>
                                            </div>
                                                </LocalizationProvider>
                                    </Col>
                                   
                                </Row>
                                <Row>
                                <Col lg={6}>
                                        <p>Leave Time From</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="inputgap">
                                        <TimePicker
                                            style={{border:"1px solid red"}}
                                            value={leavetimefrom}
                                            onChange={(newValue) => {
                                                setleavetimefrom(newValue);
                                            }}
                                            renderInput={(params) => <TextField {...params} inputProps={{
                                                ...params.inputProps,
                                                placeholder: "Leave time from"
                                              }} error={false} />}
                                        />
                                            <label>{labelLeavetimefrom}</label>
                                            </div>
                                        </LocalizationProvider>
                                    </Col>
                                    <Col lg={6}>
                                        <p>Leave Time To </p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="inputgap">
                                            <TimePicker
                                                
                                                value={leavetimeto}
                                                onChange={(newValue) => {
                                                    setleavetimeto(newValue);
                                                }}
                                                renderInput={(params) => <TextField {...params} inputProps={{
                                                    ...params.inputProps,
                                                    placeholder: "Leave time to"
                                                }} error={false} />}
                                            />
                                            <label>{labelLeavetimeto}</label>
                                            </div>
                                        </LocalizationProvider>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <p>Request Submission date</p>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="inputgap">
                                                <DatePicker
                                                    value={reqsubmissiondate}
                                                    onChange={(newValue) => {
                                                        setreqsubmissiondate(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField {...params} inputProps={{
                                                        ...params.inputProps,
                                                        placeholder: "Request submission date"
                                                      }} error={false} />}
                                                />
                                                <label>{labelreqsubdate}</label>
                                            </div>
                                                </LocalizationProvider>
                                       
                                    </Col>
                                    <Col lg={6}>
                                        <p>Documents &#40;if any&#41;</p>
                                        <div className="inputgap">
                                            <input className='fileinputbutton' style={{paddingTop:"4px"}} type="file" onChange={docfileuploadhandler} placeholder=''/>
                                            
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg={6}>
                                <div className="alagwrapper" style={{height:"100%!important"}}>
                                    <p>Valid Reason</p>
                                    <div className="inputgap" style={{height:"83%"}}>
                                        <textarea style={{background:"#fff",color:"#000"}} className="textbox" type="text" name="validreason" value={validreason} onChange={(e)=>setvalidreason(e.target.value)} placeholder=''/>
                                        <label>{labelvalidreason}</label>
                                    </div>
                                </div> 
                            </Col>
                        </Row>
                            
                        </div>
                        <div className="bottombuttondiv">
                            <Button onClick={handleSubmit}>Submit</Button>
                        </div>
                    </div>
                    <div className="samebox leaverequeststatustmb">
                        <div className="headingofleaverea">
                            <h4>Leave Request Status</h4>
                        </div>
                        <div className="levereqstatustablt " >
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            leavereqstatusData.heading.map((item,key)=>{
                                                return(
                                                    <th key={key}>
                                                        {item.headline}
                                                    </th>
                                                )
                                            })
                                        } 
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        leavereqstatusData.innnerData.map((item,key)=>{
                                            return(
                                                <tr>
                                                    <td>{item.type}</td>
                                                    <td>{item.reason}</td>
                                                    <td>{item.from}</td>
                                                    <td>{item.to}</td>
                                                    <td>{item.duration}</td>
                                                    <td>{item.status=="Accepted"?
                                                    <PillSmall class="Accepted" fontsize="12px" text={item.status} bg="rgba(0, 202, 85, 0.05)" textcolor="rgba(0,202,85, 1)" />
                                                    :
                                                    <PillSmall class={item.status} fontsize="12px" text={item.status} bg=" rgba(255, 173, 0, 0.05)" textcolor="rgba(255, 173, 0, 1)" />
                                                    }</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                        </div>
                    </div>
        </div>
        </div>
    </>
  )
}

export default TeaLeaveFormTab