import React,{useState} from 'react'
import {Row,Col,Form} from 'react-bootstrap'
import CommonButton from '../../subComponents/CommonButton'

const ContactPageComponent = () => {
    const [contactformdata,setcontactformdata] = useState({
        name:"",
        email:"",
        phone:"",
        info:"",
        subject:"",
        message:"",
        captcha:""
      })
    const contactcardsdata = [
        {
            heading:"Corporate Office",
            address:"13, Larmini Street Wari, Dhaka",
            phone:"88027115628",
            email:"info@britishstandardschool.com"
        },
        {
            heading:"Junior Section",
            campus:"Campus 3",
            address:"26/1, Larmini Street Wari, Dhaka",
            phone:"88029512540",
            email:"juniorsection@britishstandardschool.com"
        },
        {
            heading:"Middle Section",
            campus:"Campus 4",
            address:"13, Larmini Street Wari, Dhaka",
            phone:"880223388129",
            email:"middlesection@britishstandardschool.com"
        },
        {
            heading:"Senior Section",
            campus:"Campus 1",
            address:"2/7, Nawab Street Wari, Dhaka",
            phone:"880247116519",
            email:"seniorsection@britishstandardschool.com"
        },
    
      ]
      const [validationnamemsg,setvalidationnamemsg] = useState("")
      const [validationemailmsg,setvalidationemailmsg] = useState("")
      const [validationphonenumber,setvalidationphonenumber] = useState(false)
      const [validationsubject,setvalidationsubject] = useState("")
      const [validationmessage,setvalidationmessage] = useState("")
    
      const [bordererrorname,setborderrorname] = useState(false)
      const [bordererroremail,setbordererroremail] = useState(false)
      const [bordererrorphone,setbordererrorphone] = useState(false)
      const [bordererrorsubject,setbordererrorsubject] = useState(false)
      const [bordererrormessage,setbordererrormessage] = useState(false)
    

      const handleInput=(e)=>{
        setcontactformdata({...contactformdata,[e.target.name]: e.target.value})
      }
      const handleSubmit=(e)=>{
        e.preventDefault()
        console.log(contactformdata)
        if (contactformdata.name && contactformdata.name.length < 3 || contactformdata.name.length > 50) {
            setvalidationnamemsg('Name must contain between 3 and 50 characters')
            setborderrorname(true)
          }
          else if (contactformdata.name.length==0){
             setvalidationnamemsg("Name is required")
             setborderrorname(true)
          }
          else{
             setvalidationnamemsg("")
             setborderrorname(false)
          }
          if (contactformdata.email.length==0){
            setvalidationemailmsg("Email is required")
            setbordererroremail(true)
         }
         else if (contactformdata.email && !/\S+@\S+\.\S+/.test(contactformdata.email)) {
            setvalidationemailmsg('Email format is incorrect')
            setbordererroremail(true)
          }
          else{
            setvalidationemailmsg('')
            setbordererroremail(false)
          }
    
          if(contactformdata.phone.length==0){
            setvalidationphonenumber("Phone number is required")
            setbordererrorphone(true)
          }
          else if(contactformdata.phone.length<11){
            setvalidationphonenumber("Phone number needs to be 11 characters")
            setbordererrorphone(true)
          }
          else{
            setvalidationphonenumber("")
            setbordererrorphone(false)
          }
          if(contactformdata.subject.length==0){
            setvalidationsubject("Subject is required")
            setbordererrorsubject(true)
          }
          else{
            setvalidationsubject("")
            setbordererrorsubject(false)
          }
          if(contactformdata.message.length==0){
            setvalidationmessage("Message is required")
            setbordererrormessage(true)
          }
          else{
            setvalidationmessage("")
            setbordererrormessage(false)
          }
    
          if(validationnamemsg=="" && validationemailmsg=="" && validationphonenumber=="" && validationsubject=="" && validationmessage==""){
            console.log("Form Submitted")
          }
        
      }
  return (
    <>
        <div className="mobilecontaibner">
            <div className="heading">
                <h4>
                    Contact
                </h4>
            </div>
            <Row>
            {
                contactcardsdata.map((item,key)=>{
                    return (
                        <Col lg={12} key={key} style={{marginBottom:"10px",padding:"0 35px"}}>
                            <div className="contactdeskbox">
                                <div className="flexwrapper">
                                    <h4>{item.heading}</h4>
                                    {
                                        item.campus?
                                        <p>{item.campus}</p>
                                        :
                                        ""
                                    }
                                    <p>{item.address}</p>
                                    <p>TEL: {item.phone}</p>
                                    <p>{item.email}</p>
                                </div>
                            </div>
                        </Col>
                    )
                })
            }
            
        </Row>
        <div className="heading onlineinquiry">
                <h4>
                    Online Inquiry
                </h4>
            </div>
           <Form onSubmit={handleSubmit}>

            <Row>
                <Col lg={12} style={{padding:"0 35px"}}>
                    <div className="basicinputfield">
                    <div className="inputgap">
                        <input type="text" maxLength={50} style={bordererrorname? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} placeholder='Name' name="name"  onChange={handleInput}/>
                        <label>{validationnamemsg}</label>
                    </div>
                    <div className="inputgap">
                        <input type="text" style={bordererroremail? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}}  placeholder='Email' name="email"  onChange={handleInput}/>
                        <label>{validationemailmsg}</label>
                    </div>
                    <div className="inputgap">
                        <input style={bordererrorphone? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} type="text" onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                                }
                            }} maxLength={11} placeholder='Phone' name="phone"  onChange={handleInput}/>
                        <label>{validationphonenumber}</label>
                    </div>
                    <div className="inputgap">
                        <input type="text"  placeholder='Info' name="info" onChange={handleInput}/>

                    </div>
                    <div className="inputgap">
                        <input type="text" style={bordererrorsubject? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} placeholder='Subject' name="subject" onChange={handleInput}/>
                        <label>{validationsubject}</label>
                    </div>
                    </div>
                
                    <div className="basicinputfield contactpagerightsideform">
                    <div className="inputgap">
                    <textarea maxLength={500}  style={bordererrormessage? {borderColor:"rgb(237, 28, 36)"}:{borderColor:"rgba(0,0,0,0.2)"}} name="message" placeholder="Message" onChange={handleInput}></textarea>
                    <label>{validationmessage}</label>
                    </div>
                    <div className="inputgap">
                    <input type="text" placeholder='Captcha' name="captcha" onChange={handleInput}/>

                    </div>
                    </div>
                
                </Col>
            </Row>
            <CommonButton buttonTitle="Submit"/>
            </Form>
        </div>
    </>
  )
}

export default ContactPageComponent