import React from 'react'

const SearchBar = () => {
  return (
    <>
        <input type="text" />
    </>
  )
}

export default SearchBar