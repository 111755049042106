import React,{useState,useEffect} from 'react'
import { sidebarTApi } from '../../../../../utils/DashboardApi/TeacherDashboardApi'
import {NavLink,Link, useNavigate} from 'react-router-dom';
import { Accordion,Card,Row,Col,Navbar,Nav,Button } from 'react-bootstrap';
const TeaDashSidebarMB = () => {
    let navigate = useNavigate()
    const handlelogout=()=>{
        navigate("/")
    }

    const handleLi = (mylink)=>{
        navigate(mylink)
       
      }
    const [varforiconchange,setvarforiconchange ]=useState("")
const handleonclick=()=>{
  
  
  var mynavbarnav = document.getElementById("mynavbarnav");
  mynavbarnav.classList.toggle('show');
  mynavbarnav.classList.toggle('navbar-white');
}
    const handletoggler=()=>{
        var navtoggler = document.getElementById("nav-icon4");
        navtoggler.classList.toggle('open');
      }
      useEffect(()=>{
  
        var navbar2 = document.getElementById("navbarmini");
        
        var sticky = navbar2.offsetTop;
      
        
        
        function myFunction() {
          if (window.pageYOffset >= sticky) {
            navbar2.classList.add("sticky2");
          
            
            
            
          } else {
            navbar2.classList.remove("sticky2");
            
            
          }
         
          if (window.pageYOffset >= 30) {
            navbar2.classList.add("sticky2shadow");
           
            
          } else {
            navbar2.classList.remove("sticky2shadow");
           
            
          }
        }
        window.onscroll = function() {myFunction()};
        
       
      },[])
  return (
    <>
         <Navbar id="navbarmini" className="mynavbarmb studashnav" expand="lg">
            
            <div className="headerwrapper">
              <Row style={{width:"100%"}}>
                <Col xs={2}>
                    <div className='navbar-brand2'>
                      <Link to="/"><img src="./assets/images/logo/navbar_logo.png" alt="logo" /> </Link>
                                         
                </div>                
                </Col>
                <Col xs={9} style={{paddingRight:"0",paddingLeft:"0"}} className="togglermenuclass2">
                    <p>British Standard School</p> 
                </Col>

                <Col xs={1} className="togglermenuclass">
                <div className="navbar-toggler" onClick={handleonclick} id="navbar-toggler" aria-controls="basic-navbar-nav" >
                <div id="nav-icon4" onClick={handletoggler}>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                </div>
                </Col>
              </Row>
                
             <div className="menupartmb">
             <Nav className="mynavbarnav mynavbarnavdash" id="mynavbarnav">
                        <div className="profileimgandid">
                        <img style={{borderRadius:"50%",width:"50px",height:"50px"}} src="./assets/images/dashboards/teacherDashboard/profile_pic.png" alt="smallpropic.png" />
                        <div className="propicidpartright">
                            <h4>Farhana Ahmed</h4>
                            <p>ID 1705032108</p>
                        </div>
                    </div>
                    <div className="sidebarscrolldiv">
                        {
                        sidebarTApi.map((item,key)=>{
                            if(item.drpdwn.length!==0){
                                return(
                                  <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header><img src={item.imgurl} alt="" /> <span>{item.name}</span> </Accordion.Header>
                                    <Accordion.Body>
                                    <ul>
                                    { item.drpdwn.map((item2,key2)=>{
                                      return(
                                        <li onClick={()=>handleLi(item2.link)}> 
                                        <div className={window.location.pathname==item2.link?`wr active`:`wr`}>
                                          {item2.item}
                                          </div>
                                           </li>
                                      )
                                    })}
          
                                  </ul>
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                                )
          
                              }else{
                                return(
                                
                                    <Nav.Item key={key}>
                                    <NavLink to={item.link} className={window.location.pathname==item.link?`activei`:``}><img src={item.imgurl} alt="" /> {item.name}</NavLink>
                                    </Nav.Item>
                                )
                              }
                            
                        })
                    }
                        
                    
                    </div>

            <div className="logoutbuttondiv">
                <Button onClick={handlelogout}>Log Out</Button>
            </div>
                </Nav>
             </div>
                
               
                
                
            
            </div>
            </Navbar>
    </>
  )
}

export default TeaDashSidebarMB