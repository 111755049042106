import React from 'react'
import '../../sassFiles/sassPages/admindashboard/resultProcess.scss'
// import '../../sassFiles/sassPages/admindashboard/role.scss'
import '../../sassFiles/sassPages/admindashboard/rolemgmt.scss'
import RoleMgmtComp from '../../components/adminDashboard/desktop/RoleMgmtComp'

const RoleMgmtPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
        <div className="overlayfordrpdwn"></div>
            <RoleMgmtComp/>
        </div>
    </>
  )
}

export default RoleMgmtPage