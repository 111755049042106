export const resultReportApi = {
    heading:[
        {
            head:"SL"
        },
        {
            head:"Student Name"
        },
        {
            head:"Class"
        },
        {
            head:"Obtained Marks"
        },
        {
            head:"Pic"
        },
        {
            head:"Pis"
        },
        {
            head:"Status"
        }
    ],
    data:[
        {
            sl:"1",            
            name:"Arko Chatterjee",
            class:"Starter",
            obtainedmarks:"Diamond",
            pic:"",
            pis:"",
            status:""
        },
        {
            sl:"2",
            name:"Arko Chatterjee",
            class:"Starter",
            obtainedmarks:"Diamond",
            pic:"",
            pis:"",
            status:""
        },
        {
            sl:"3",
            name:"Arko Chatterjee",
            class:"Starter",
            obtainedmarks:"Diamond",
            pic:"",
            pis:"",
            status:""
        },
        {
            sl:"4",
            name:"Arko Chatterjee",
            class:"Starter",
            obtainedmarks:"Diamond",
            pic:"",
            pis:"",
            status:""
        },
        {
            sl:"5",
            name:"Arko Chatterjee",
            class:"Starter",
            obtainedmarks:"Diamond",
            pic:"",
            pis:"",
            status:""
        },
        {
            sl:"6",
            name:"Arko Chatterjee",
            class:"Starter",
            obtainedmarks:"Diamond",
            pic:"",
            pis:"",
            status:""
        },
        {
            sl:"7",
            name:"Arko Chatterjee",
            class:"Starter",
            obtainedmarks:"Diamond",
            pic:"",
            pis:"",
            status:""
        },
       
       
    ]
}