import React from 'react'
import '../../sassFiles/sassPages/admindashboard/resultProcess.scss'
import '../../sassFiles/sassPages/admindashboard/role.scss'
import RoleComp from '../../components/adminDashboard/desktop/RoleComp'

const RolePage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
        <div className="overlayfordrpdwn"></div>
            <RoleComp/>
        </div>
    </>
  )
}

export default RolePage