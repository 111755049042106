import React from 'react'
import TeaAttendanceComp from '../../../components/dashboardComponents/teacherDashboard/TeaAttendanceComp'
import TeaDashSidebarMB from '../../../components/mobile/dashbordMobile/TeacherDashboard/SubComponent/TeaDashSidebarMB'
import TeaAttdDashTab from '../../../components/mobile/dashbordMobile/TeacherDashboard/TeaAttdDashTab'
import FooterMobile from '../../../components/mobile/FooterMobile'

const TeaAttendancePage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
     <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">                
                    <TeaAttendanceComp/>
                    
                </div>
            </div>
        </div> 
        <div className="mobile">
        <div className="teacherdashboardmobilewrapper">
            <TeaDashSidebarMB/>
            <TeaAttdDashTab/>
            <FooterMobile/>
            

        </div>
        </div> 
     </>
  )
}

export default TeaAttendancePage