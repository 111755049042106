import React from 'react'
import FlexValueComp from '../../components/adminDashboard/desktop/FlexValueComp'
import '../../sassFiles/sassPages/admindashboard/admindashboardpage.scss'
import '../../sassFiles/sassPages/admindashboard/flexValue.scss'
import FlexValueSetComp from '../../components/adminDashboard/desktop/FlexValueSetComp'
const FlexValueSetAdashPage = () => {
  function setBodyColor({color}) {
    document.documentElement.style.setProperty('--bodyColor', color)
}

  setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
        <div className="overlayfordrpdwn"></div>
            <FlexValueSetComp/>
        </div>
    </>
  )
}

export default FlexValueSetAdashPage