import React,{useState} from 'react'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import {Row,Col} from 'react-bootstrap'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { optionssession,optionsClass,optionssemester } from '../../Helper'

const StuPositionProcessComp = () => {
    const [drpsectionselect,setdrpsection] = useState("")
    const [drpclassselect,setdrpclassselect] = useState("")
    const [drpsemesterselect,setdrpsemesterselect] = useState("")
    const drpfuncsession=(mysectionval)=>{
        setdrpsection(mysectionval)
    }
    const drpfunctionclass=(mysectionval)=>{
        setdrpclassselect(mysectionval)
    }
    const drpfunctionsemester=(mysectionval)=>{
        setdrpsemesterselect(mysectionval)
    }
   
    const handleProcess=()=>{
        // Process Code
    }
  return (
    <>
         <div className="d-flex ">

<SidebarOfAdash/>
<div className="contentpartwidthwrapper">
  <HeaderofAdash head="Student Position Process"/>
  <div className="contentofadmindash contentflexvalue">
     
     <Row>
        <Col lg={3}>
            <div className="dropdownwrapper">
                <Dropdown2 func={drpfuncsession} myplaceholder="Select Session" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionssession}/>
            </div>
        
        </Col>
        <Col lg={3}>
            <div className="dropdownwrapper">
                <Dropdown2 func={drpfunctionclass} myplaceholder="Select Class" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionsClass}/>
            </div>
        
        </Col>
        <Col lg={3}>
            <div className="dropdownwrapper">
                <Dropdown2 func={drpfunctionsemester} myplaceholder="Select Semester" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionssemester}/>
            </div>
        
        </Col>
        <Col lg={3}>
            <div className="commonadminbutton">
                <button onClick={handleProcess}>Start Process</button>
            </div>
        </Col>
     </Row>

  </div>

</div>
</div>
    </>
  )
}

export default StuPositionProcessComp