import React from 'react'
import UpcomingPartSliderNnoticePage from './UpcomingPartSliderNnoticePage'
const UpcomingPartMb = () => {
  return (
    <>
        <div className="upcomingpartNoticePagemb">
            <UpcomingPartSliderNnoticePage subtitle="Up Coming"/>
        </div>
    </>
  )
}

export default UpcomingPartMb