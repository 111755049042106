import React,{useState,useEffect} from 'react'
import CommonButton from '../../subComponents/CommonButton'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import {Row,Col,Modal,Button} from 'react-bootstrap'
import {AiOutlinePlus} from 'react-icons/ai'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { flexValueTable } from '../../../utils/AdminDashApi/FlexValue'
import ReactPaginate from 'react-paginate';
const FlexValueComp = () => {

  const [flexvaluesetdrp,setflexvaluesetdrp] = useState("")
  const [activefrom,setactivefrom] = useState("")
  const [activeto,setactiveTo] = useState("")
 
  const [activefromnewFlexValue,setactivefromnewFlexValue] = useState("")
  const [activetonewFlexValue,setactiveTonewFlexValue] = useState("")



  const [show,setShow] = useState("")

  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage,setitemsPerPage] = useState(10);
  const [paginatenumselected,setpaginatenumselected] = useState(0)

  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);

  const handleClose = () => setShow2(false);
  const handleShow = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  const handleActiveTonewFlexValue = (activetoval)=>{

    if(activetoval != null){

        var dtmnt = activetoval.$M + 1;
        var dtfinalc = activetoval.$y + '-'+ dtmnt +'-'+ activetoval.$D;
        setactiveTonewFlexValue(dtfinalc)
    }
    
    
}
const handleActiveFromnewFlexValue = (activefromval)=>{

  if(activefromval != null){

      var dtmnt = activefromval.$M + 1;
      var dtfinalc = activefromval.$y + '-'+ dtmnt +'-'+ activefromval.$D;
      setactivefromnewFlexValue(dtfinalc)
  }
  
  
}

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(flexValueTable.data.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(flexValueTable.data.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % flexValueTable.data.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setpaginatenumselected(event.selected);
    setItemOffset(newOffset);
  };
  const optionsFlexValueSet = [
    {
      id:1,
      opt:"Religion"
    },
    {
      id:2,
      opt:"Gender"
    },
    {
      id:3,
      opt:"Leave Type"
    },
  ]
  const optionsShowt = [
    {
      id:1,
      opt:1
    },
    {
      id:2,
      opt:2
    },
    {
      id:3,
      opt:3
    },
  ]

  const drpfuncshow=(myshow)=>{
    setShow(myshow)
  }
  const drpfuncflexvalueset=(myflexval)=>{
    setflexvaluesetdrp(myflexval)
  }
  const handleActiveFrom = (activefromval)=>{

    if(activefromval != null){

        var dtmnt = activefromval.$M + 1;
        var dtfinalc = activefromval.$y + '-'+ dtmnt +'-'+ activefromval.$D;
        setactivefrom(dtfinalc)
    }
    
    
}
  const handleActiveTo = (activetoval)=>{

    if(activetoval != null){

        var dtmnt = activetoval.$M + 1;
        var dtfinalc = activetoval.$y + '-'+ dtmnt +'-'+ activetoval.$D;
        setactiveTo(dtfinalc)
    }
    
    
}
  return (
    <>
      <div className="d-flex flexvaluecomp">

       <SidebarOfAdash/>
       <div className="contentpartwidthwrapper">
          <HeaderofAdash head="Flex Value"/>
          <div className="contentofadmindash contentflexvalue">
              <Row>
                <Col lg={3}>
                  <div className="basicinput">
                    <p></p>
                  <button className='cmbutton' onClick={handleShow}> <AiOutlinePlus/> New Flex Value</button>
                  </div>
                </Col> 
                <Col lg={3}>
                <div className="basicinput">
                <p>Flex Value Set</p>
                <div className="dropdownwrapper">
                  
                    <Dropdown2 func={drpfuncflexvalueset} myplaceholder="Select Type" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsFlexValueSet}/>
                </div>
                </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Active from</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activefrom}
                          onChange={(newvaldob)=>handleActiveFrom(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active From"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Active To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activeto}
                          onChange={(newvaldob)=>handleActiveTo(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active To"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
              </Row>
              <Row style={{marginTop:"20px"}}>
                <Col className="m-auto" lg={4}>
                  <Row>
                    <Col lg={3}>
                    <div className="basicinput">
                      <p>Show</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                      </div>
                      </div>
                    </Col>
                    <Col lg={9}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" />
                              <img src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={10} className="m-auto">
                  <div className="flexvaluetable">
                    <table>
                      <thead>
                        <tr>
                            {
                              flexValueTable.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItems?

                          currentItems.map((item,key)=>{
                            return(
                              <tr>
                                <td>{item.flexvalueid}</td>
                                <td>{item.flexsetname}</td>
                                <td>{item.flexvaluename}</td>
                                <td>{item.flexshortname}</td>
                                <td>{item.activefrom}</td>
                                <td>{item.activeto}</td>
                                <td>
                                  <div className="img text-center">
                                    <img onClick={handleShow3} src={item.action} alt="" />  
                                  </div>  
                                </td>
                              </tr>
                            )
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">

                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffset+1} to {itemOffset+10} of {flexValueTable.data.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                </Col>
               
              </Row>

              <Modal dialogClassName="mymodal" show={show2} onHide={handleClose} backdrop="static"
              keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Flex Value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Flex Value Set ID</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncflexvalueset} myplaceholder="Select Type" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsFlexValueSet}/>
                      </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                    
                    </Col>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Flex Value</p>
                      <input type="text" />
                    </div>
                    </Col>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Flex Short Value</p>
                      <input type="text" />
                    </div>
                    </Col>
                    <Col lg={6}>
                  <div className="basicinput">
                    <p>Active from</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activefromnewFlexValue}
                          onChange={(newvaldob)=>handleActiveFromnewFlexValue(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active From"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="basicinput">
                    <p>Active To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activetonewFlexValue}
                          onChange={(newvaldob)=>handleActiveTonewFlexValue(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active To"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="m-auto" style={{borderTop:"0"}}>
                  <button variant="secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button variant="primary" onClick={handleClose}>
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
              <Modal dialogClassName="mymodal2" show={show3} onHide={handleClose3} backdrop="static"
              keyboard={false}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Flex Value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Flex Value Set ID</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncflexvalueset} myplaceholder="Select Type" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsFlexValueSet}/>
                      </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                    
                    </Col>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Flex Value</p>
                      <input type="text" />
                    </div>
                    </Col>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Flex Short Value</p>
                      <input type="text" />
                    </div>
                    </Col>
                    <Col lg={6}>
                  <div className="basicinput">
                    <p>Active from</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activefromnewFlexValue}
                          onChange={(newvaldob)=>handleActiveFromnewFlexValue(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active From"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="basicinput">
                    <p>Active To</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                          
                          value={activetonewFlexValue}
                          onChange={(newvaldob)=>handleActiveTonewFlexValue(newvaldob)}
                          renderInput={(params) => <TextField {...params}inputProps={{
                              ...params.inputProps,
                              placeholder: "Active To"
                            }} error={false} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="m-auto" style={{borderTop:"0"}}>
                  <button variant="secondary" onClick={handleClose3}>
                    Close
                  </button>
                  <button variant="primary" onClick={handleClose}>
                    Update
                  </button>
                </Modal.Footer>
              </Modal>
          </div>

       </div>
      </div>
    </>
  )
}

export default FlexValueComp