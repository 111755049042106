import React from 'react'
import AdminDashComp from '../../components/adminDashboard/desktop/AdminDashComp'
import '../../sassFiles/sassPages/admindashboard/admindashboardpage.scss'
const AdminDashboardHome = () => {
  function setBodyColor({color}) {
    document.documentElement.style.setProperty('--bodyColor', color)
}

  setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <AdminDashComp/>
        </div>
    </>
  )
}

export default AdminDashboardHome