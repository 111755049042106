import React,{useState,useEffect} from 'react'
import SmallHeadingMsg from '../components/subComponents/SmallHeadingMsg';
import NavbarMoblie from '../components/mobile/NavbarMoblie'
import FooterMobile from '../components/mobile/FooterMobile'
import Header from '../components/Header'
import Mynavbar from '../components/Navbar'
import Footer from '../components/Footer'
import HeadlineOfOtherPage from '../components/subComponents/HeadlineOfOtherPage'
import '../sassFiles/sassPages/trackAdmissionPage.scss'
import TrackAmissionComp from '../components/TrackAmissionComp';
import TrackAdmissioncompmb from '../components/mobile/TrackAdmissioncompmb';
import { Link } from 'react-router-dom';
const SubmitSuccessPage = () => {
  function setBodyColor({color}) {
    document.documentElement.style.setProperty('--bodyColor', color)
}

  setBodyColor({color: "#fff"})
  useEffect(()=>{
    
    var navbar2 = document.getElementById("navbarmini");
    var navbar = document.getElementById("mynavbar");
    
    
    
    var sticky = navbar2.offsetTop;
  
    
    
    function myFunction() {
      if (window.pageYOffset >= sticky) {
        navbar2.classList.add("sticky2");
        
        
        
        
      } else {
        navbar2.classList.remove("sticky2");
        
        
      }
      if(window.pageYOffset > 99){
        navbar.classList.add("sticky");
      }else{
        navbar.classList.remove("sticky");
      }
    
      if (window.pageYOffset >= 30) {
        navbar2.classList.add("sticky2shadow");
       
        
      } else {
        navbar2.classList.remove("sticky2shadow");
       
        
      }
    }
    window.onscroll = function() {myFunction()};
    
   
  },[])
    
  return (
    <>
        <div className="trackadmissionpagemain successpage">
            <div className="mobile">
                <NavbarMoblie/>
                <div className="maincontent">
                    <HeadlineOfOtherPage noticepagegapbalance="noticepagegapbalance" headline="Submission Successful!"/>
                    <div className="content" data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
                        <div className="myContainer">
                            <h3>Your Form was submitted Successfully</h3>
                            <p>to track your admission click <Link to="/trackadmission">this link</Link></p>
                        </div>
                    </div>

                    </div>
                <FooterMobile/>
                </div>
                <div className="desktop">                  
                    <Header/>                
                    <Mynavbar/>
                    <div className="maincontent">
                    <HeadlineOfOtherPage noticepagegapbalance="noticepagegapbalance" headline="Submission Successful!"/>
                    <div className="content" data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
                        <div className="myContainer">
                            <h3>Your Form was submitted Successfully</h3>
                            <p>to track your admission click <Link to="/trackadmission">this link</Link></p>
                        </div>
                    </div>

                    </div>
                </div>
                             
                </div>
            
    </>
  )
}

export default SubmitSuccessPage