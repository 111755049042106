import React from 'react'
import SidebarOfsDash from './subComponents/SidebarOfsDash'
import HeaderOfstuDashComp from './subComponents/HeaderOfstuDashComp'
import { headerprops, routinetabData } from '../../../utils/DashboardApi/StudentDashboardApi'
import { Button } from 'react-bootstrap'
const StuDashERoutineComp = () => {
    const handleDocumentDownloadroutine = (file)=>{

    }
    const handleExamRoutineDownload=()=>{

    }
  return (
    <>
        <div className="stu_dash_mainWrap">
        <SidebarOfsDash activeID="2"/>
        <div className="rightpartcontentwrap">
            <HeaderOfstuDashComp heading="Exam Routine" myprops={headerprops}/>
            <div className="tabPane">
            <div className="tabpaneheightadjust examRoutineTab">
                    <div className="examroutinetable">
                        <table>
                        <thead>
                                    <tr>
                                        {
                                            routinetabData.examRoutine.heading.map((item,key)=>{
                                                return(
                                                    <th className='text-center' key={key}>
                                                        {item.head}
                                                    </th>
                                                )
                                            })
                                        } 
                                    </tr>
                                </thead>
                                <tbody>
                                  
                                    <tr>
                                    <td>
                                        <table>
                                            {
                                                routinetabData.examRoutine.tableData.map((item,key)=>{
                                                    return(
                                                        <tr key={key}>
                                                            <td>{item.date}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            {
                                                routinetabData.examRoutine.tableData.map((item,key)=>{
                                                    return(
                                                        <tr key={key}>
                                                            <td>{item.day}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            {
                                                routinetabData.examRoutine.tableData.map((item,key)=>{
                                                    return(
                                                        <tr key={key}>
                                                            <td>{item.starttime}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            {
                                                routinetabData.examRoutine.tableData.map((item,key)=>{
                                                    return(
                                                        <tr key={key}>
                                                            <td>{item.endtime}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </td>
                                    <td>
                                        <table>
                                            {
                                                routinetabData.examRoutine.tableData.map((item,key)=>{
                                                    return(
                                                        <tr key={key}>
                                                            <td>{item.subject}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </table>
                                    </td>

                                    <td rowSpan={6} className='downbtncell'>
                                    <Button onClick={()=>handleDocumentDownloadroutine(routinetabData.examRoutine.downloadlink)}>
                                        <img src="./assets/images/dashboards/studentDashboard/routineTab/download.png" alt="" />
                                    </Button>
                                    </td>
                                    </tr>
                                
                                </tbody>
                        </table>
                    </div>
                        <div className="bottombuttondiv">
                            <Button onClick={handleExamRoutineDownload}><img style={{marginRight:"0px"}} src="./assets/images/dashboards/studentDashboard/routineTab/downloadcoloredbutton.png" alt="" /> Download Routine</Button>
                        </div>
                </div>
       
            </div>
        </div>
    </div>
    </>
  )
}

export default StuDashERoutineComp