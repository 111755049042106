import React, { useEffect } from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { setVariable,setObject } from '../../redux/Action/Action';
import { importfucntion } from '../../redux/directFunctionImport/myimport';
const ReduxTest = () => {
  const dispatch = useDispatch();
  const noOfThings = useSelector(state => state.variable)

  const myObj = useSelector(state => state.obj)
  function handleClick() {
    dispatch(setVariable());
    var obj=[1,2,3]
    dispatch(setObject(obj))
    importfucntion()
  }

  return (
    <>
      <div className="Redux">
        <h1>{noOfThings}</h1>
        {/* <h1>{JSON.stringify(myObj)}</h1> */}

        {myObj && myObj.map((item, key) => {
          return (
            <h1>{JSON.stringify(item.Present)}</h1>
          )
        })}
        <h2>hello</h2>
        <button onClick={handleClick}>Set Variable</button>
      </div>
    </>
  )
}

export default ReduxTest