import React, { useEffect, useRef, useState } from 'react'
import CommonButton from './subComponents/CommonButton'
import { Row, Col, Form } from 'react-bootstrap'
import SmallHeadingMsg from './subComponents/SmallHeadingMsg'
import { howmuchtopayApi, trackAdmission } from '../utils/TrackAdmissionApi'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
const TrackAmissionComp = () => {
  const navigate = useNavigate()
  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [unpdmsgstate, setunpdmsgstate] = useState("")
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [admissionId, setadmissionId] = useState("");
  const [merchant_trans_id, setmerchant_trans_id] = useState("");
  const [store_id, setstore_id] = useState("");
  const [store_passwd, setstore_passwd] = useState("");
  const [studentName, setstudentName] = useState("");
  const [sessionName, setsessionName] = useState("");
  const [className, setclassName] = useState("");
  const [applyDate, setapplyDate] = useState("");
  const [studentImg, setstudentImg] = useState("");
  const [applicationStatus, setapplicationStatus] = useState("");
  const [aplicationDeclineReason, setaplicationDeclineReason] = useState("");
  const [paymentStatus, setpaymentStatus] = useState("");
  const [TrackAdmissionresponse, setTrackAdmissionresponse] = useState([]);
  const [month, setmonth] = useState("");
  const [receiveType, setreceiveType] = useState("");
  const [billAmount, setbillAmount] = useState("");
  const [total, settotal] = useState("");
  const [PaymentAmountresponse, setPaymentAmountresponse] = useState([]);
  const [MakePaymentresponse, setMakePaymentresponse] = useState([]);


  const [tickme, settickme] = useState("")
  

  const handleUnpaidPayment = async () => {

    setunpdmsgstate("Your online admission application isn't approved yet. Please get in touch for approval & then you can make payment.");
    document.getElementById("unpaidmsg").style.display = "block"
    setTimeout(() => {
      setunpdmsgstate('');
      document.getElementById("unpaidmsg").style.display = "none"
    }, 3000);

  }
  const handleDownloadToken = () => {

  }
  const handleChange = event => {
    if (event.target.checked) {
      console.log('✅ Checkbox is checked');
    } else {
      console.log('⛔️ Checkbox is NOT checked');
    }
    setIsSubscribed(current => !current);
  };

  async function TrackAdmissionInfo(mydata) {
    try {
      axios.get(`https://sass.britishstandardschool.com/api/Public/TrackAdmission?TrackId=${mydata.trackid}`, headers)
        .then(function (response) {
          console.log(response)
          setTrackAdmissionresponse(response.data);
        })
    } catch (err) {
      if (err) {
        console.error(err);
      }
    }
    // try {
    //   axios.get(`https://sass.britishstandardschool.com/api/Public/TrackAdmission?TrackId=${mydata.trackid}`, headers)
    //     .then(function (response) {
    //       console.log(response)
    //       setTrackAdmissionresponse(response.data);
    //     })
    // } catch (err) {
    //   if (err) {
    //     console.error(err);
    //   }
    // }
  }
  async function PaymentInfo(admId) {
    try {
      axios.get(`https://sass.britishstandardschool.com/api/OnlineAdmissionPayment/AdmissionPaymentAmount?AdmissionId=${admId.trackid}`, headers)
        .then(function (response) {
          console.log(response);
          setPaymentAmountresponse(response.data);

        });
    } catch (err) {
      if (err) {
        console.error(err);
      }
    }
  }
  async function MakePayment(admdata) {
    try {
      const { protocol, host } = window.location;
      const baseUrl = (`${protocol}//${host}`);
      console.log(baseUrl);
      axios.get(`https://sass.britishstandardschool.com/api/OnlineAdmissionPayment/AdmissionPayment?AdmissionId=${admdata.trackid}&baseUrl=${baseUrl}`, headers)
        .then(function (response) {
          console.log(response);
          setMakePaymentresponse(response.data);
          if (response.status == 200) {
            window.location.href = response.data
          }

        });
    } catch (err) {
      if (err) {
        console.error(err);
      }
    }
  }
  
  const [searchkeyword, setsearchkeyword] = useState({
    trackid: ""
    
  });
  const [showdata, setshowdata] = useState(false);
  
  const handletrackadmissionsearch = async (e) => {
    e.preventDefault()
    // console.log(searchkeyword.trackid)
    await TrackAdmissionInfo(searchkeyword);
    await PaymentInfo(searchkeyword);
    console.log(TrackAdmissionresponse);
    console.log(PaymentAmountresponse);
  }

 
  
  
  //   try {
  //     const response = await axios.post('https://sandbox.sslcommerz.com/validator/api/merchantTransIDvalidationAPI.php', {
  //       merchant_trans_id:'BSS2951kdUM-20235-248-ADM',
  //       store_id: 'britishstandardschoollive',
  //       store_passwd: '5EA53E04F2BC925282',
  //       format: 'json',
  //       // Add the required parameters here
  //       // merchant_trans_id: 'YOUR_TRANSACTION_ID',
  //       // store_id: 'YOUR_STORE_ID',
  //       // store_passwd: 'YOUR_STORE_PASSWORD',
  //     });
  //     // Handle the response data here
  //     const transactionStatus = response.data.status;
  //     if (transactionStatus === 'VALID') {
  //       // The transaction was successful
  //       console.log('Transaction successful');
  //     } else if (transactionStatus === 'FAILED') {
  //       // The transaction has failed
  //       console.log('Transaction failed');
  //     } else if (transactionStatus === 'CANCELLED'  || response.data.GWRedirectURL.includes('canceled')) {
  //       // The transaction was canceled
  //       navigate("/TrackAdmissionPage");
  //       console.log('Transaction canceled');
  //     } else {
  //       // Handle other status values if needed
  //       console.log('Unknown transaction status');
  //     }
  //     console.log(response.data);
  //   } catch (error) {
  //     // Handle errors here
  //     console.error(error);
  //   }
  // };
  
  // const TransactionStatus = async () => {
  //   const [status, setStatus] = useState('');
  //   const [tranId, setTranId] = useState('');
  //   const TransactionIdGet = () => {
    
  //     useEffect(() => {
  //       // Function to extract tran_id from the URL
  //       const getTranIdFromURL = () => {
  //         const urlParams = new URLSearchParams(window.location.search);
  //         console.log(urlParams);
  //         const tranIdParam = urlParams.get('tran_id');
  //         console.log(tranIdParam);
  //         setTranId(tranIdParam);
  //       };
    
  //       // Call the function to get tran_id
  //       getTranIdFromURL();
  //     }, []);
  //   };
  //   useEffect(() => {
  //     // Function to fetch the transaction status
  //     const fetchTransactionStatus = async () => {
  //       try {
  //         const response = await axios.post(
  //           'https://sandbox.sslcommerz.com/validator/api/merchantTransIDvalidationAPI.php',
  //           {
  //             merchant_trans_id: tranId,
  //             store_id: 'bssit5dc02f6fcc2e7',
  //             store_passwd: 'bssit5dc02f6fcc2e7@ssl',
  //             format: 'json',
  //           }
  //         );
  //         if (status === "VALID") {
  //           // The transaction was successful
  //           console.log("Transaction successful");
  //         } else if (status === "FAILED") {
  //           // The transaction has failed
  //           console.log("Transaction failed");
  //         } else if (
  //           status === "CANCELLED" ||
  //           response.data.GWRedirectURL.includes("canceled")
  //         ) {
  //           // The transaction was canceled
  //           navigate("/trackadmission/canceled");
  //           console.log("Transaction canceled");
  //         } else {
  //           // Handle other status values if needed
  //           console.log("Unknown transaction status");
  //         }
  //         // Assuming the API response contains a 'status' field
  //         setStatus(response.data.status);
          
  //       } catch (error) {
  //         console.error('Error fetching transaction status:', error);
  //       }
  //     };
  
  //     // Call the function to fetch the transaction status
  //     fetchTransactionStatus();
  //   }, []);
  
  //   return (
  //     <div>
  //       <h1>Transaction Status: {status}</h1>
  //     </div>
  //   );
  // };
  
  const handleMakePayment = async() => {
    if (isSubscribed) {
      await MakePayment(searchkeyword);
      //await TransactionStatus();
    }
    else {
      console.log("tick the checkbox")
      settickme("tick the checkbox")
    }
  }
  

  


  const handleInput = (e) => {
    setsearchkeyword({ ...searchkeyword, [e.target.name]: e.target.value })
  }

  return (
    <>
      <div className="basicinfopopulate">
        <div
          className="searchandsearchbutton m-auto d-flex flex-column"
          data-aos-delay="300"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <p>Search Track Number</p>
          <Form onSubmit={(e) => handletrackadmissionsearch(e)}>
            <div className="flexwrapper d-flex">
              <input
                name="trackid"
                type="text"
                value={searchkeyword.trackid}
                onChange={handleInput}
              />
              <CommonButton buttonTitle="Search" />
            </div>
          </Form>
        </div>
        <div className="myContainer">
          {TrackAdmissionresponse
            ? TrackAdmissionresponse.map((item, key) => {
              return (
                <div
                  className="populateinfo"
                  data-aos-delay="300"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <div className="topbox">
                    <SmallHeadingMsg smalltitle="Tracking Information" />

                    <div className="wrapperwrap">
                      <div className="leftimg">
                        <img
                          className="w-100"
                          //   src="./assets/images/trackAdmissionPage/idpic.png"
                          // src={`${item.studentImg}`}
                          src={
                            "./assets/images/ApplyOnlineImage/" +
                            item.studentImg
                          }
                          alt=""
                        />
                      </div>
                      <div className="rightcontent">
                        <div className="item">
                          <div className="left">
                            <div className="flexwrap">
                              <h5>Tracking No</h5>
                              <h5>:</h5>
                            </div>
                          </div>
                          <div className="right">
                            <h5>{item.admissionId}</h5>
                          </div>
                        </div>
                        <div className="item">
                          <div className="left">
                            <div className="flexwrap">
                              <h5>Name</h5>
                              <h5>:</h5>
                            </div>
                          </div>
                          <div className="right">
                            <h5>{item.studentName}</h5>
                          </div>
                        </div>
                        <div className="item">
                          <div className="left">
                            <div className="flexwrap">
                              <h5>Session</h5>
                              <h5>:</h5>
                            </div>
                          </div>
                          <div className="right">
                            <h5>{item.sessionName}</h5>
                          </div>
                        </div>
                        <div className="item">
                          <div className="left">
                            <div className="flexwrap">
                              <h5>Class</h5>
                              <h5>:</h5>
                            </div>
                          </div>
                          <div className="right">
                            <h5>{item.className}</h5>
                          </div>
                        </div>
                        <div className="item">
                          <div className="left">
                            <div className="flexwrap">
                              <h5>Apply Date</h5>
                              <h5>:</h5>
                            </div>
                          </div>
                          <div className="right">
                            <h5>{item.applyDate}</h5>
                          </div>
                        </div>
                        <div className="tokenbutton">
                          <button onClick={handleDownloadToken}>
                            Download Token
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="bottomboxes">
                    <div className="bottomleft boxcommon">
                      <h4>Status</h4>
                      {item.applicationStatus.toLowerCase() == "pending" ? (
                        <>
                          <div className="pillsmall2">
                            <p>{item.applicationStatus}</p>
                          </div>
                          <div className="note">
                            <h4>
                              <span>Note: </span> Your Application is in the
                              queue for review. As soon as it's reviewed, you
                              will see the status updated
                            </h4>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="pillsmall3">
                            <p>{item.applicationStatus}</p>
                          </div>
                          <div className="note">
                            <h4>
                              <span>Note: </span> Congratulation, your
                              application for admission at BSS is approved.
                              Now you can pay for admision by clicking the
                              Make Payment Button
                            </h4>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="bottomright boxcommon">
                      <h4>Admission Payment</h4>
                      {item.paymentStatus.toLowerCase() == "unpaid" ? (
                        <>
                          <div className="pillsmall2">
                            <p>{item.paymentStatus}</p>
                          </div>
                        </>
                      ) : (
                        <div className="pillsmall3">
                          <p>{item.paymentStatus}</p>
                        </div>
                      )}
                      {item.applicationStatus.toLowerCase() == "pending" ? (
                        <>
                          <div className="tokenbutton">
                            <button onClick={handleUnpaidPayment}>
                              Make Payment
                            </button>
                            <div className="howmuchtopaymain">
                              <p id="unpaidmsg">{unpdmsgstate}</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="howmuchtopaymain">
                            <div className="top">
                              <ul>
                                {PaymentAmountresponse.slice(0, -1).map((item, key) => {
                                  return (
                                    <li>
                                      <div className="left">
                                        {item.receiveType}
                                        {item.month ? ` (${item.month})` : ""}
                                      </div>
                                      <div className="right">
                                        {item.billAmount}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                            <div className="bottom">
                              <ul>
                                {PaymentAmountresponse.slice(-1).map((item, key) => {
                                  return (
                                    <li>
                                      <div className="left">total</div>
                                      <div className="right">
                                        {item.total}
                                      </div>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>

                            <div className="checkboxfull">
                              <input
                                type="checkbox"
                                value={isSubscribed}
                                onChange={handleChange}
                                id="remember"
                                name="subscribe"
                              />
                              <span>
                                Click here to indicate that you have read and
                                agreed to the terms presented in the Terms and
                                Conditions agreement.
                              </span>
                              <span
                                style={{
                                  display: "block",
                                  color: "red",
                                  marginLeft: "0",
                                }}
                              >
                                {isSubscribed ? "" : tickme}
                              </span>
                            </div>
                          </div>
                          <div className="tokenbutton">
                            <button onClick={handleMakePayment}>
                              Make Payment
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })
            : ""}
        </div>
      </div>
    </>
  );
}

export default TrackAmissionComp