import React from 'react'

const trackAdmission = {
        id:"BSS4491xVrm",
        name:"Nayem",
        session:"July 2021-June 2022",
        class:"O Level",
        applydate:"23 May, 2022",
        token:{},
        img:"",
        status:"approved",
        admissionpayment:"unpaid"
    }

  const howmuchtopayApi = {
    heading:[
      {
        head:"Admission Fee",
        fee:6000,
        
      },
      {
        head:"Session Fee",
        fee:12000,
        
      },
      {
        head:"Billing Charge",
        fee:300,
        
      },
      {
        head:"Tuition Fee",
        fee:10000,
        month:"July"
      },
    ],
    total:28300


  }    


const TrackAdmissionApi = () => {
  return (
    <>
    
    </>
  )
}

export {trackAdmission,howmuchtopayApi}

export default TrackAdmissionApi