import React from 'react'
import ResultProcessComp from '../../components/adminDashboard/desktop/ResultProcessComp'
import '../../sassFiles/sassPages/admindashboard/resultProcess.scss'
const ResultProcessPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
        <div className="overlayfordrpdwn"></div>
            <ResultProcessComp/>
        </div>
    </>
  )
}

export default ResultProcessPage