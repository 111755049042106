import React, { useState, useEffect } from 'react'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import { Row, Col, Modal, Button, Form, Nav, Tab } from 'react-bootstrap'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { manageonlinetable } from '../../../utils/AdminDashApi/ManageOnlineAd'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import { attachmentfilesapi } from '../../../utils/AdminDashApi/AttachmentFiles'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { GrAttachment } from 'react-icons/gr'
import { RiCheckboxMultipleLine } from 'react-icons/ri'
const ManageOnlineAdmissionComp = () => {
  const navigate = useNavigate()

  const [currentItems, setCurrentItems] = useState(null);
  const [declshow, setdeclshow] = useState(false)
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [paginatenumselected, setpaginatenumselected] = useState(0)
  const [show, setShow] = useState("")



  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(manageonlinetable.datatable.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(manageonlinetable.datatable.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = event.selected * itemsPerPage % manageonlinetable.datatable.length;
    console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
    setpaginatenumselected(event.selected);
    setItemOffset(newOffset);
  };
  const drpfuncshow = (myshow) => {
    setitemsPerPage(myshow)
  }
  const optionsShowt = [
    {
      id: 1,
      opt: 10
    },
    {
      id: 2,
      opt: 20
    },
    {
      id: 3,
      opt: 30
    },
  ]
  const [drptoggler, setdrptoggler] = useState(false)
  const [drpshow, setdrpshow] = useState(null)

  function handleaction(myid, mytoggler) {
    if (mytoggler == false) {
      setdrptoggler(true)
      setdrpshow(myid)
    } else {
      setdrptoggler(false)
      setdrpshow(null)
    }



  }
  moment().format();
  const handleDateofbirth = (newvaldob) => {


    console.log(newvaldob)
    // setdateofbirthnew(moment(newvaldob).format("YYYY-MM-DD"))
    if (newvaldob != null) {

      var dtofbrth = newvaldob.$M + 1;
      var dtdobfinalc = newvaldob.$y + '-' + dtofbrth + '-' + newvaldob.$D;
      setdateofbirthnew(moment(dtdobfinalc).format("YYYY-MM-DD"))
      // setdateofbirthnew(newvaldob)
    }
    console.log(dateofbirthnew);


  }



  const [searchkeyword, setsearchkeyword] = useState("")
  const [firstnamelabel, setfirstnamelabel] = useState("")
  const [middlenamelabel, setmiddlenamelabel] = useState("")
  const [labelbirthcert, setlabelbirthcert] = useState("")
  const [labeldob, setlabeldob] = useState("")
  const [labelmobileno, setlabelmobileno] = useState("")
  const [labelgender, setlabelgender] = useState("")
  const [labelemail, setlabelemail] = useState("")
  const [labeladDate, setlabeladDate] = useState("")
  const [labelclass, setlabelclass] = useState("")
  const [labelsession, setlabelsession] = useState("")
  const [fatherfirstnamelabel, setfatherfirstnamelabel] = useState("")
  const [fathermiddlenamelabel, setfathermiddlenamelabel] = useState("")
  const [fathernidlabel, setfathernidlabel] = useState("")
  const [mothernidlabel, setmothernidlabel] = useState("")
  const [motherfirstnamelabel, setmotherfirstnamelabel] = useState("")
  const [mothermiddlenamelabel, setmothermiddlenamelabel] = useState("")
  const [fnidlabel, setfnidlabel] = useState("")
  const [mnidlabel, setmnidlabel] = useState("")
  const [declineerrlabel, setdeclineerrlabel] = useState("")

  const [classoptions, setclassoptions] = useState("")
  const [drpsessionselect, setdrpsession] = useState("")

  const [bordererrordecl, setbordererrordecl] = useState("")
  const [bordererrorfirstname, setbordererrorfirstname] = useState(false)
  const [bordererrormiddlename, setbordererrormiddlename] = useState(false)
  const [bordererrorlabelbirthcert, setbordererrorlabelbirthcert] = useState(false)
  const [bordererrormobileno, setbordererrormobileno] = useState(false)
  const [bordererroeemail, setbordererroeemail] = useState(false)
  const [bordergender, setbordergender] = useState(false)
  const [borderclass, setborderclass] = useState(false)
  const [bordersection, setbordersection] = useState(false)
  const [bordererrorfatherfirstname, setbordererrorfatherfirstname] = useState(false)
  const [bordererrorfathermiddlename, setbordererrorfathermiddlename] = useState(false)
  const [bordererrorfathernid, setbordererrorfathernid] = useState(false)
  const [bordererrormothernid, setbordererrormothernid] = useState(false)
  const [bordererrormotherfirstname, setbordererrormotherfirstname] = useState(false)
  const [bordererrormothermiddlename, setbordererrormothermiddlename] = useState(false)
  const [bordererrorfnid, setbordererrorfnid] = useState(false)
  const [bordererrormnid, setbordererrormnid] = useState(false)
  const [dateofbirthnew, setdateofbirthnew] = useState("")
  const [drpsectionselectgender, setdrpsectiongender] = useState("")
  const [showReviewApply, setshowReviewApply] = useState(false)

  const handleSearchkeyword = (e) => {
    setsearchkeyword(e.target.value)
  }

  const drpfunctiongender = (mysectionval, genderid) => {
    setdrpsectiongender(genderid)
  }

  const drpfuncClass = (myclassval, classid) => {
    setclassoptions(classid)
  }
  const drpfunctionsession = (mysectionval, sessionid) => {

    setdrpsession(sessionid)
  }
  const handleClosereviewapply = (e) => {
    setshowReviewApply(false);
  }


  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [admissionId, setadmissionId] = useState("");
  const [studentName, setstudentName] = useState("");
  const [firsT_NAME, setfirsT_NAME] = useState("");
  const [middlE_NAME, setmiddlE_NAME] = useState("");
  const [lasT_NAME, setlasT_NAME] = useState("");
  const [nicK_NAME, setnicK_NAME] = useState("");
  const [admissioN_SESSION_ID, setadmissioN_SESSION_ID] = useState("");
  const [admissioN_SESSION, setadmissioN_SESSION] = useState("");
  const [sessionName, setsessionName] = useState("");
  const [admissioN_CLASS_ID, setadmissioN_CLASS_ID] = useState("");
  const [admissioN_CLASS, setadmissioN_CLASS] = useState("");
  const [className, setclassName] = useState("");
  const [dob, setdob] = useState("");
  const [gendeR_ID, setgendeR_ID] = useState("");
  const [gender, setgender] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [birthCertificate, setbirthCertificate] = useState("");
  const [applyDate, setapplyDate] = useState("");
  const [status, setstatus] = useState("");
  const [paymentStatus, setpaymentStatus] = useState("");
  const [fatheR_FIRST_NAME, setfatheR_FIRST_NAME] = useState("");
  const [fatheR_MIDDLE_NAME, setfatheR_MIDDLE_NAME] = useState("");
  const [fatheR_LAST_NAME, setfatheR_LAST_NAME] = useState("");
  const [fatheR_NATIONAL_ID, setfatheR_NATIONAL_ID] = useState("");
  const [motheR_FIRST_NAME, setmotheR_FIRST_NAME] = useState("");
  const [motheR_MIDDLE_NAME, setmotheR_MIDDLE_NAME] = useState("");
  const [motheR_LAST_NAME, setmotheR_LAST_NAME] = useState("");
  const [motheR_NATIONAL_ID, setmotheR_NATIONAL_ID] = useState("");
  const [stuImg, setstuImg] = useState("");
  const [transcriptImg, settranscriptImg] = useState("");
  const [certificateImg, setcertificateImg] = useState("");
  const [fatherNidImg, setfatherNidImg] = useState("");
  const [motherNidImg, setmotherNidImg] = useState("");
  const [multiReviewReason, setmultiReviewReason] = useState("");
  const [ManageOnlineAdmissionresponse, setManageOnlineAdmissionresponse] = useState([]);
  const [OnlineAdmissionReviewrespone2, setOnlineAdmissionReviewrespone] = useState([]);
  const [genderresponse, setgenderrsponse] = useState([]);
  const [classresponse, setclassresponse] = useState([]);
  const [sessionresponse, setsessionrsponse] = useState([]);

  const [formdata, setformdata] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    nickName: "",
    birthCertificateNo: "",
    mobileNo: "",
    email: "",
    fatherFirstName: "",
    fatherMiddleName: "",
    fatherLastName: "",
    fatherNIDNo: "",
    motherFirstName: "",
    motherMiddleName: "",
    motherLastName: "",
    motherNIDNo: "",
    declReason: ""
  })
  //genderdropdownload
  useEffect(() => {
    const fetchGenderDropdown = async () => {
      try {
        const genderresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/GenderDropDown",
          {},
          headers
        );
        setgenderrsponse(genderresponse.data);
        console.log(genderresponse);
      } catch (err) {
        if (err.genderresponse) {
          console.error(err.genderresponse.data);
          console.log(err.genderresponse.status);
          console.log(err.genderresponse.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchGenderDropdown();
  }, []);

  //classdropdownload
  useEffect(() => {
    const fetchClassDropdown = async () => {
      try {
        const classresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/ClassList",
          {},
          headers
        );
        setclassresponse(classresponse.data);
        console.log(classresponse);
      } catch (err) {
        if (err.classresponse) {
          console.error(err.classresponse.data);
          console.log(err.classresponse.status);
          console.log(err.classresponse.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchClassDropdown();
  }, []);

  //sessiondropdownload
  useEffect(() => {
    const fetchSessionDropdown = async () => {
      try {
        const sessionresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/SessionDropDown",
          {},
          headers
        );
        setsessionrsponse(sessionresponse.data);
        console.log(sessionresponse);
      } catch (err) {
        if (err.sessionresponse) {
          console.error(err.sessionresponse.data);
          console.log(err.sessionresponse.status);
          console.log(err.sessionresponse.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchSessionDropdown();
  }, []);

  useEffect(() => {

    const OnlineAdmissionList = async () => {
      try {
        const ManageOnlineAdmissionresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Admin/AdmissionList", {},
          headers
        );
        //sass.britishstandardschool.com
        setManageOnlineAdmissionresponse(ManageOnlineAdmissionresponse.data);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };

    OnlineAdmissionList();
    console.log(ManageOnlineAdmissionresponse)
    //load review apply from with dynamic data
    if (OnlineAdmissionReviewrespone2.data) {
      setformdata({
        firstName: OnlineAdmissionReviewrespone2.data[0].firsT_NAME,
        middleName: OnlineAdmissionReviewrespone2.data[0].middlE_NAME,
        lastName: OnlineAdmissionReviewrespone2.data[0].lasT_NAME,
        nickName: OnlineAdmissionReviewrespone2.data[0].nicK_NAME,
        birthCertificateNo: OnlineAdmissionReviewrespone2.data[0].birthCertificate,
        fatherFirstName: OnlineAdmissionReviewrespone2.data[0].fatheR_FIRST_NAME,
        fatherMiddleName: OnlineAdmissionReviewrespone2.data[0].fatheR_MIDDLE_NAME,
        fatherLastName: OnlineAdmissionReviewrespone2.data[0].fatheR_LAST_NAME,
        fatherNIDNo: OnlineAdmissionReviewrespone2.data[0].fatheR_NATIONAL_ID,
        motherFirstName: OnlineAdmissionReviewrespone2.data[0].motheR_FIRST_NAME,
        motherMiddleName: OnlineAdmissionReviewrespone2.data[0].motheR_MIDDLE_NAME,
        motherLastName: OnlineAdmissionReviewrespone2.data[0].motheR_LAST_NAME,
        motherNIDNo: OnlineAdmissionReviewrespone2.data[0].motheR_NATIONAL_ID,
        mobileNo: OnlineAdmissionReviewrespone2.data[0].mobile,
        email: OnlineAdmissionReviewrespone2.data[0].email,
        dob: OnlineAdmissionReviewrespone2.data[0].dob
      })
    }
    ///setdrpsession(OnlineAdmissionReviewrespone2.data?OnlineAdmissionReviewrespone2.data[0].admissioN_SESSION:"")
  }, [OnlineAdmissionReviewrespone2.data]);

  function func(myval) {
    setOnlineAdmissionReviewrespone(myval)
  }
  async function OnlineAdmissinReview(admId) {
    try {
      axios.get(
        "https://sass.britishstandardschool.com/api/Admin/ReviewOnlineAdmission" + admId,
        {},
        headers
      ).then(function (response) {
        console.log(response);
        func(response)
        console.log(OnlineAdmissionReviewrespone2);
      });
    } catch (err) {
      if (err.response) {
        console.error(err.response.data);
        console.log(err.response.status);
        console.log(err.response.header);
      } else {
        console.error(`Error: ${err.message}`);
      }
    }
  }
  const handleShowreviewapply = async (myid) => {
    await OnlineAdmissinReview(myid);
    setshowReviewApply(true);
  }
  const handleCloseAtt = () => {
    setshowAtt(false)
  }
  const handleShowAtt = async (myid) => {
    await OnlineAdmissinReview(myid);
    console.log(myid)
    setshowAtt(true)
  }


  const handleDeclinenew = () => {
    setdeclshow(true)
  }
  const [apprid, setapprid] = useState("")
  const [showAtt, setshowAtt] = useState(false)

  const [showApprv, setshowApprv] = useState(false)

  const handleOpenApprv = (myid) => {
    setapprid(myid)

    console.log(myid)
    setshowApprv(true)
  }

  const handleApprove = async () => {
    setshowApprv(true)
    console.log(apprid)
    // Approval code
    await SaveAdmissionStatus(apprid, "Approved");
    setshowApprv(false)
  }
  const handleDeclineSubmit = async (e) => {
    e.preventDefault()
    if (formdata.declReason.length == 0) {
      setdeclineerrlabel("Reason is required")
      setbordererrordecl(true)
    }
    else if (formdata.declReason.length < 21) {
      setdeclineerrlabel("Reason should be greater than 20 characters")
      setbordererrordecl(true)
    }
    else {
      setdeclineerrlabel("")
      setbordererrordecl(false)
      setshowDecl(false)

    }
    if (declineerrlabel == "") {
      console.log(apprid)
      await SaveAdmissionStatus(apprid, "Declined");
      console.log("decline done")
    }
    console.log(formdata.declReason)
  }
  async function SaveAdmissionStatus(admId, admissionStatus) {
    try {
      axios.post(
        "https://sass.britishstandardschool.com/api/Admin/SaveOnlineAdmissionStatus", {
        admissionId: admId,
        status: admissionStatus,
        declineReason: formdata.declReason
      },
        headers
      ).then(function (response) {
        console.log(response);
        func(response)
        if (response.status == 200 && response.data.value == 1) {
          navigate(0);
          console.log("done")
        }
        else {
          navigate(0)
        }
        console.log(OnlineAdmissionReviewrespone2);
      });
    } catch (err) {
      if (err.response) {
        console.error(err.response.data);
        console.log(err.response.status);
        console.log(err.response.header);
      } else {
        console.error(`Error: ${err.message}`);
      }
    }
  }
  async function SaveApplyOnlineReview(mydata) {
    try {
      axios.post('https://sass.britishstandardschool.com/api/Admin/SaveReviewApply', {
        admissionId: OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].admissionId : "",
        firstName: mydata.firstName,
        middleName: mydata.middleName,
        lastName: mydata.lastName,
        nickName: mydata.nickName,
        birthCertificateNo: mydata.birthCertificateNo,
        fatherFirstName: mydata.fatherFirstName,
        fatherMiddleName: mydata.fatherMiddleName,
        fatherLastName: mydata.fatherLastName,
        fatherNIDNo: mydata.fatherNIDNo,
        motherFirstName: mydata.fatherNIDNo,
        motherFirstName: mydata.motherFirstName,
        motherMiddleName: mydata.motherMiddleName,
        motherLastName: mydata.motherLastName,
        motherNIDNo: mydata.motherNIDNo,
        gender: OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].gendeR_ID : (drpsectionselectgender ? drpsectionselectgender : ""),
        dob: dateofbirthnew,
        mobileNo: mydata.mobileNo,
        email: mydata.email,
        admissionClassId: OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].admissioN_CLASS_ID : classoptions,
        admissionSesstionId: OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].admissioN_SESSION_ID : drpsessionselect,
      }, headers).then(function (response) {
        console.log(response)
        if (response.status == 200 && response.data.value == 1) {
          navigate("/manageonlineadmission")
        }
        else {
          navigate("/applyonline")
        }
      })

    } catch (err) {
      if (err) {
        console.error(err);
      }
    }
  }
  const handleSubmit = async (e) => {

    e.preventDefault();
    if (formdata.firstName.length > 50) {
      setfirstnamelabel("less than 50 characters required");
      setbordererrorfirstname(true);
    } else if (formdata.firstName.length == 0) {
      setfirstnamelabel("First Name Required");
      setbordererrorfirstname(true);
    } else {
      setfirstnamelabel("");
      setbordererrorfirstname(false);
    }
    if (
      (formdata.middleName && formdata.middleName.length < 3) ||
      formdata.middleName.length > 50
    ) {
      setmiddlenamelabel("3 to 50 characters please");
      setbordererrormiddlename(true);
    } else if (formdata.middleName.length == 0) {
      setmiddlenamelabel("Middle name Required");
      setbordererrormiddlename(true);
    } else {
      setmiddlenamelabel("");
      setbordererrormiddlename(false);
    }
    if (
      formdata.birthCertificateNo.length > 0 &&
      formdata.birthCertificateNo.length < 17
    ) {
      setlabelbirthcert("17 digits required");
      setbordererrorlabelbirthcert(true);
    } else if (formdata.birthCertificateNo.length == 0) {
      setlabelbirthcert("Birth certificate number required");
      setbordererrorlabelbirthcert(true);
    } else {
      setlabelbirthcert("");
      setbordererrorlabelbirthcert(false);
    }
    if (
      (formdata.fatherFirstName && formdata.fatherFirstName.length < 3) ||
      formdata.fatherFirstName.length > 50
    ) {
      setfatherfirstnamelabel("3 to 50 characters required");
      setbordererrorfatherfirstname(true);
    } else if (formdata.fatherFirstName.length == 0) {
      setfatherfirstnamelabel("First Name Required");
      setbordererrorfatherfirstname(true);
    } else {
      setfatherfirstnamelabel("");
      setbordererrorfatherfirstname(false);
    }
    if (
      (formdata.fatherMiddleName && formdata.fatherMiddleName.length < 3) ||
      formdata.fatherMiddleName.length > 50
    ) {
      setfathermiddlenamelabel("3 to 50 characters please");
      setbordererrorfathermiddlename(true);
    } else if (formdata.fatherMiddleName.length == 0) {
      setfathermiddlenamelabel("Middle name Required");
      setbordererrorfathermiddlename(true);
    } else {
      setfathermiddlenamelabel("");
      setbordererrorfathermiddlename(false);
    }

    if (formdata.fatherNIDNo.length == 0) {
      setfathernidlabel("NID is required");
      setbordererrorfathernid(true);
    } else if (formdata.fatherNIDNo.length < 10) {
      setfathernidlabel("NID should be 10 - 17 numbers");
      setbordererrorfathernid(true);
    } else {
      setfathernidlabel("");
      setbordererrorfathernid(false);
    }

    if (
      (formdata.motherFirstName && formdata.motherFirstName.length < 3) ||
      formdata.motherFirstName.length > 50
    ) {
      setmotherfirstnamelabel("3 to 50 characters required");
      setbordererrormotherfirstname(true);
    } else if (formdata.motherFirstName.length == 0) {
      setmotherfirstnamelabel("First Name Required");
      setbordererrormotherfirstname(true);
    } else {
      setmotherfirstnamelabel("");
      setbordererrormotherfirstname(false);
    }
    if (
      (formdata.motherMiddleName && formdata.motherMiddleName.length < 3) ||
      formdata.motherMiddleName.length > 50
    ) {
      setmothermiddlenamelabel("3 to 50 characters please");
      setbordererrormothermiddlename(true);
    } else if (formdata.motherMiddleName.length == 0) {
      setmothermiddlenamelabel("Middle name Required");
      setbordererrormothermiddlename(true);
    } else {
      setmothermiddlenamelabel("");
      setbordererrormothermiddlename(false);
    }
    if (formdata.motherNIDNo.length == 0) {
      setmothernidlabel("NID is required");
      setbordererrormothernid(true);
    } else if (formdata.motherNIDNo.length < 10) {
      setmothernidlabel("NID should be 10 - 17 numbers");
      setbordererrormothernid(true);
    } else {
      setmothernidlabel("");
      setbordererrormothernid(false);
    }
    if (drpsectionselectgender) {
      setlabelgender("Gender reauired");
      setbordergender(true);
    } else {
      setlabelgender("");
      setbordergender(false);
    }
    if (dateofbirthnew) {
      setlabeldob("Date Required");
    } else {
      setlabeldob("");
    }
    if (formdata.mobileNo.length > 0 && formdata.mobileNo.length < 11) {
      setlabelmobileno("Mobile no must be 11 digits");
      setbordererrormobileno(true);
    } else if (formdata.mobileNo.length == 0) {
      setlabelmobileno("Mobile no is required");
      setbordererrormobileno(true);
    } else {
      setlabelmobileno("");
      setbordererrormobileno(false);
    }
    if (formdata.email.length == 0) {
      setlabelemail("Email is required");
      setbordererroeemail(true);
    } else if (formdata.email && !/\S+@\S+\.\S+/.test(formdata.email)) {
      setlabelemail("Email format is incorrect");
      setbordererroeemail(true);
    } else {
      setlabelemail("");
      setbordererroeemail(false);
    }
    if (classoptions) {
      setlabelclass("Class reauired");
      setborderclass(true);
    } else {
      setlabelclass("");
      setborderclass(false);
    }
    if (drpsessionselect) {
      setlabelsession("Session required");
      setbordersection(true);
    } else {
      setlabelsession("");
      setbordersection(false);
    }
    //drpsectionselectgender !== "" && classoptions !== "" && drpsessionselect !== "" && labeladDate == "" dateofbirthnew !== "" &&
    if (
      firstnamelabel == "" && middlenamelabel == "" && labelbirthcert == "" &&
      labelmobileno == "" && labelemail == "" && fatherfirstnamelabel == "" && fathermiddlenamelabel == "" && fathernidlabel == "" &&
      mothernidlabel == "" && motherfirstnamelabel == "" && mothermiddlenamelabel == "" &&
      fnidlabel == "" && mnidlabel == "" && declineerrlabel == ""
    ) {
      await SaveApplyOnlineReview(formdata)
      console.log("done");
    }
    else {
      console.log("not done");
    }

  }

  const handleInput = (e) => {
    setformdata({ ...formdata, [e.target.name]: e.target.value })
  }


  const handleCloseApprv = () => {
    setdeclshow(false)
    setshowApprv(false)
  }

  const [showDecl, setshowDecl] = useState(false)
  const handleCloseDecl = () => {
    setshowDecl(false)
  }
  const handleopenDecl = () => {
    setshowDecl(true)
  }


  return (
    <>
      <SidebarOfAdash />

      <div className="contentpartwidthwrapper cntmanageonlinead">
        <Modal dialogClassName="mymodal" show={showReviewApply} onHide={handleClosereviewapply} backdrop="static"
          keyboard={false}>
          <Form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title className="ms-auto">Review Apply</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <h5>Student Info</h5>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>First Name*</p>
                    <div className="inputgap">
                      <input maxLength={50} style={bordererrorfirstname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="First Name" name="firstName" value={formdata.firstName} onChange={handleInput} />
                      <label>{firstnamelabel}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Middle Name*</p>
                    <div className="inputgap">
                      <input maxLength={50} style={bordererrormiddlename ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="Middle Name" name="middleName" value={formdata.middleName} onChange={handleInput} />

                      <label>{middlenamelabel}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Last Name</p>
                    <div className="inputgap">
                      <input maxLength={50} type="text" placeholder="Last Name" name="lastName" value={formdata.lastName} onChange={handleInput} />

                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Nick Name</p>
                    <div className="inputgap">
                      <input maxLength={50} type="text" placeholder="Nick Name" name="nickName" value={formdata.nickName} onChange={handleInput} />

                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="basicinput">
                    <p>Birth Certificate No*</p>
                    <div className="inputgap">
                      <input style={bordererrorlabelbirthcert ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} maxLength={17} type="text" placeholder="Birth Certificate No" name="birthCertificateNo" value={formdata.birthCertificateNo} onChange={handleInput} />
                      <label>{labelbirthcert}</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <h5>Father Info</h5>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>First Name*</p>
                    <div className="inputgap">
                      <input maxLength={50} style={bordererrorfatherfirstname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="First Name" name="fatherFirstName" value={formdata.fatherFirstName} onChange={handleInput} />
                      <label>{fatherfirstnamelabel}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Middle Name*</p>
                    <div className="inputgap">
                      <input maxLength={50} style={bordererrorfathermiddlename ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="Middle Name" name="fatherMiddleName" value={formdata.fatherMiddleName} onChange={handleInput} />

                      <label>{fathermiddlenamelabel}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Last Name</p>
                    <div className="inputgap">
                      <input maxLength={50} type="text" placeholder="Last Name" name="fatherLastName" value={formdata.fatherLastName} onChange={handleInput} />

                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>NID No*</p>
                    <div className="inputgap">
                      <input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} maxLength={17} style={bordererrorfathernid ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="NID No" name="fatherNIDNo" value={formdata.fatherNIDNo} onChange={handleInput} />
                      <label>{fathernidlabel}</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <h5>Mother Info</h5>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>First Name*</p>
                    <div className="inputgap">
                      <input maxLength={50} style={bordererrormotherfirstname ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="First Name" name="motherFirstName" value={formdata.motherFirstName} onChange={handleInput} />
                      <label>{motherfirstnamelabel}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Middle Name*</p>
                    <div className="inputgap">
                      <input maxLength={50} style={bordererrormothermiddlename ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="Middle Name" name="motherMiddleName" value={formdata.motherMiddleName} onChange={handleInput} />

                      <label>{mothermiddlenamelabel}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Last Name</p>
                    <div className="inputgap">
                      <input maxLength={50} type="text" placeholder="Last Name" name="motherLastName" value={formdata.motherLastName} onChange={handleInput} />

                    </div>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>NID No*</p>
                    <div className="inputgap">
                      <input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} maxLength={17} style={bordererrormothernid ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="NID No" name="motherNIDNo" value={formdata.motherNIDNo} onChange={handleInput} />
                      <label>{mothernidlabel}</label>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <h5>Other Info</h5>
                <Col lg={4} xs={6}>
                  <div className="basicinput">
                    <p>Gender*</p>
                    <div className="inputgap">
                      <Dropdown2 func={drpfunctiongender} myplaceholder={OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].gender : "Select"} fontsize="18" fontfamily="'Poppins', sans-serif" options={genderresponse} />
                      <label>{labelgender}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={4} xs={6}>
                  <div className="basicinput">
                    <p>Date of Birth*</p>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div className="inputgap">
                        <DatePicker
                          value={OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].dob : dateofbirthnew}
                          onChange={(newvaldob) => handleDateofbirth(newvaldob)}
                          renderInput={(params) => <TextField {...params} inputProps={{
                            ...params.inputProps,
                            placeholder: "Date of Birth"
                          }} error={false} />}
                        />
                        <label>{labeldob}</label>
                      </div>
                    </LocalizationProvider>
                  </div>
                </Col>
                <Col lg={4} xs={6}>
                  <div className="basicinput">
                    <p>Mobile No*</p>
                    <div className="inputgap">
                      <input onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }} maxLength={11} style={bordererrormobileno ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} type="text" placeholder="Mobile No" name="mobileNo" value={formdata.mobileNo} onChange={handleInput} />
                      <label>{labelmobileno}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={5} xs={6}>
                  <div className="basicinput">
                    <p>Email Address*</p>
                    <div className="inputgap">
                      <input type="text" style={bordererroeemail ? { borderColor: "rgb(237, 28, 36)" } : { borderColor: "rgba(0,0,0,0.2)" }} placeholder="Email" name="email" value={formdata.email} onChange={handleInput} />
                      <label>{labelemail}</label>
                    </div>
                  </div>
                </Col>
                <Col lg={3} xs={6}>
                  <div className="basicinput">
                    <p>Class</p>
                    <div className="inputgap">
                      <div className="dropdownwrapper scrollabledrp">
                        <Dropdown2 func={drpfuncClass} myplaceholder={OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].admissioN_CLASS : "Select"} fontsize="18" fontfamily="'Poppins', sans-serif" options={classresponse} />
                      </div>
                      <label>{labelclass}</label>
                    </div>
                  </div>

                </Col>
                <Col lg={4} xs={6}>
                  <div className="basicinput">
                    <p>Session</p>
                    <div className="inputgap">
                      <div className="dropdownwrapper">
                        <Dropdown2 func={drpfunctionsession} myplaceholder={OnlineAdmissionReviewrespone2.data ? OnlineAdmissionReviewrespone2.data[0].admissioN_SESSION : "Select"} fontsize="18" fontfamily="'Poppins', sans-serif" options={sessionresponse} />
                      </div>
                      <label>{labelsession}</label>
                    </div>
                  </div>

                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="m-auto justify-content-center" style={{ borderTop: "0" }}>
              <p type="text" variant="secondary" onClick={handleClosereviewapply}>
                Close
              </p>
              <button type="submit">
                Save
              </button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal className="attachmentmodal" dialogClassName="mymodal2" show={showAtt} onHide={handleCloseAtt} backdrop="static"
          keyboard={false}>

          <Modal.Header closeButton>
            <Modal.Title className="ms-auto">Attachment Files</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container className="wholetabofatt" id="left-tabs-example" defaultActiveKey="first">
              <Row>
                <Col sm={12} lg={4} xl={3}>
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Student Image</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Student Transcript</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Birth Certificate</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fourth">Father NID</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="fifth">Mother NID</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={12} lg={8} xl={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      {
                        attachmentfilesapi.stupic.length !== 0 ?
                          <img src={OnlineAdmissionReviewrespone2.data ? "./assets/images/ApplyOnlineImage/" + OnlineAdmissionReviewrespone2.data[0].stuImg : attachmentfilesapi.stupic} />
                          : "no image to show"
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      {
                        attachmentfilesapi.transcript.length !== 0 ?
                          //<img  src={attachmentfilesapi.transcript}/>
                          <img src={OnlineAdmissionReviewrespone2.data ? "./assets/images/ApplyOnlineImage/" + OnlineAdmissionReviewrespone2.data[0].transcriptImg : attachmentfilesapi.transcript} />
                          : "no image to show"
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      {
                        attachmentfilesapi.bcertificate.length !== 0 ?
                          // <img  src={attachmentfilesapi.bcertificate}/>
                          <img src={OnlineAdmissionReviewrespone2.data ? "./assets/images/ApplyOnlineImage/" + OnlineAdmissionReviewrespone2.data[0].certificateImg : attachmentfilesapi.bcertificate} />
                          : "no image to show"
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="fourth">
                      {
                        attachmentfilesapi.fnid.length !== 0 ?
                          // <img  src={attachmentfilesapi.fnid}/>
                          <img src={OnlineAdmissionReviewrespone2.data ? "./assets/images/ApplyOnlineImage/" + OnlineAdmissionReviewrespone2.data[0].fatherNidImg : attachmentfilesapi.fnid} />
                          : "no image to show"
                      }
                    </Tab.Pane>
                    <Tab.Pane eventKey="fifth">
                      {
                        attachmentfilesapi.mnid.length !== 0 ?
                          // <img  src={attachmentfilesapi.mnid}/>
                          <img src={OnlineAdmissionReviewrespone2.data ? "./assets/images/ApplyOnlineImage/" + OnlineAdmissionReviewrespone2.data[0].motherNidImg : attachmentfilesapi.mnid} />
                          : "no image to show"
                      }
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer className="m-auto justify-content-center" style={{ borderTop: "0" }}>
            <p type="text" variant="secondary" onClick={handleCloseAtt}>
              Close
            </p>
          </Modal.Footer>

        </Modal>

        <div className="apprvmodalwrap">
          <Modal className="apprvmodal" dialogClassName="mymodal3" show={showApprv} onHide={handleCloseApprv} backdrop="static"
            keyboard={false}>

            <Modal.Header closeButton>
              <Modal.Title className="ms-auto">{declshow == false ? "Approve/Decline Application" : "Decline Application"}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="pdiv text-center">
                {
                  declshow == false ?
                    <p>Click approve if all document is correct</p>
                    :
                    <div className="basicinput smalltext">
                      <p>Set a reason for decline applicaton</p>
                      <div className="inputgap">
                        <textarea name="declReason" value={formdata.declReason} onChange={handleInput} style={bordererrordecl ? { borderColor: "rgb(237, 28, 36)", height: "138px", width: "100%", border: "1px solid rgba(0,0,0,0.2)", borderRadius: "10px", padding: "10px" } : { borderColor: "rgba(0,0,0,0.2)", height: "138px", width: "100%", border: "1px solid rgba(0,0,0,0.2)", borderRadius: "10px", padding: "10px" }} id=""></textarea>
                        <label>{declineerrlabel}</label>
                      </div>
                    </div>
                }
              </div>
            </Modal.Body>
            <Modal.Footer className="m-auto justify-content-center" style={{ borderTop: "0" }}>
              {
                declshow == false ?
                  <>
                    <p type="text" variant="secondary" onClick={handleDeclinenew}>
                      Decline
                    </p>
                    <button variant="primary" onClick={handleApprove}>Approve</button>
                  </>
                  :
                  <>
                    <p type="text" variant="secondary" onClick={handleCloseApprv}>
                      Close
                    </p>
                    <button onClick={handleDeclineSubmit} variant="primary">Save</button>
                  </>
              }

            </Modal.Footer>=
          </Modal>

        </div>

        <HeaderofAdash head="Manage Online Admission" />

        <div className="contentofadmindash">
          <Row style={{ marginTop: "20px" }}>
            <Col className="m-auto" lg={4}>
              <Row>
                <Col lg={3}>
                  <div className="basicinput">
                    <p>Show</p>
                    <div className="dropdownwrapper">

                      <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif" options={optionsShowt} />
                    </div>
                  </div>
                </Col>
                <Col lg={9}>
                  <div className="basicinput">
                    <p>Search</p>
                    <div className="wrap">
                      <input type="text" value={searchkeyword} onChange={handleSearchkeyword} placeholder="Search" />
                      <img src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <div className="manageonlineadtable">
                <table>
                  <thead>
                    <tr>
                      {
                        manageonlinetable.heading.map((item, key) => {
                          return (
                            <th key={key}><div>{item.head}</div> </th>
                          )
                        })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      ManageOnlineAdmissionresponse ?

                        ManageOnlineAdmissionresponse.map((item, key) => {
                          if (searchkeyword == item.admissionId) {
                            return (
                              <tr key={key}>
                                <td>{item.admissionId}</td>
                                <td>{item.studentName}</td>
                                <td>{item.sessionName}</td>
                                <td>{item.className}</td>
                                <td>{item.mobile}</td>
                                <td>{item.birthCertificate}</td>
                                <td>{item.applyDate}</td>
                                <td>{item.status}</td>
                                <td>{item.paymentStatus}</td>
                                <td>
                                  <div className="img text-center">
                                    <img onClick={() => handleaction(item.admissionId, drptoggler)} src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" />
                                    {
                                      drpshow == item.admissionId ?
                                        <div className="drpdwn">
                                          <ul>
                                            <li><p onClick={handleShowreviewapply}>Review Apply</p></li>
                                            <li><p onClick={handleShowAtt}>Attachment Files</p></li>
                                            <li><p onClick={handleOpenApprv}>Approve Application</p></li>
                                            <li><p onClick={handleopenDecl}>Decline Application</p></li>
                                          </ul>
                                        </div>
                                        : ""
                                    }
                                  </div>
                                </td>
                              </tr>
                            )

                          }
                          if (searchkeyword == "") {
                            return (
                              <tr key={key}>
                                <td>{item.admissionId}</td>
                                <td>{item.studentName}</td>
                                <td>{item.sessionName}</td>
                                <td>{item.className}</td>
                                <td>{item.mobile}</td>
                                <td>{item.birthCertificate}</td>
                                <td>{item.applyDate}</td>
                                <td>{item.status}</td>
                                <td>{item.paymentStatus}</td>
                                <td>
                                  {/* <div className="img text-center">
                                      <img onClick={()=>handleaction(item.admissionId,drptoggler)} src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" />  
                                      {
                                        drpshow==item.admissionId?
                                        <div className="drpdwn">
                                            <ul>
                                              <li><p onClick={()=>handleShowreviewapply(item.admissionId)}>Review Apply</p></li>
                                              <li><p onClick={() =>handleShowAtt(item.admissionId)}>Attachment Files</p></li>
                                              <li><p onClick={handleOpenApprv}>Approve Application</p></li>
                                              <li><p onClick={handleopenDecl}>Decline Application</p></li>
                                            </ul>
                                        </div>
                                        :""
                                      }
                                    </div>   */}
                                  <div className="img text-center">
                                    <div className="eachicon">
                                      <img onClick={() => handleShowreviewapply(item.admissionId)} src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" />

                                    </div>
                                    <div className="eachicon">
                                      <GrAttachment onClick={() => handleShowAtt(item.admissionId)} />

                                    </div>
                                    <div className="eachicon">
                                      <RiCheckboxMultipleLine onClick={() => handleOpenApprv(item.admissionId)} />
                                    </div>

                                  </div>
                                </td>
                              </tr>
                            )

                          }

                        })
                        : ""
                    }
                  </tbody>

                </table>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={10} className="m-auto">

              <Row>
                <Col lg={6}>
                  <p className='pagileft'>Showing {itemOffset + 1} to {itemOffset + 10} of {manageonlinetable.datatable.length} entries (filtered from 10 total entries)</p>
                </Col>
                <Col lg={6} className="ms-auto">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel="next"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={5}
                    pageCount={pageCount}
                    previousLabel="previous"
                    renderOnZeroPageCount={null}
                    containerClassName='pagination'
                    pageLinkClassName='page-num'
                    previousLinkClassName='page-numl'
                    nextLinkClassName='page-numr'
                    activeLinkClassName='activelink'
                    disabledClassName="disabledClass"

                  />
                </Col>
              </Row>
            </Col>

          </Row>
        </div>
      </div>
    </>
  )
}

export default ManageOnlineAdmissionComp