import { IonApp, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';


import Page from './pages/Page';


// import '@ionic/react/css/core.css';


// import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
// import '@ionic/react/css/typography.css';


// import '@ionic/react/css/padding.css';
// import '@ionic/react/css/float-elements.css';
// import '@ionic/react/css/text-alignment.css';
// import '@ionic/react/css/text-transformation.css';
// import '@ionic/react/css/flex-utils.css';
// import '@ionic/react/css/display.css';

/* Theme variables */
// import './theme/variables.css';

// react bootstrap

import 'bootstrap/dist/css/bootstrap.min.css';
import Homepage from './pages/Homepage';
import AboutUsPage from './pages/AboutUsPage';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import NoticePage from './pages/NoticePage';
import Scroll from './components/subComponents/ScrollToTop';
import CareerPage from './pages/CareerPage';
import Login from './pages/Login';
import ForgotPassword from './pages/ForgotPassword';
import BillPage from './pages/BillPage';
import BillEntryPage from './pages/BillEntryPage';
import StudentAdmissionEntryBillPage from './pages/StudentAdmissionEntryBillPage';
import ContactPage from './pages/ContactPage';
import AdmissionPage from './pages/AdmissionPage';
import StudentAgeCheckPage from './pages/StudentAgeCheckPage';
import SchoolTimingPage from './pages/SchoolTimingPage';
import TrackAdmissionPage from './pages/TrackAdmissionPage';
import KarateCoursePage from './pages/KarateCoursePage';
import FeeStructurePage from './pages/FeeStructurePage';
import NoticePageSingleDetail from './pages/NoticePageSingleDetail';
import UpcomingPageSingledetail from './pages/UpcomingPageSingledetail';
import OlderNoticeSingleDetailPage from './pages/OlderNoticeSingleDetailPage';
import CareerDetailsPage from './pages/CareerDetailsPage';
import StudentDashboardPage from './pages/dashboard/StudentDashboardPage';
import UsernameORPassCouldnotMatch from './components/subComponents/UsernameORPassCouldnotMatch';
import TeacherDashboardPage from './pages/dashboard/TeacherDashboardPage';
import StudentTeacherChattingPage from './pages/dashboard/StudentTeacherChattingPage';
import StudentDashHelpSupportPage from './pages/dashboard/StudentDashHelpSupportPage';
import StudentPhotoGallaryPage from './pages/dashboard/StudentPhotoGallaryPage';
import StudentPayNowPage from './pages/dashboard/StudentPayNowPage';
import StudentDashbordRedirect from './pages/dashboard/StudentDashbordRedirect';
import AdminDashboardHome from './pages/adminDashboard/AdminDashboardHome';
import FlexValueAdashPage from './pages/adminDashboard/FlexValueAdashPage';
import ApplyOnlinePage from './pages/ApplyOnlinePage';
import { useEffect } from 'react';
import SubmitSuccessPage from './pages/SubmitSuccessPage';
import FlexValueSetAdashPage from './pages/adminDashboard/FlexValueSetAdashPage';
import NotFountPage404 from './pages/errorPages/NotFountPage404';
import InternalServerError500 from './pages/errorPages/InternalServerError500';
import PageisGone410 from './pages/errorPages/PageisGone410';
import ServiceUnavailable503 from './pages/errorPages/ServiceUnavailable503';
import ManageOnlineAdmission from './pages/adminDashboard/ManageOnlineAdmission';
import ManageReviewApply from './pages/adminDashboard/ManageReviewApply';
import StuDashPage from './pages/dashboard/studentDashboard/StuDashPage';
import StuDashDocumentPage from './pages/dashboard/studentDashboard/StuDashDocumentPage';
import StuDashNoticePage from './pages/dashboard/studentDashboard/StuDashNoticePage';
import StuDashClassRPage from './pages/dashboard/studentDashboard/StuDashClassRPage';
import StuDashExamRPage from './pages/dashboard/studentDashboard/StuDashExamRPage';
import StuDashAttPage from './pages/dashboard/studentDashboard/StuDashAttPage';
import StuDashBillPage from './pages/dashboard/studentDashboard/StuDashBillPage';
import StuDashResultPage from './pages/dashboard/studentDashboard/StuDashResultPage';
import StuDashLeaveFPage from './pages/dashboard/studentDashboard/StuDashLeaveFPage';
import StuDashProfilePage from './pages/dashboard/studentDashboard/StuDashProfilePage';
import TeaDashPage from './pages/dashboard/teacherDashboard/TeaDashPage';
import TeadClassRoutinePage from './pages/dashboard/teacherDashboard/TeadClassRoutinePage';
import TeadCurriculumPage from './pages/dashboard/teacherDashboard/TeadCurriculumPage';
import TeadResultEntryPage from './pages/dashboard/teacherDashboard/TeadResultEntryPage';
import ReduxTest from './pages/reduxTest/ReduxTest';
import AttendanceSummaryPage from './pages/adminDashboard/AttendanceSummaryPage';
import TeaAttendancePage from './pages/dashboard/teacherDashboard/TeaAttendancePage';
import TeadSalaryinfoPage from './pages/dashboard/teacherDashboard/TeadSalaryinfoPage';
import TeadLeaveformPage from './pages/dashboard/teacherDashboard/TeadLeaveformPage';
import TeadProfilePage from './pages/dashboard/teacherDashboard/TeadProfilePage';
import TeaDashHelpSPage from './pages/dashboard/teacherDashboard/TeaDashHelpSPage';
import ResultProcessPage from './pages/adminDashboard/ResultProcessPage';
import ReportCardPage from './pages/adminDashboard/ReportCardPage';
import StudentPositionProcessPage from './pages/adminDashboard/StudentPositionProcessPage';
import ReesultReportPage from './pages/adminDashboard/ReesultReportPage';
import RolePage from './pages/adminDashboard/RolePage';
import RoleMgmtPage from './pages/adminDashboard/RoleMgmtPage';
import MenuPage from './pages/adminDashboard/MenuPage';
import MenuMgmtPage from './pages/adminDashboard/MenuMgmtPage';
import ClassSubforAdPage from './pages/adminDashboard/ClassSubforAdPage';
import ApplyOnlinePage2 from './pages/AppluOnlinePage2';
// import StudentDashboardPage from './pages/dashboard/studentDashboard/StudentDashboardPage';

setupIonicReact();

const App: React.FC = () => {

  const response = {
    status:"500"
  }
  

  return (
    <IonApp>
      
      <BrowserRouter>
    

      <Scroll/>
      <Routes>
        <Route  path="/" element={<Homepage />}/>
        
        {
          response.status == "500"?
          <Route  path="/internalservererror" element={<InternalServerError500 />}/>
          :
          ""
        }
        {
          response.status == "410"?
          <Route  path="/pageisgone" element={<PageisGone410 />}/>
          :
          ""
        }
        {
          response.status == "503"?
          <Route  path="/serviceunavailable" element={<ServiceUnavailable503 />}/>
          :
          ""
        }
        
        <Route  path="*" element={<NotFountPage404 />}/>
        
        
        <Route  path="/redux" element={<ReduxTest />}/>



        <Route path="/applyonline" element={<ApplyOnlinePage/>} />
        
        <Route path="/submitSuccess" element={<SubmitSuccessPage/>} />
          
        <Route  path="/aboutus" element={<AboutUsPage />}/>
          
        <Route  path="/notice" element={<NoticePage/>}/>
        
        <Route  path="/notice/latest/:id" element={<NoticePageSingleDetail/>}/>
        
        <Route  path="/notice/upcoming/:id" element={<UpcomingPageSingledetail/>}/>
        
        <Route  path="/notice/older/:id" element={<OlderNoticeSingleDetailPage/>}/>
          
        <Route  path="/career" element={<CareerPage/>}/>
        
        <Route  path="/career/careerdetails/:id" element={<CareerDetailsPage/>}/>

        <Route  path="/contact" element={<ContactPage/>}/>

        <Route  path="/admission" element={<AdmissionPage/>}/>

        <Route  path="/studentagecheck" element={<StudentAgeCheckPage/>}/>
        
        <Route  path="/schooltiming" element={<SchoolTimingPage/>}/>
        
        <Route  path="/trackadmission" element={<TrackAdmissionPage/>}/>

        <Route  path="/karatecourse" element={<KarateCoursePage/>}/>
        
        <Route  path="/feestructure" element={<FeeStructurePage/>}/>

        <Route  path="/login" element={<Login/>}/>

        <Route  path="/forgotpassword" element={<ForgotPassword/>}/>

        <Route  path="/billpage" element={<BillPage/>}/>

        <Route  path="/billentry" element={<BillEntryPage/>}/>
        
        <Route  path="/studashboard" element={<StuDashPage/>}/>            
        
        <Route  path="/studashboard/document" element={<StuDashDocumentPage/>}/>            
        
        <Route  path="/studashboard/notice" element={<StuDashNoticePage/>}/>            
        
        <Route  path="/studashboard/classroutine" element={<StuDashClassRPage/>}/>            
        
        <Route  path="/studashboard/examroutine" element={<StuDashExamRPage/>}/>            
        
        <Route  path="/studashboard/attendance" element={<StuDashAttPage/>}/>            
        
        <Route  path="/studashboard/billinfo" element={<StuDashBillPage/>}/>            
        
        <Route  path="/studashboard/result" element={<StuDashResultPage/>}/>            
        
        <Route  path="/studashboard/leaveform" element={<StuDashLeaveFPage/>}/>            
        
        <Route  path="/studashboard/profile" element={<StuDashProfilePage/>}/>            
        
        <Route  path="/studashboard/:slug" element={<StudentDashbordRedirect/>}/>            
        
        <Route  path="/studashboard/classteacher" element={<StudentTeacherChattingPage/>}/>            
                  
        <Route  path="/studashboard/helpandsupport" element={<StudentDashHelpSupportPage/>}/>            
        
        <Route  path="/teacherdashboard/helpandsupport" element={<TeaDashHelpSPage/>}/>            
        
        <Route  path="/studashboard/payment" element={<StudentPayNowPage/>}/>            

        <Route  path="/teacherdashboard" element={<TeaDashPage/>}/>
        
        <Route  path="/teacherdashboard/classroutine" element={<TeadClassRoutinePage/>}/>
        
        <Route  path="/teacherdashboard/curriculumentry" element={<TeadCurriculumPage/>}/>
        
        <Route  path="/teacherdashboard/resultentry" element={<TeadResultEntryPage/>}/>
        
        <Route  path="/teacherdashboard/classroutine" element={<TeadClassRoutinePage/>}/>
        
        <Route  path="/teacherdashboard/curriculumentry" element={<TeadCurriculumPage/>}/>
        
        
        
        <Route  path="/teacherdashboard/attendance" element={<TeaAttendancePage/>}/>
        
        <Route  path="/teacherdashboard/salaryinfo" element={<TeadSalaryinfoPage/>}/>
        
        <Route  path="/teacherdashboard/leaveform" element={<TeadLeaveformPage/>}/>
        
        <Route  path="/teacherdashboard/profile" element={<TeadProfilePage/>}/>
        
        
        <Route  path="/studentphotogallery" element={<StudentPhotoGallaryPage/>}/>

        <Route  path="/studentaddmissionentry" element={<StudentAdmissionEntryBillPage/>}/>
        
        <Route  path="/userpassnotmatched" element={<UsernameORPassCouldnotMatch/>}/>
        
        <Route  path="/admindashboard" element={<AdminDashboardHome/>}/>
        
        <Route  path="/flexvalueadashpage" element={<FlexValueAdashPage/>}/>
        
        <Route  path="/flexvaluesetadashpage" element={<FlexValueSetAdashPage/>}/>
        
        <Route  path="/manageonlineadmission" element={<ManageOnlineAdmission/>}/>
        
        <Route  path="/attendancesummary" element={<AttendanceSummaryPage/>}/>
        
        <Route  path="/admin/resultprocess" element={<ResultProcessPage/>}/>
        
        
        <Route  path="/admin/reportcard" element={<ReportCardPage/>}/>
        
        <Route  path="/admin/stuposition" element={<StudentPositionProcessPage/>}/>
        
        <Route  path="/admin/resultreport" element={<ReesultReportPage/>}/>
        
        <Route  path="/admin/role" element={<RolePage/>}/>
        
        <Route  path="/admin/rolemgmt" element={<RoleMgmtPage/>}/>
        
        <Route  path="/admin/menumanagement" element={<MenuMgmtPage/>}/>
        
        <Route  path="/admin/classSubjectforAdm" element={<ClassSubforAdPage/>}/>
        

      </Routes>
      
      
    </BrowserRouter>
       
    </IonApp>
  );
};

export default App;
