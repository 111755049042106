import React from 'react'

const manageonlinetable = {
    heading:[
        {
            head:"ID"
        },
        {
            head:"Student Name"
        },
        {
            head:"Session"
        },
        {
            head:"Class"
        },
        {
            head:"Mobile"
        },
        {
            head:"B.Certificate"
        },
        {
            head:"Apply Date"
        },
        {
            head:"P.Status"
        },
        {
            head:"A.Status"
        },
        {
            head:"Action"
        },
    ],

    datatable:[
        {
            id:1,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:2,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:3,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:4,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:5,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:1,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:2,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:3,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:4,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:5,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:1,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:2,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:3,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:4,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:5,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:1,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:2,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:3,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:4,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:5,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Arifa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Arishfa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Arishfa Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
       
      
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:6,
            studentname:"Sumshiyara Khan",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
        {
            id:7,
            studentname:"Shoumik shams",
            session:"June 2022 - July 2023",
            class:6,
            mobile:"01741852354",
            bcertificate:"",
            applydate:"2023-4-17",
            pstatus:"pending",
            astatus:"on hold",
            action:""

        },
    ]
}

const ManageOnlineAd = () => {
  return (
    <>
    </>
  )
}

export {manageonlinetable}

export default ManageOnlineAd