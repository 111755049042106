import React,{useRef,useState,useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import {Row,Col,Nav,Tab,Tabs,Button} from 'react-bootstrap'
import { sidebarApi } from '../../../utils/DashboardApi/StudentDashboardApi'
import { Link } from 'react-router-dom'

import Dropdown from 'react-dropdown';
import {BsChevronDown} from 'react-icons/bs'
import HeaderOfstuDashComp from './subComponents/HeaderOfstuDashComp'
import { headerprops } from '../../../utils/DashboardApi/StudentDashboardApi'
import SidebarOfsDash from './subComponents/SidebarOfsDash'
const StuDocumentComp = () => {
    
    
    const Optiondoctypeprevdoc = [
        'All','Home Work','Class Work','Exam Paper','Assessment','Syllabus'
      ];
      const defaultOptiondoctypeprevdoc = Optiondoctypeprevdoc[0];
      const [doctypeprevdoc,setdoctypeprevdoc] = useState("")
      const dropdownvaluedovtypeprevdoc=(e)=>{
        // console.log(e.value)
        setdoctypeprevdoc(e.value)
        
      }
      
      
      
  return (
    <div className="stu_dash_mainWrap">
        <SidebarOfsDash activeID="1"/>
        <div className="rightpartcontentwrap">
            <HeaderOfstuDashComp heading="Documents" myprops={headerprops}/>
            <div className="tabPane">

        <div className="tabpaneheightadjust tabpanedocument">
                <div className="todaydocuments">
                    <h4>Today Documents</h4>
                    <Row>
                        <Col lg={6} style={{paddingRight:"15px"}}>
                            <div className="documenttabsignlebar">
                                <img src="./assets/images/dashboards/studentDashboard/documentTab/1.png" alt="" />
                                <div className="documenttabtexts">
                                    <h6>Home Work</h6>
                                    <p>8 files available to download</p>
                                </div>
                                <div className="downloadbuttondoctab">
                                    <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} style={{paddingLeft:"0"}}>
                            <div className="documenttabsignlebar">
                                    <img src="./assets/images/dashboards/studentDashboard/documentTab/2.png" alt="" />
                                    <div className="documenttabtexts">
                                        <h6>Class Work</h6>
                                        <p>8 files available to download</p>
                                    </div>
                                    <div className="downloadbuttondoctab">
                                        <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                    </div>
                                </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"15px"}}>
                        <Col lg={6} style={{paddingRight:"15px"}}>
                            <div className="documenttabsignlebar">
                                <img src="./assets/images/dashboards/studentDashboard/documentTab/3.png" alt="" />
                                <div className="documenttabtexts">
                                    <h6>Exam Paper</h6>
                                    <p>8 files available to download</p>
                                </div>
                                <div className="downloadbuttondoctab">
                                    <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} style={{paddingLeft:"0"}}>
                            <div className="documenttabsignlebar">
                                    <img src="./assets/images/dashboards/studentDashboard/documentTab/4.png" alt="" />
                                    <div className="documenttabtexts">
                                        <h6>Assessment</h6>
                                        <p>8 files available to download</p>
                                    </div>
                                    <div className="downloadbuttondoctab">
                                        <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                    </div>
                                </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"15px"}}>
                        <Col lg={6} style={{paddingRight:"15px"}}>
                            <div className="documenttabsignlebar">
                                <img src="./assets/images/dashboards/studentDashboard/documentTab/5.png" alt="" />
                                <div className="documenttabtexts">
                                    <h6>Syllabus</h6>
                                    <p>8 files available to download</p>
                                </div>
                                <div className="downloadbuttondoctab">
                                    <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="preveousdocumnets">
                    <h4>Previous Documents</h4>
                    <div className="docselects">
                        <div className="dropdownwrapper docseletssingle">
                            <Dropdown className='filterdropone' options={Optiondoctypeprevdoc} onChange={(e)=>dropdownvaluedovtypeprevdoc(e)} value={defaultOptiondoctypeprevdoc} />
                            <BsChevronDown/>
                        </div>
                        <input type="date" />
                        <input type="date" />
                    </div>
                    <Row>
                        <Col lg={6} style={{paddingRight:"15px"}}>
                            <div className="documenttabsignlebar">
                                <img src="./assets/images/dashboards/studentDashboard/documentTab/1.png" alt="" />
                                <div className="documenttabtexts">
                                    <h6>Home Work</h6>
                                    <p>8 files available to download</p>
                                </div>
                                <div className="downloadbuttondoctab">
                                    <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} style={{paddingLeft:"0"}}>
                            <div className="documenttabsignlebar">
                                    <img src="./assets/images/dashboards/studentDashboard/documentTab/2.png" alt="" />
                                    <div className="documenttabtexts">
                                        <h6>Class Work</h6>
                                        <p>8 files available to download</p>
                                    </div>
                                    <div className="downloadbuttondoctab">
                                        <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                    </div>
                                </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"15px"}}>
                        <Col lg={6} style={{paddingRight:"15px"}}>
                            <div className="documenttabsignlebar">
                                <img src="./assets/images/dashboards/studentDashboard/documentTab/3.png" alt="" />
                                <div className="documenttabtexts">
                                    <h6>Exam Paper</h6>
                                    <p>8 files available to download</p>
                                </div>
                                <div className="downloadbuttondoctab">
                                    <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6} style={{paddingLeft:"0"}}>
                            <div className="documenttabsignlebar">
                                    <img src="./assets/images/dashboards/studentDashboard/documentTab/4.png" alt="" />
                                    <div className="documenttabtexts">
                                        <h6>Assessment</h6>
                                        <p>8 files available to download</p>
                                    </div>
                                    <div className="downloadbuttondoctab">
                                        <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                    </div>
                                </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop:"15px"}}>
                        <Col lg={6} style={{paddingRight:"15px"}}>
                            <div className="documenttabsignlebar">
                                <img src="./assets/images/dashboards/studentDashboard/documentTab/5.png" alt="" />
                                <div className="documenttabtexts">
                                    <h6>Syllabus</h6>
                                    <p>8 files available to download</p>
                                </div>
                                <div className="downloadbuttondoctab">
                                    <Button><img src="./assets/images/dashboards/studentDashboard/documentTab/downloadbtn.png" alt="" /> Download</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default StuDocumentComp