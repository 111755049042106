import React,{useState} from 'react'
import { adminSidebarApi } from '../../../utils/AdminDashApi/AdminSidebar';
import { useNavigate,Link } from 'react-router-dom';
import { Accordion,Card } from 'react-bootstrap';
const SidebarOfAdash = () => {
  const navigate = useNavigate()
  const handlehover=()=>{
    const select = document.querySelector(".sidebarofadmin");
    select.classList.add('opened');
  }
  const handlehover2=()=>{
    const select = document.querySelector(".sidebarofadmin");
    select.classList.remove('opened');
  }

  const [activeClass,setactiveClass] = useState(false)

  const [opendrpdwn,setopendrpdwn] = useState(false)

  const handleClick=(mylink,drplength)=>{
    navigate(mylink)
    if(drplength!==0){
      setopendrpdwn(!opendrpdwn)
    }
  }
  const handleLi = (mylink)=>{
    navigate(mylink)
   
  }
  return (
    <>
        <div className="sidebarofadmin" onMouseEnter={handlehover} onMouseLeave={handlehover2}>
            <div className="sidebarofadminbox">
                <div className="img text-center">
                  <img src={adminSidebarApi.topPart.img} alt="" />
                </div>
                {
                  adminSidebarApi.menuItem.map((item,key)=>{
                    if(item.drpdwn.length!==0){
                      return(
                        <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header><img src={item.img} alt="" /> <span>{item.name}</span> </Accordion.Header>
                          <Accordion.Body>
                          <ul>
                          { item.drpdwn.map((item2,key2)=>{
                            return(
                              <li onClick={()=>handleLi(item2.link)}> 
                              <div className={window.location.pathname==item2.link?`wr active`:`wr`}>
                                {item2.item}
                                </div>
                                 </li>
                            )
                          })}

                        </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      )

                    }else{
                      return(
                        <Card className={window.location.pathname==item.link?"item active":"item"}><Link to={item.link}> {window.location.pathname==item.link? <img src={item.activeicon} alt="" />:<img src={item.img} alt="" /> } <span>{item.name}</span></Link></Card>
                      )
                    }
                    
                  })
                }
                
                
            </div>
            <div className="logoutbutton">
                <Link> <img src="./assets/images/dashboards/adminDashboard/sidebar/signout.png" alt="" /> <span>Log Out</span></Link>
            </div>
          
        </div>
    </>
  )
}

export default SidebarOfAdash