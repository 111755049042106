import React from 'react'
import SidebarOfsDash from './subComponents/SidebarOfsDash'
import HeaderOfstuDashComp from './subComponents/HeaderOfstuDashComp'
import { headerprops, noticeData, noticeDataGreetings, noticeGreetingsdaydate } from '../../../utils/DashboardApi/StudentDashboardApi'
import {Row,Col} from 'react-bootstrap'
const StuDashNoticeComp = () => {
    function replaceWithBr() {
        return noticeDataGreetings.replace(/\n/g, "<br />")
        }
  return (
    <>
        <div className="stu_dash_mainWrap">
        <SidebarOfsDash activeID="2"/>
        <div className="rightpartcontentwrap">
            <HeaderOfstuDashComp heading="Notice" myprops={headerprops}/>
            <div className="tabPane">
            <div className="tabpaneheightadjust">
            <Row>
                <Col lg={12}>
               
                    <div className="noticetabbox">
                        <div className="noticetabboxbarbig">
                            <div className="dateofnoticebox">
                                <h5>{noticeGreetingsdaydate.day}<br/>{noticeGreetingsdaydate.year}</h5>
                            </div>
                            <h3 dangerouslySetInnerHTML={{__html: replaceWithBr()}} ></h3>
                        </div>
                    {
                        noticeData.map((item,key)=>{
                            return(
                                    <div key={key} className="noticetabboxbar">
                                        <div className="dateofnoticebox">
                                            <h5>{item.publishDateDayMonth}<br/>{item.publishDateYear}</h5>
                                        </div>
                                        <h3>{item.noticeheading} - <span>Published : {item.publishDate}</span></h3>
                                    </div>

                            )
                        })
                    }
                    </div>
                </Col>
            
            </Row>
            </div>
       
            </div>
        </div>
    </div>
    </>
  )
}

export default StuDashNoticeComp