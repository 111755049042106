import React,{useState} from 'react'
import Dropdown2 from '../../../subComponents/CustomSubComponents/Dropdown2'
import { attendanceApiteacher, periodsubjectdatateacher, salaryInformationteacher,routinetabDatateacher, curriculumtabtabledata, resultTabtabledata, attendanceDatateacher, prevSalarytabledata, profiletabApiteacher } from '../../../../utils/DashboardApi/TeacherDashboardApi'
import { Button } from 'react-bootstrap'
const TeaResultEntryTab = () => {
    const optionme = [
        {
            opt:'First Semester'
        },
        {
            opt:'Second Semester'
        }
    ]
    const optionselectclass = [
        {
            opt:'Class 1'
        },
        {
            opt:'Class 2'
        }
    ]
    const [editFormData,seteditFormData] = useState({
        id:null,
        sl:"",
        studentid:"",
        studentname:"",
        exam:"",
        examnumber:{

            exam1:null,
            exam2:null,
            exam3:null,
            exam4:null
        }
    })
    const [apiData,setapiData] = useState(resultTabtabledata.tabledata)
    const [editContactId,seteditContactId] = useState(null)
    const handleEditFormSubmit = (event)=>{
        event.preventDefault();
        console.log("hello");
        const editedContact = {
            id:editFormData.id,
            sl:editFormData.sl,
            studentid:editFormData.studentid,
            studentname:editFormData.studentname,
            exam:editFormData.exam,
            examnumbers:{
                exam1: editFormData.exam1,
                exam2: editFormData.exam2,
                exam3: editFormData.exam3,
                exam4: editFormData.exam4

            }

        }
       
        const newContacts = {...apiData}

        const index = apiData.findIndex((contact)=> contact.id == editContactId)

        newContacts[index] = editedContact
        console.log(newContacts)
        const arr =[]
        var result = Object.keys(newContacts).map((key) => arr.push(newContacts[key]))
        console.log(arr)
        setapiData(arr)
        seteditContactId(null)
    }
    const handleEditButton=(event,item)=>{
        event.preventDefault();

        seteditContactId(item.id)

        const formValues = {
            id:item.id,
            sl:item.sl,
            studentid:item.studentid,
            studentname:item.studentname,
            exam:item.exam,
            examnumbers:{
                exam1: item.examnumbers.exam1,
                exam2: item.examnumbers.exam2,
                exam3: item.examnumbers.exam3,
                exam4: item.examnumbers.exam4

            }
        }
        seteditFormData(formValues)
    }
    const handleEditFromChange = (e)=>{
        e.preventDefault();

        const fieldName = e.target.getAttribute("name");
        const fieldValue = e.target.value;
        const newFormData = {...editFormData}
        newFormData[fieldName] = fieldValue
        seteditFormData(newFormData)
    }
  return (
    <>
        <div className="bodycolorbg">
            <div className="mobilecontainer">
                <div className="heading">
                    <h3>Result Entry</h3>
                </div>

                <div className="samebox resultfilterentrybox">
                        <div className="resultfilterfirstrow">
                        <div className="flexwrap">

                        <div className="drpwithtextwrap">
                            <p>Select Class</p>
                            <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionselectclass}/>
                        </div>
                        <div className="drpwithtextwrap">
                            <p>Exam</p>
                            <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                        </div>
                        </div>
                        <div className="flexwrap">
                        <div className="drpwithtextwrap">
                            <p>Section</p>
                            <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                        </div>
                        <div className="drpwithtextwrap">
                            <p>Shift</p>
                            <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                        </div>

                        </div>
                        <div className="flexwrap">

                        <div className="drpwithtextwrap">
                            <p>Subject Select</p>
                            <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                        </div>
                        <div className="drpwithtextwrap">
                            <p>Sunject Subgroup</p>
                            <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                        </div>
                        </div>
                      
                        </div>
                        
                       
                    </div>
                    <div className="samebox resulttabentrybox">
                    <div className="resulttabtable">
                       
                    <div className="resulttabtabletable">
                            <form  onSubmit={handleEditFormSubmit}>
                            
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            
                                            resultTabtabledata.header.map((item,key)=>{
                                                return(
                                                    <th key={key}>{item.headerline}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                      {
                                        apiData.map((item,key)=>{
                                            return(

                                                
                                                editContactId!==item.id?
                                                    <>
                                                <tr>
                                                    <td>{item.sl}</td>
                                                    <td>{item.studentid}</td>
                                                    
                                                    <td>{item.studentname}</td>
                                                 
                                                    <td >{item.examnumbers.exam1}</td>
                                                    <td >{item.examnumbers.exam2}</td>
                                                    <td >{item.examnumbers.exam3}</td>
                                                    <td >{item.examnumbers.exam4}</td>
                                                        
                                                   
                                                        <td>37.5</td>
                                                     <td> <button onClick={(event)=>handleEditButton(event,item)}> <img src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" /> </button> </td>
                                               </tr>
                                                    </>
                                                    :

                                                    <>
                                                    <tr>
                                                    <td>{item.sl}</td>
                                                    <td>{item.studentid}</td>
                                                    
                                                    <td>{item.studentname}</td>
                                                    <td><input type="text" value={editFormData.exam1} onChange={handleEditFromChange} name="exam1"/></td>
                                                    <td><input type="text" value={editFormData.exam2} onChange={handleEditFromChange} name="exam2"/></td>
                                                    <td><input type="text" value={editFormData.exam3} onChange={handleEditFromChange} name="exam3"/></td>
                                                    <td><input type="text" value={editFormData.exam4} onChange={handleEditFromChange} name="exam4"/></td>
                                                        <td>37.5</td>
                                                     <td> <button onClick={(event)=>handleEditButton(event,item)} > <img src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" /> </button>  </td>
                                               </tr>
                                                    </>
                                              

                                                // <tr key={key}>
                                                //     <td>{item.sl}</td>
                                                //     <td>{item.studentid}</td>
                                                    
                                                //     <td>{item.studentname}</td>
                                                //     {
                                                //         item.examnumbers? 
                                                //         item.examnumbers.map((item2,key2)=>{
                                                //             return(
                                                //                 <td key={key2}> <input disabled type="text" id={`${item.id}${item2.name}`}   placeholder={item2.number} /> </td>
                                                //             )
                                                //         })
                                                //         :
                                                //         <>
                                                //         <td></td>
                                                //         <td></td>
                                                //         <td></td>
                                                //         <td></td>
                                                //         </>
                                                    
                                                //     }
                                                //     <td> <input id={key} disabled type="text" style={{border:"0"}} /> </td>
                                                //     <td>37.5</td>
                                                //     <td> <button onClick={handleinsideinput}> <img src="./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png" alt="" /> </button> <button><AiOutlineSave/></button> </td>
                                                // </tr>
                                            )
                                        })
                                      }
                                </tbody>
                            </table>
                            </form>
                      
                        </div>
                    {/* <div className="resulttabtabletable">
                        <table>
                                <thead>
                                    <tr>
                                        {
                                            
                                            resultTabtabledata.header.map((item,key)=>{
                                                return(
                                                    <th key={key}>{item.headerline}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                      {
                                        resultTabtabledata.tabledata.map((item,key)=>{
                                            return(
                                                <tr key={key}>
                                                    <td>{item.sl}</td>
                                                    <td>{item.studentid}</td>
                                                    
                                                    <td>{item.studentname}</td>
                                                    <td> <input type="text"  /> </td>
                                                    
                                                </tr>
                                            )
                                        })
                                      }
                                </tbody>
                            </table>
                        </div> */}
                            <div className="bottombuttondiv">
                            <Button>Print</Button>
                            <Button>Save</Button>
                        </div>

                    </div>
                    </div>
            </div>
        </div>
    </>
  )
}

export default TeaResultEntryTab