import React from 'react'
import '../../sassFiles/errorpages/notFoundPage.scss'
import { Link } from 'react-router-dom'
const ServiceUnavailable503 = () => {
  return (
    <>
     <div className="desktop">
        <div className="errorpage404wrap error503">
            <img src="./assets/images/errorPages/404/503.png" alt="" />
            <h4>SERVICE UNAVAILABLE</h4>
            <Link to="/">Go To Homepage</Link>
        </div>

    </div>
    </>
  )
}

export default ServiceUnavailable503