import React, { useEffect } from 'react'


export const roledrp = [
    {
        id:0,
        opt:"Select"
    },
    {
        id:1,
        opt:"Admin"
    },
    {
        id:2,
        opt:"Admin Academic"
    }
]

export const optionsStatus = [
    {
        id:1,
        opt:"All"
    },
    {
        id:2,
        opt:"Promoted"
    },
    {
        id:3,
        opt:"Not Promoted"
    }
]

export const optionspromotion = [
  {
      id:1,
      opt:'All'
  },
  {
      id:2,
      opt:'Promoted'
  },
  {
      id:3,
      opt:'Not Promoted'
  },
];
export const optionssession = [
  {
      id:1,
      opt:'July 2022 - June 2023'
  },
  {
      id:2,
      opt:'July 2023 - June 2024'
  }
];
export const optionssection = [
  {
    id:1,
    opt:"Diamond 1"
  },
  {
    id:2,
    opt:"Diamond 2"
  },
  {
    id:3,
    opt:"Ruby 1"
  },
  {
    id:4,
    opt:"Ruby 2"
  },
]
export const optionssemester = [
  {
      id:1,
      opt:'First Semester'
  },
  {
      id:2,
      opt:'Second Semester'
  }
];
export const optionsClass = [
  {   id:1,
      opt:'Select Class'
  },
  {   id:2,
      opt:'Starter'
  },
  {   id:3,
      opt:'Playgroup'
  },
  {   id:3,
      opt:'Nursery'
  },
  {   id:4,
      opt:'Junior KG'
  },
  {   id:5,
      opt:'Senior KG'
  },
  {   id:6,
      opt:'Class 1'
  },
  {   id:7,
      opt:'Class 2'
  },
  {   id:8,
      opt:'Class 3'
  },
  {
      id:9,
      opt:'Class 4'
  },
  {
      id:10,
      opt:'Class 5'
  },
  {
      id:11,
      opt:'Class 6'
  },
  {
      id:12,
      opt:'Class 7'
  },
  {
      id:13,
      opt:'O Level'
  },
  {
      id:14,
      opt:'A Level'
  },
  {
      id:15,
      opt:'Pre- O level'
  },

];

const Helper = () => {

    

  return (
    <></>
  )
}



export default Helper