import React from 'react'
import StuDashERoutineComp from './../../../components/dashboardComponents/studentDashboard/StuDashERoutineComp';
import StuDashboardExamRoutinemb from './../../../components/mobile/dashbordMobile/StuDashboardExamRoutinemb';
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb';
import FooterMobile from '../../../components/mobile/FooterMobile';

const StuDashExamRPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">
                    <StuDashERoutineComp/>

                </div>
            </div>
        </div>  
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="fourth"/>
           
            <StuDashboardExamRoutinemb/>
             <FooterMobile/>

          </div>
        </div> 
    </>
  )
}

export default StuDashExamRPage