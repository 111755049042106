import React from 'react'
import '../../sassFiles/errorpages/notFoundPage.scss'
import { Link } from 'react-router-dom'
const InternalServerError500 = () => {
  return (
    <>
        <div className="desktop">
        <div className="errorpage500wrap">
           
            <img src="./assets/images/errorPages/404/500.png" alt="" />
           

           
            <Link to="/">Go To Homepage</Link>
        </div>

    </div>
    </>
  )
}

export default InternalServerError500