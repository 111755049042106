import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import axios from 'axios';
const AddressCards = () => {
  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [campusId, setcampusId] = useState("");
  const [title, settitle] = useState("");
  const [address, setaddress] = useState("");
  const [mobileNum, setmobileNum] = useState("");
  const [email, setemail] = useState("");
  const [route, setroute] = useState("");
  const [path, setpath] = useState("");
  const [myresponse, setmyResponse] = useState([]);
  useEffect(() => {

    const fetchUpComingNotice = async () => {
      try {
        const myresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/TranspotService", {},
          headers
        );
        setmyResponse(myresponse.data);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchUpComingNotice();
    console.log(myresponse);
  }, []);
  return (
    <>
      <div
        className="addresscards"
        data-aos-delay="300"
        data-aos="fade-up"
        data-aos-duration="1000"
      >
        <div className="myContainer">
          <Row className="addresscardsrow">
            {myresponse.map((item, index) => {
              return (
                <Col lg={6}>
                  <div className="addresscard hoverscale">
                    <div className="addresscardcontent">
                      {item.campusId == 2 ? (
                        <h3>{item.title} </h3>
                      ) : (
                        <h3>{item.route} </h3>
                      )}
                      {
                        item.campusId == 2 ? (
                          <p>{item.address} {item.mobileNum} {item.email}</p>
                        ) : (
                          <p>BSS WARI : {item.path} </p>
                        )
                      }
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
}

export default AddressCards