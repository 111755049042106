export const roleApi = {
    heading:[
        {
            head:"Role ID"
        },
        {
            head:"Role Name"
        },
        {
            head:"Status"
        },
        {
            head:"Redirect URL"
        },
        {
            head:"Actions"
        },
    ],
    data:[
        {
            roleid:1,
            rolename:"",
            status:"",
            redirecturl:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            roleid:2,
            rolename:"",
            status:"",
            redirecturl:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },

    ]
}