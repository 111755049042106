import React, { useEffect, useState, ueRef } from 'react'
import { Container, Col, Row } from 'react-bootstrap';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import $ from 'jquery';

import axios from 'axios';
import { elements } from 'chart.js';

const Banner = () => {

  var settings = {
    dots: false,
    infinite: true,
    speed: 1200,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: 5000,
    loop: true,
    pauseOnHover: false,
  };
  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };

  const [NumberOfBranch, setNumberOfBranch] = useState("");
  const [NumOfStudent, setNumOfStudent] = useState("");
  const [NumOfActiveStudent, setNumOfActiveStudent] = useState("");
  const [NumOfTeacherAndStaff, setNumOfTeacherAndStaff] = useState("");
  const [myresponse, setmyResponse] = useState([]);
  const [banneR_ID, setbanneR_ID] = useState("");
  const [banneR_TITLE, setbanneR_TITLE] = useState("");
  const [banneR_DESCRIPTION, setbanneR_DESCRIPTION] = useState("");
  const [position, setposition] = useState("");
  const [imagE_URL, setimagE_URL] = useState("");
  const [BannerResponse, setBannerResponse] = useState([]);

  useEffect(() => {
    const fetchUpBannerSmallBoxInfo = async () => {
      try {
        const myresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Home/HomePageBanner", {},
          headers
        );
        setmyResponse(myresponse.data);
        console.log(myresponse)
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchUpBannerSmallBoxInfo();
  }, []);

  useEffect(() => {
    const GetBannerInfo = async () => {
      try {
        const myresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Home/HomePageSlide", {},
          headers
        );
        setBannerResponse(myresponse.data);
        console.log(myresponse)
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    GetBannerInfo();
  }, []);

  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();
  return (
    <>
      <div className="myContainer">
        <div className="mybanner" id="bntxinner">
          <Row>
            <Col lg={8} className="bannertext">
            
              <Slider
                {...settings}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}>
                  
                  {BannerResponse.map((item, key)=> {
                    return(
                      <div className="bntxinner" data-aos-delay="400" data-aos="fade-right" data-aos-duration="2000">
                      <h3>{item.banneR_TITLE}</h3>
                      <p
                        className="bannerdetailsp"
                        style={{ marginBottom: "36px" }}
                      >
                      {item.banneR_DESCRIPTION}
                    </p>
                </div>
                    );
                    
                  })}
              </Slider>

              <div className="bannersmallboxwrapper">
                {myresponse.map((element, index) => {
                  return (
                    <Slider
                      {...settings}
                      asNavFor={nav2}
                      ref={(slider1) => setNav1(slider1)}
                    >
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter "
                            >
                              <div
                                className="bannersmallbox"
                                data-aos-delay="400"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner2.png"
                                  alt=""
                                />
                                <p>
                                  <span>18+</span> Years Since Founded in 2004
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox gap"
                                data-aos-delay="500"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner1.png"
                                  alt=""
                                />
                                <p>
                                  <span>{element.numOfStudent}+</span> Student
                                  from all over Dhaka city
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox gap2"
                                data-aos-delay="600"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner3.png"
                                  alt=""
                                />
                                <p>
                                  <span>{element.numOfTeacherAndStaff}+</span>{" "}
                                  Teachers & Staff providing excellence
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox"
                                data-aos-delay="400"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner2.png"
                                  alt=""
                                />
                                <p>
                                  <span>{element.numberOfBranch}</span> Branches
                                  in Dhaka City
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox gap"
                                data-aos-delay="500"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner1.png"
                                  alt=""
                                />
                                <p>
                                  <span>{element.numOfStudent}+</span> Student
                                  from all over the country
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox gap2"
                                data-aos-delay="600"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner3.png"
                                  alt=""
                                />
                                <p>
                                  <span>{element.numOfTeacherAndStaff}+</span>{" "}
                                  Teachers & Staff providing excellence
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                          <Row>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox"
                                data-aos-delay="400"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner2.png"
                                  alt=""
                                />
                                <p>
                                  <span>18+</span> Years Since Founded in 2004
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox gap"
                                data-aos-delay="500"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner1.png"
                                  alt=""
                                />
                                <p>
                                  <span>{element.numOfActiveStudent}+</span>{" "}
                                  Active Student from all over Dhaka city
                                </p>
                              </div>
                            </Col>
                            <Col
                              lg={4}
                              xs={12}
                              className="bannersmallboxcenter"
                            >
                              <div
                                className="bannersmallbox gap2"
                                data-aos-delay="600"
                                data-aos="fade-up"
                                data-aos-duration="2000"
                              >
                                <img
                                  src="./assets/images/smallvectorbanner3.png"
                                  alt=""
                                />
                                <p>
                                  <span>{element.numOfTeacherAndStaff}+</span>{" "}
                                  Teachers & Staff providing excellence
                                </p>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Slider>
                  );
                })}
              </div>
            </Col>
            <Col lg={4} className="main_image">
              <Slider
                {...settings}
                asNavFor={nav2}
                ref={(slider1) => setNav1(slider1)}
              >
                {BannerResponse.map((item, key)=> {
                    return(
                <img
                  src={"./assets/images/SliderImage/" + item.imagE_URL}
                  alt="main_image"
                />
                );
                    
                  })}
              </Slider>
            </Col>
            
          
          </Row>

          <div className="bannerbgimg">
            <img src="./assets/images/BG_Logo_full.png" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Banner