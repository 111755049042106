import React,{useState} from 'react'
import '../../sassFiles/mobile/sassPages/trackAdmissionPagemb.scss'
import CommonButton from '../subComponents/CommonButton'
import { trackAdmission } from '../../utils/TrackAdmissionApi'
import SmallHeadingMsg from '../subComponents/SmallHeadingMsg'
import {Row,Col} from 'react-bootstrap'
const TrackAdmissioncompmb = () => {

    const [searchkeyword,setsearchkeyword] = useState("")
    const [showdata,setshowdata] = useState(false)
    const handletracksearchmb=()=>{
        console.log(searchkeyword)
        if(searchkeyword==trackAdmission.id){
            setshowdata(true)
        }
        else{
            setshowdata(false)
        }
    }

    const handleMakePayment=()=>{

    }
    const handleDownloadToken=()=>{

    }
   
  return (
    <>
        <div className="mobilecontainer">
            <div className="heading">
                <h4>
                    Track Admission
                </h4>
                <p>
                    Track Your Application Details
                </p>
            </div>
            <div className="searchpart">
                <h5>Search Track Number</h5>

                <input type="text" placeholder='Enter Track Number' value={searchkeyword} onChange={(e)=>setsearchkeyword(e.target.value)}/>
                <div className="mybutton">
                <CommonButton myclick={handletracksearchmb} buttonTitle="Search"/>

                </div>
                
                {
                    showdata?
                    <div className="populateinfo"  data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
                    <div className="topbox">
                        <SmallHeadingMsg smalltitle="Tracking Information"/>

                        <div className="wrapperwrap">
                            <Row>
                                <Col xs={12}>
                                <div className="leftimg">
                                <img  src="./assets/images/trackAdmissionPage/idpic.png" alt="" />
                            </div>
                                </Col>
                            </Row>
                            
                            <div className="rightcontent">
                                <div className="item">
                                    <div className="left">
                                        <div className="flexwrap">
                                            
                                        <h5>Tracking No</h5>
                                        <h5>:</h5>
                                        </div>

                                    </div>
                                    <div className="right">
                                        <h5>{trackAdmission.id}</h5>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="left">
                                        <div className="flexwrap">
                                            
                                        <h5>Name</h5>
                                        <h5>:</h5>
                                        </div>

                                    </div>
                                    <div className="right">
                                        <h5>{trackAdmission.name}</h5>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="left">
                                        <div className="flexwrap">
                                            
                                        <h5>Session</h5>
                                        <h5>:</h5>
                                        </div>

                                    </div>
                                    <div className="right">
                                        <h5>{trackAdmission.session}</h5>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="left">
                                        <div className="flexwrap">
                                            
                                        <h5>Class</h5>
                                        <h5>:</h5>
                                        </div>

                                    </div>
                                    <div className="right">
                                        <h5>{trackAdmission.class}</h5>
                                    </div>
                                </div>
                                <div className="item">
                                    <div className="left">
                                        <div className="flexwrap">
                                            
                                        <h5>Apply Date</h5>
                                        <h5>:</h5>
                                        </div>

                                    </div>
                                    <div className="right">
                                        <h5>{trackAdmission.applydate}</h5>
                                    </div>
                                </div>
                                <div className="tokenbutton">
                                    <button onClick={handleDownloadToken}>Download Token</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bottomboxes">
                        <Row>
                            <Col xs={12}>
                            <div className="bottomleft boxcommon">
                        <h4>Status</h4>
                        {
                            trackAdmission.status=="Pending"?
                                <div className="pillsmall2">
                                    <p>{trackAdmission.status}</p>
                                </div>
                            :
                            <div className="pillsmall3">
                                <p>{trackAdmission.status}</p>
                            </div>
                        }
                        
                        </div>
                            </Col>
                            <Col xs={12}>
                            <div className="bottomright boxcommon">
                        <h4>Admission Payment</h4>
                        {
                            trackAdmission.admissionpayment=="Pending"?
                                <div className="pillsmall2">
                                    <p>{trackAdmission.admissionpayment}</p>
                                </div>
                            :
                            <div className="pillsmall3">
                                <p>{trackAdmission.admissionpayment}</p>
                            </div>
                            
                        }
                        <div className="tokenbutton">
                            <button onClick={handleMakePayment}>Make Payment</button>
                        </div>

                        </div>
                            </Col>
                        </Row>
                        
                        
                    </div>
                </div>  
                    :
                    ""
                }
                    
            
            </div>
        </div>
    </>
  )
}

export default TrackAdmissioncompmb