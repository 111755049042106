import React,{useState,useEffect} from 'react'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import {Row,Col,Modal,Form} from 'react-bootstrap'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { optionssession,optionsClass,optionssemester, optionsStatus } from '../../Helper'
import { AiOutlinePlus } from 'react-icons/ai'
import { flexValueTable } from '../../../utils/AdminDashApi/FlexValue'
import ReactPaginate from 'react-paginate';
import { roleApi } from '../../../utils/AdminDashApi/RoleApi'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
const RoleComp = () => {
    const [drpsectionselect,setdrpsection] = useState("")
    const [drpclassselect,setdrpclassselect] = useState("")
    const [drpsemesterselect,setdrpsemesterselect] = useState("")
    const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage,setitemsPerPage] = useState(10);
  const [paginatenumselected,setpaginatenumselected] = useState(0)
    const drpfuncsession=(mysectionval)=>{
        setdrpsection(mysectionval)
    }
    const drpfunctionclass=(mysectionval)=>{
        setdrpclassselect(mysectionval)
    }
    const drpfunctionsemester=(mysectionval)=>{
        setdrpsemesterselect(mysectionval)
    }
   
    const handleProcess=()=>{
        // Process Code
    }
    const [show,setShow] = useState("")
    const [activefrom,setactivefrom] = useState("")
    const [show2, setShow2] = useState(false);
    const handleClose = () => setShow2(false);
    const drpfuncshow=(myshow)=>{
      setShow(myshow)
    }
    const handleShow=()=>{
      setShow2(true)
    }
    const [newArray,setNewArray] = useState({})
    const [redirectval,setredirectval] = useState("")
    const [nameval,setnameval] = useState("")
    const [redirecterr,setredirecterr] = useState("")
    const [nameerr,setnameerr] = useState("")
    const [statusvalueerr,setstatusvalueerr] = useState("")

    const [redirecterrborder,setredirecterrborder] = useState(false)
    const [nameerrborder,setnameerrborder] = useState(false)

    const handleredirectval =(e)=>{
      setredirectval(e.target.value)
    }
    const handlenameval =(e)=>{
      setnameval(e.target.value)
    }
    const handleSavemodaledit=(e)=>{
      e.preventDefault()
      if(redirectval==""){
        setredirecterr("value required")
        setredirecterrborder(true)
      }else{
        setredirecterr("")
        setredirecterrborder(false)
      }
      if(nameval==""){
        setnameerr("value required")
        setnameerrborder(true)
      }else{
        setnameerr("")
        setnameerrborder(false)
      }
      if(statusvalue==""){
        setstatusvalueerr("err")
      }else{
        setstatusvalueerr("")
      }
      if(nameval!=="" && redirectval!=="" && statusvalue!==""){
        setShow2(false)

        const updatedArray = [...currentItems];

        updatedArray[saveid - 1] = {
          roleid:saveid,
          rolename:nameval,
          status:statusvalue,
          redirecturl:redirectval,
          action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        }

        setCurrentItems(updatedArray)
        
        setnameval("")
        setredirectval("")
        setstatusvalue("")
        setShow3(false)
        console.log(statusvalue)
      }
    }

    const handleSavemodal=(e)=>{
      e.preventDefault()
      if(redirectval==""){
        setredirecterr("value required")
        setredirecterrborder(true)
      }else{
        setredirecterr("")
        setredirecterrborder(false)
      }
      if(nameval==""){
        setnameerr("value required")
        setnameerrborder(true)
      }else{
        setnameerr("")
        setnameerrborder(false)
      }
      if(statusvalue==""){
        setstatusvalueerr("err")
      }else{
        setstatusvalueerr("")
      }
      if(nameval!=="" && redirectval!=="" && statusvalue!==""){
        setShow2(false)
        setCurrentItems([...currentItems,{
          roleid:currentItems.length + 1,
          rolename:nameval,
          status:statusvalue,
          redirecturl:redirectval,
          action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
     
        }])
       
        setnameval("")
        setredirectval("")
        setstatusvalue("")
        console.log(statusvalue)
      }
      console.log(redirectval)
    }

      const optionsShowt = [
        {
          id:1,
          opt:1
        },
        {
          id:2,
          opt:2
        },
        {
          id:3,
          opt:3
        },
      ]
      const handlesearch =()=>{
    
        setactivefrom("")
      
    }
    var [localCurrArr,setlocalCurrArr] = useState([])
    useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(roleApi.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(roleApi.data.length / itemsPerPage));
        
        
      }, [itemOffset, itemsPerPage]);
    
      // Invoke when user click to request another page.
      const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % roleApi.data.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setpaginatenumselected(event.selected);
        setItemOffset(newOffset);
      };
      const [show3, setShow3] = useState(false);
      const [saveid,setsaveid] = useState(null)
      const handleShow3 = (e,myid) => {
        setShow3(true)
        setsaveid(myid)
      }
      const handleClose3 = () => setShow3(false);
      const [statusvalue,setstatusvalue] = useState("")

      const drpfuncstatus=(myid,mystatus)=>{
        setstatusvalue(mystatus)
      }
  return (
    <>
          <div className="d-flex ">

<SidebarOfAdash/>
<div className="contentpartwidthwrapper">
  <HeaderofAdash head="Role"/>
  <div className="contentofadmindash contentflexvalue">
     <Row>
        <Col lg={3} className='m-auto'>
        <button className='cmbutton' onClick={handleShow}> <AiOutlinePlus/> Add New Role</button>
        </Col>
     </Row>
     <Row style={{marginTop:"20px"}}>
                <Col className="m-auto" lg={4}>
                  <Row>
                    <Col lg={3}>
                    <div className="basicinput">
                      <p>Show</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 func={drpfuncshow} myplaceholder="Select" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsShowt}/>
                      </div>
                      </div>
                    </Col>
                    <Col lg={9}>
                            <div className="basicinput">
                              <p>Search</p>
                              <div className="wrap">
                              <input type="text" placeholder="Search" />
                              <img onClick={handlesearch} src="./assets/images/dashboards/adminDashboard/flexvalue/search.png" alt="" />

                              </div>
                            </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">
                  <div className="flexvaluetable">
                    <table>
                      <thead>
                        <tr>
                            {
                              roleApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItems?

                          currentItems.map((item,key)=>{
                            return(
                              <tr>
                                <td>{item.roleid}</td>
                                <td>{item.rolename}</td>
                                <td>{item.status}</td>
                                <td>{item.redirecturl}</td>
                                <td>
                                  <div className="img text-center">
                                    <img onClick={(e)=>handleShow3(e,item.roleid)} src={item.action} alt="" />  
                                  </div>  
                                </td>
                              </tr>
                            )
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">

                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffset+1} to {itemOffset+10} of {roleApi.data.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                </Col>
               
              </Row>

              <Modal dialogClassName="mymodalrole" show={show2} onHide={handleClose} backdrop="static"
              keyboard={false}>
                <Form onSubmit={handleSavemodal}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Flex Value</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  

                  <Row>
                    <Col lg={6}>
                        <div className="basicinput">
                          <p>name</p>
                          <div className="inputgap">
                          <input type="text" style={nameerrborder? {border:"1px solid rgb(237, 28, 36)"}:{border:"1px solid rgba(0,0,0,0.2)"}} onChange={handlenameval} value={nameval} />
                          <label>{nameerr}</label>
                          </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Status</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 err={statusvalueerr=="err"?"err":""} func={drpfuncstatus} myplaceholder="Select Type" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsStatus}/>
                      </div>
                      </div>
                    </Col>
                    
                    <Col lg={6}>
                    <div className="basicinput">
                          <p>Redirect URL</p>
                          <div className="inputgap">
                          <input type="text" style={redirecterrborder? {border:"1px solid rgb(237, 28, 36)"}:{border:"1px solid rgba(0,0,0,0.2)"}} onChange={handleredirectval} value={redirectval} />
                          <label>{redirecterr}</label>
                            
                          </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                    
                    </Col>
                    <Col lg={6}>
                 
                </Col>
                <Col lg={6}>
                  
                </Col>
                  </Row>
                
                </Modal.Body>
                <Modal.Footer className="m-auto" style={{borderTop:"0"}}>
                  <button variant="secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button type="submit" variant="primary">
                    Save
                  </button>
                </Modal.Footer>
                </Form>
              </Modal>
              <Modal dialogClassName="mymodal" show={show3} onHide={handleClose3} backdrop="static"
              keyboard={false}>
                <Form onSubmit={handleSavemodaledit}>
                <Modal.Header closeButton>
                  <Modal.Title className="ms-auto">Role Edit {saveid}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  

                  <Row>
                    <Col lg={6}>
                        <div className="basicinput">
                          <p>name</p>
                          <div className="inputgap">
                          <input type="text" style={nameerrborder? {border:"1px solid rgb(237, 28, 36)"}:{border:"1px solid rgba(0,0,0,0.2)"}} onChange={handlenameval} value={nameval} />
                          <label>{nameerr}</label>
                          </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                    <div className="basicinput">
                      <p>Status</p>
                      <div className="dropdownwrapper">
                        
                          <Dropdown2 err={statusvalueerr=="err"?"err":""} func={drpfuncstatus} myplaceholder="Select Type" fontsize="16" fontfamily="'Poppins', sans-serif"  options={optionsStatus}/>
                      </div>
                      </div>
                    </Col>
                    
                    <Col lg={6}>
                    <div className="basicinput">
                          <p>Redirect URL</p>
                          <div className="inputgap">
                          <input type="text" style={redirecterrborder? {border:"1px solid rgb(237, 28, 36)"}:{border:"1px solid rgba(0,0,0,0.2)"}} onChange={handleredirectval} value={redirectval} />
                          <label>{redirecterr}</label>
                            
                          </div>
                        </div>
                    </Col>
                    <Col lg={6}>
                    
                    </Col>
                    <Col lg={6}>
                 
                </Col>
                <Col lg={6}>
                  
                </Col>
                  </Row>
                
                </Modal.Body>
                <Modal.Footer className="m-auto" style={{borderTop:"0"}}>
                  <button variant="secondary" onClick={handleClose}>
                    Close
                  </button>
                  <button type="submit" variant="primary">
                    Save
                  </button>
                </Modal.Footer>
                </Form>
              </Modal>

  </div>

</div>
</div>
    </>
  )
}

export default RoleComp