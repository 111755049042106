import React from 'react'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import {Row,Col} from 'react-bootstrap'
import {AiOutlinePlus} from 'react-icons/ai'
const AdminDashComp = () => {
  return (
    <>
       <div className="d-flex ">

      <SidebarOfAdash/>
      <div className="contentpartwidthwrapper">
        <HeaderofAdash head="Home"/>
        <div className="contentofadmindash contentflexvalue">
           ami dahsboard
        </div>

      </div>
      </div>
    </>
  )
}

export default AdminDashComp