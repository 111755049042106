import React,{useState,useEffect} from 'react'
import '../../../sassFiles/sassPages/dashboards/srudentDashboards.scss'
import StuDocumentComp from '../../../components/dashboardComponents/studentDashboard/StuDocumentComp'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardDocumenttab from '../../../components/mobile/dashbordMobile/StuDashboardDocumenttab'

const StuDashDocumentPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
    <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">                
                    <StuDocumentComp/>
                    
                </div>
            </div>
        </div> 
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="second"/>
           
            <StuDashboardDocumenttab/>
             

          </div>
        </div> 
    </>
  )
}

export default StuDashDocumentPage