import { SET_VARIABLE,SET_OBJECT } from "../Action/Action";

const initialState = {
    variable: 20,
    obj:{
      
    }
  };
  
export const Rootreducer = (state = initialState, action) => {
    switch (action.type) {
      case SET_VARIABLE:
        return {
          ...state,
        //   variable: action.payload + state,
          variable: state.variable+1,
        };
      case SET_OBJECT:
        return {
         ...state,
        //   variable: action.payload + state,
          obj: [...action.payload],
          // obj: action.payload
        };
      default:
        return state;
    }
  };