import React,{useState} from 'react'
import HeaderOfTDashComp from '../teacherDashboard/subComponents/HeaderOfTDashComp'
import { headerpropst } from '../../../utils/DashboardApi/TeacherDashboardApi'
import NotificationboxComp from '../commonSybcomponent/NotificationboxComp'
import {Row,Col,Nav,Tab,Button} from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

import { AiOutlineArrowLeft } from 'react-icons/ai'
import { helpandsupporttabs, notificationstuboxApi } from '../../../utils/DashboardApi/StudentDashboardApi'

import SidebarofTdash from './subComponents/SidebarofTdash'
const TeaDashHelpSComp = () => {
    const navigate = useNavigate()
    const [shownotificationbox,setshownotificationbox] = useState(false)
      function funcnotification(){
        
        setshownotificationbox(!shownotificationbox);
      }
      const handlegoBack=()=>{
        navigate("/teacherdashboard/profile")
      }
      const [dependentdatashow,setdependentdatashow] = useState("")
    const handleOpenNewTicket=()=>{
        setdependentdatashow("OpenForm")
        console.log("yes man")
    }
  return (
    <>
         <div className="stu_dash_mainWrap">
        <SidebarofTdash/>
        <div className="rightpartcontentwrap">
            <HeaderOfTDashComp func={funcnotification} heading="Exam Routine" myprops={headerpropst}/>
            {
            shownotificationbox == true ?
            <NotificationboxComp api={notificationstuboxApi}/>
            :
            ""
        }
            <div className="tabPane">
           
            <div className="tabpaneheightadjust tabpanerightattandancetab studenthelpsupport">
                    <div className="topbar">
                        <div className="arrowdiv">
                            <AiOutlineArrowLeft onClick={handlegoBack}/>
                        </div>
                        <div className="searchbox">
                            <input type="text" placeholder="Search Ticket"/>
                            <img src="./assets/images/dashboards/studentDashboard/profileTab/helpSupport/searchicon.png" alt="searchicon.png" />
                        </div>
                    </div>

                    <div className="bottompartbox">
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <Row>
                            <Col sm={12}>
                            <Nav variant="pills" className="flex-row floatingtabs">
                                <Nav.Item>
                                <Nav.Link eventKey="first">Open Tickets</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                <Nav.Link eventKey="second">resolved</Nav.Link>
                                </Nav.Item>
                            </Nav>
                            </Col>
                            <Col sm={12}>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    
                                    {
                                      
                                        dependentdatashow==""?

                                                <div className="tabpaneboxopenticket">

                                                    <img src="./assets/images/dashboards/studentDashboard/profileTab/helpSupport/heroticket.png" alt="heroticket.png" />
                                                    <p style={{marginTop:"-15px"}}>There are no tickets to</p>
                                                    <p>display in this tab</p>
                                                    <Button onClick={handleOpenNewTicket}>Open a new Ticket</Button>
                                                </div>
                                            
                                       :
                                       <div className="tabpaneboxopenticket">
                                            <div className="formbox">
                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="basicinputfield">
                                                            <p>Name*</p>
                                                            <input type="text" placeholder="Md Jisan Khan"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={6}>
                                                        <div className="basicinputfield">
                                                            <p>ID*</p>
                                                            <input type="text" placeholder="1705032108"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="basicinputfield">
                                                            <p>Subject*</p>
                                                            <input type="text" placeholder="Subject"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12}>
                                                        <div className="basicinputfield">
                                                            <p>Message*</p>
                                                            <textarea className="mytextarea" placeholder="Message"></textarea>
                                                        </div>
                                                    </Col>
                                                    <Col lg={5} className="m-auto d-flex justify-between basicinputfield">
                                                        <Button>Submit</Button>
                                                        <Button>Reset</Button>
                                                    </Col>
                                                </Row>
                                                
                                            </div>

                                       </div>
                                    }
                                    
                                  
                                </Tab.Pane>
                                <Tab.Pane eventKey="second" className="resolvedtablediv">
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    helpandsupporttabs.header.map((item,key)=>{
                                                        return(
                                                            <th key={key}>
                                                                {item.head}
                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                helpandsupporttabs.tableData.map((item,key)=>{
                                                    return(
                                                        <tr>
                                                            <td>{item.reference}</td>
                                                            <td>{item.subject}</td>
                                                            <td>{item.datecreated}</td>
                                                            <td>{item.lastaction}</td>
                                                            <td>{item.resolvedby}</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <Button>Open a new Ticket</Button>
                                </Tab.Pane>
                            </Tab.Content>
                            </Col>
                        </Row>
                        </Tab.Container>  
                        
                    
                    
                
                </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default TeaDashHelpSComp