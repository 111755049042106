import React from 'react'
import TeadSalaryinfoComp from '../../../components/dashboardComponents/teacherDashboard/TeadSalaryinfoComp'
import TeaDashSidebarMB from '../../../components/mobile/dashbordMobile/TeacherDashboard/SubComponent/TeaDashSidebarMB'
import TeaSalaryDashTab from '../../../components/mobile/dashbordMobile/TeacherDashboard/TeaSalaryDashTab'
import FooterMobile from '../../../components/mobile/FooterMobile'

const TeadSalaryinfoPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">                
                    <TeadSalaryinfoComp/>
                    
                </div>
            </div>
        </div> 
        <div className="mobile">
        <div className="teacherdashboardmobilewrapper">
            
            <TeaDashSidebarMB/>
            <TeaSalaryDashTab/>
            <FooterMobile/>

        </div>
        </div> 
    </>
  )
}

export default TeadSalaryinfoPage