import React from 'react'
import StuDashProfileComp from '../../../components/dashboardComponents/studentDashboard/StuDashProfileComp'
import StuDashboardProfilemb from './../../../components/mobile/dashbordMobile/StuDashboardProfilemb';
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb';
import FooterMobile from '../../../components/mobile/FooterMobile';

const StuDashProfilePage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
           <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">
                    <StuDashProfileComp/>

                </div>
            </div>
        </div> 
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="ninth"/>
           
            <StuDashboardProfilemb/>
             
            <FooterMobile/>  
          </div>
        </div> 
    </>
  )
}

export default StuDashProfilePage