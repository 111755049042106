import React,{useState} from 'react'
import { attendanceData, headerprops } from '../../../utils/DashboardApi/StudentDashboardApi'
import HeaderOfstuDashComp from './subComponents/HeaderOfstuDashComp'
import SidebarOfsDash from './subComponents/SidebarOfsDash'
import {Row,Col,Nav,Tab,Tabs, Button} from 'react-bootstrap'
import Dropdown from 'react-dropdown';
import {BsChevronDown} from 'react-icons/bs'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import PillSmall from '../../subComponents/CustomSubComponents/PillSmall'
const StuDashAttComp = () => {
    const handleReqCorrection=()=>{

    }
    const Optionroutinetype = [
        'week','Month','Year'
      ];
      const defaultOptionroutinetype = Optionroutinetype[0];
      const [drproutinetype,setdrproutinetype] = useState("")
    
    const dropdownvalueroutinetype=(e)=>{
        // console.log(e.value)
        setdrproutinetype(e.value)
        
      }
      const [startDate,setStartDate] = useState("")
      const handleStartDate=(e)=>{
        setStartDate(e.target.value)
        console.log(startDate)
      }
      const [endDate,setEndtDate] = useState("")
      const handleEndDate=(e)=>{
        setEndtDate(e.target.value)
        console.log(endDate)
      }
      const optionme = [
        {
            opt:'Select Section'
        },
        {
            opt:'First Semester'
        }
    ]
  return (
    <>
        <div className="stu_dash_mainWrap">
        <SidebarOfsDash activeID="2"/>
        <div className="rightpartcontentwrap">
            <HeaderOfstuDashComp heading="Attendance" myprops={headerprops}/>
            <div className="tabPane">
            <div className="tabpaneheightadjust tabpanerightattandancetab">
                    <div className="atttabbox">
                    <Row className='firstrow'>
                            <Col className="d-flex align-items-end">
                            <div className="dropdownwrapper w-100" id="routinedrp">
                                <Dropdown open={true} className='filterdropone'  options={Optionroutinetype} onChange={(e)=>dropdownvalueroutinetype(e)} value={defaultOptionroutinetype} placeholder="Select an option" />
                                <BsChevronDown/>
                            </div>
                            </Col>
                            <Col className="d-flex align-items-end">
                                {/* <div className="dropdownwrapper">
                                    <Dropdown className='filterdropone' options={optionsemester} onChange={(e)=>dropdownvaluesection(e)} value={defaultOptionsemester} placeholder="Select an option" />
                                    <BsChevronDown/>
                                </div>                             */}
                               <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                            </Col>
                            <Col  className="d-flex align-items-end">
                            
                                <input type="date" placeholder='start date' onChange={(e)=>handleStartDate(e)} />
                           
                            </Col>
                            <Col className="d-flex align-items-end">
                            
                                <input type="date" placeholder='end date' onChange={(e)=>handleEndDate(e)} />
                            </Col>
                            <Col className="d-flex align-items-end">
                                <Button className='reqcorrectionbutton' onClick={handleReqCorrection}>Request for correction</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <div className="buttonview">
                                    <div className="flexwrap">
                                        <div className="totalpresentpill">
                                            <p>Total Present : 00</p>
                                        </div>
                                        <div className="totalpresentpill totalabsentpill">
                                            <p>Total Absent : 00</p>
                                        </div>
                                        <div className="totalpresentpill totallatepill">
                                            <p>Total Late : 00</p>
                                        </div>
                                        <div className="totalpresentpill totalearlyleave">
                                            <p>Total Present this : 00</p>
                                        </div>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={12}>
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                attendanceData.header.map((item,key)=>{
                                                    return (
                                                        <th key={key}>
                                                            {item.headeritem}
                                                            {
                                                                item.img?
                                                                <img src={item.img} alt="" />
                                                                
                                                                :
                                                                ""
                                                            }
                                                        </th>
                                                    )
                                                })
                                            }                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            attendanceData.innerTableData.map((item,key)=>{
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {item.SL}
                                                        </td>
                                                        <td>
                                                            {item.date}
                                                        </td>
                                                        <td>
                                                            {item.schooltime}
                                                        </td>
                                                        <td>
                                                            {item.intime}
                                                        </td>
                                                        <td>
                                                            {item.outtime}
                                                        </td>
                                                        
                                                        
                                                        <td>
                                                            
                                                            {
                                                            item.type=="Present"?
                                                            <PillSmall class={item.type} fontsize="12px" text={item.type} bg="rgba(0, 202, 85, 0.05)" textcolor="rgba(0,202,85, 1)" />
                                                            :
                                                            
                                                            item.type=="Late"?
                                                            <PillSmall class={item.type} fontsize="12px" text={item.type} bg=" rgba(255, 173, 0, 0.05)" textcolor="rgba(255, 173, 0, 1)" />
                                                            :
                                                            item.type=="Early Leave"?
                                                            <PillSmall class="Earlyleave" fontsize="12px" text={item.type} bg="rgba(100, 158, 255, 0.05)" textcolor="rgba(100, 158, 255, 1)" />
                                                            :
                                                            ""    
                                                        }
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                    </div>
                </div>
       
            </div>
        </div>
    </div>
    </>
  )
}

export default StuDashAttComp