import React from 'react'
import ClassSubjectForAdmComp from '../../components/adminDashboard/desktop/ClassSubjectForAdmComp'

const ClassSubforAdPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="overlayfordrpdwn"></div>
                <ClassSubjectForAdmComp/>
        </div>
    </>
  )
}

export default ClassSubforAdPage