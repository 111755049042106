import React from 'react'
import TeadLeaveformComp from '../../../components/dashboardComponents/teacherDashboard/TeadLeaveformComp'
import TeaLeaveFormTab from '../../../components/mobile/dashbordMobile/TeacherDashboard/TeaLeaveFormTab'
import FooterMobile from '../../../components/mobile/FooterMobile'
import TeaDashSidebarMB from '../../../components/mobile/dashbordMobile/TeacherDashboard/SubComponent/TeaDashSidebarMB'

const TeadLeaveformPage = () => {
  return (
    <>
         <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">                
                    <TeadLeaveformComp/>
                    
                </div>
            </div>
        </div> 
        <div className="mobile">
        <div className="teacherdashboardmobilewrapper">
            <TeaDashSidebarMB/>
            <TeaLeaveFormTab/>
            <FooterMobile/>
            

        </div>
        </div> 
    </>
  )
}

export default TeadLeaveformPage