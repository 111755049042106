import React from 'react'
import SidebarofTdash from './subComponents/SidebarofTdash'
import HeaderOfTDashComp from './subComponents/HeaderOfTDashComp'
import { curriculumtabtabledata, headerpropst } from '../../../utils/DashboardApi/TeacherDashboardApi'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'

const TeadCurriculumComp = () => {
    const optionme = [
        {
            opt:'First Semester'
        },
        {
            opt:'Second Semester'
        }
    ]
  return (
    <>
        <div className="teadashcomp">
            <SidebarofTdash/>

            <div className="rightpartcontentwrap">
                <HeaderOfTDashComp heading="Curriculum Entry" myprops={headerpropst}/>

                <div className="tabPane">
                <div className="tabpaneheightadjust tabpanerightcurriculumtab">
                    <div className="dropdownwrappercurriculum">
                    <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                    <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                    <Dropdown2 fontsize="12" fontfamily="'Poppins', sans-serif" options={optionme}/>
                    </div>
                    <div className="curriculumtabtable">
                    <table>
                        <thead>
                            <tr>
                                {
                                    curriculumtabtabledata.heading.map((item,key)=>{
                                        return(
                                            <th key={key}>{item.head}</th>
                                        )
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                curriculumtabtabledata.tableData.map((item,key)=>{
                                    return(
                                        <tr key={key}>
                                            <td >{item.sl}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default TeadCurriculumComp