import React from 'react'
import LatestSliderNoticePage from './LatestSliderNoticePage'


const LatestPartMb = () => {
  return (
    <>
        
            <div className="latestpartNoticePagemb">
                
                <LatestSliderNoticePage  title="Notice" subtitle="Latest"/>
                
                
            </div>
        
    </>
  )
}

export default LatestPartMb