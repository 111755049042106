import React from 'react'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import {Row,Col,Button} from 'react-bootstrap'
import {AiOutlinePlus} from 'react-icons/ai'
import { attendanceData } from '../../../utils/DashboardApi/StudentDashboardApi'
import PillSmall from '../../subComponents/CustomSubComponents/PillSmall'
import Dropdown from 'react-dropdown';
import {BsChevronDown} from 'react-icons/bs'
import moment from 'moment'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { attendanceDataAdmin } from '../../../utils/DashboardApi/AdminDashboard/AdminDashboardApi'
import {AiOutlinePrinter} from 'react-icons/ai'
const AttSummDashComp = () => {
    const handleFilter=()=>{
        console.log(moment(5, 'M').format('MMMM'))
    }
   
    const months = []
    for(var i = 0; i < moment().month() ; i++){
        months.push({id: i + 1,opt:moment(i + 1, 'M').format('MMMM')},)
    }
    const optionme = months

    const handlePrint = ()=>{
        // PRINT FUNCTION
    }
  return (
    <>
         <div className="d-flex ">

<SidebarOfAdash/>
<div className="contentpartwidthwrapper">
  <HeaderofAdash head="Attendence Summary"/>
  <div className="contentofadmindash contentflexvalue">
     
    <Row className='firstrow justify-content-center'>
                            
        <Col lg={{ span: 2, offset: 4 }} className="d-flex align-items-end">
                                
            <Dropdown2 myplaceholder="Select Month" fontsize="16" fontfamily="'Poppins', sans-serif" options={optionme}/>
        </Col>
        
        <Col lg={2} className="d-flex align-items-end">
            <Button className='reqcorrectionbutton' onClick={handleFilter}>Filter</Button>
        </Col>
        <Col  lg={4} className="d-flex justify-content-end">
            <AiOutlinePrinter onClick={handlePrint} className="printicon"/>
        </Col>
    </Row>
     <div className="attTable">
     <Col lg={12}>
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                attendanceDataAdmin.header.map((item,key)=>{
                                                    return (
                                                        <th key={key}>
                                                            {item.headeritem}
                                                            {
                                                                item.img?
                                                                <img src={item.img} alt="" />
                                                                
                                                                :
                                                                ""
                                                            }
                                                        </th>
                                                    )
                                                })
                                            }                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            attendanceDataAdmin.innerTableData.map((item,key)=>{
                                                return (
                                                    <tr key={key}>
                                                        <td>
                                                            {item.SL}
                                                        </td>
                                                        <td>
                                                            {item.employeeid}
                                                        </td>
                                                        <td>
                                                            {item.name}
                                                        </td>
                                                        <td>
                                                            {item.designation}
                                                        </td>
                                                        <td>
                                                            {item.wd}
                                                        </td>
                                                        <td>
                                                            {item.late}
                                                        </td>
                                                        <td>
                                                            {item.earlyleave}
                                                        </td>
                                                        <td>
                                                            {item.leave}
                                                        </td>
                                                        <td>
                                                            {item.remarks}
                                                        </td>
                                                        
{/*                                                         
                                                        <td>
                                                            
                                                            {
                                                            item.type=="Present"?
                                                            <PillSmall class={item.type} fontsize="12px" text={item.type} bg="rgba(0, 202, 85, 0.05)" textcolor="rgba(0,202,85, 1)" />
                                                            :
                                                            
                                                            item.type=="Late"?
                                                            <PillSmall class={item.type} fontsize="12px" text={item.type} bg=" rgba(255, 173, 0, 0.05)" textcolor="rgba(255, 173, 0, 1)" />
                                                            :
                                                            item.type=="Early Leave"?
                                                            <PillSmall class="Earlyleave" fontsize="12px" text={item.type} bg="rgba(100, 158, 255, 0.05)" textcolor="rgba(100, 158, 255, 1)" />
                                                            :
                                                            ""    
                                                        }
                                                        </td> */}
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Col>
     </div>

     {
        attendanceDataAdmin.status==false?

            <>
            <div className="pendingdiv d-flex justify-content-center">
                <div className="pendingsmdiv">
                    Pending
                </div>
            </div>
            <div className="apprbuttons d-flex justify-content-center">
                <div className="wrap d-flex">
                    <Button>Approve & Print</Button>
                    <Button>Approve</Button>
                </div>

            </div>
            </>
          
        :
        <>
             <div className="pendingdiv d-flex justify-content-center">
                <div className="pendingsmdiv apprvd">
                    Approved
                </div>
            </div>
        </>
     }

  </div>

</div>
</div>
    </>
  )
}

export default AttSummDashComp