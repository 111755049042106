import React from 'react'

const attendanceDataAdmin = {
    status:false,
    header:[
        {
            headeritem:"SL"
        },
        {
            headeritem:"EmployeeID"
        },
        {
            headeritem:"Name"
        },
        {
            headeritem:"Designation"
        },
        {
            headeritem:"Wd"
        },
        {
            headeritem:"Late"
        },
        {
            headeritem:"Early Leave"
        },
        {
            headeritem:"Leave"
        },
        {
            headeritem:"Remarks"
        },
    ],
    innerTableData: [
        
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
            {
                SL:"01",
                employeeid:"1107010173",
                name:"AYAZ AHMED",
                designation:"IT SUPPORT OFFICER",
                wd:26,                                
                late:2,
                earlyleave:0,
                leave:0,
                remarks:""
            },
           
            
           
        
    ]
}

const sidebarApiadmin = [
    {
        id:1,
        name:"Home",
        link:"/studashboard",
        imgurl:"./assets/images/icons/tinysquaresicon.png",
        drpdwn:[]
               
    },
    {
        id:2,
        name:"Flex",
        link:"/studashboard/document",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[
            {
                item:"Flex Value",
                link:"/studashboard/classroutine"
            },
            {
                item:"Flex Value Set",
                link:"/studashboard/examroutine"
            }
        ] 
              
    },
    {
        id:3,
        name:"Manage Online Admission",
        link:"/manageonlineadmission",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[]       
    },
    {
        id:4,
        name:"Routine",
        link:"/studashboard/routine",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[
            {
                item:"Class Routine",
                link:"/studashboard/classroutine"
            },
            {
                item:"Exam Routine",
                link:"/studashboard/examroutine"
            }
        ]      
    },
    
    {
        id:5,
        name:"Attendance",
        link:"/studashboard/attendance",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[]       
    },
    {
        id:6,
        name:"Bill Info",
        link:"/studashboard/billinfo",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[]       
    },
    {
        id:7,
        name:"Result",
        link:"/studashboard/result",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[]       
    },
    {
        id:8,
        name:"Leave Form",
        link:"/studashboard/leaveform",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[]       
    },
    {
        id:9,
        name:"Profile",
        link:"/studashboard/profile",
        imgurl:"./assets/images/icons/tinysquaresicon.png", 
        drpdwn:[]       
    },
]

const AdminDashboardApi = () => {
  return (
    <>
    
    </>
  )
}
export {sidebarApiadmin,attendanceDataAdmin}
export default AdminDashboardApi