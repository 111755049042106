import React from 'react'
import '../../sassFiles/errorpages/notFoundPage.scss'
import { Link } from 'react-router-dom'
const NotFountPage404 = () => {
  return (
    <>
    <div className="desktop">
        <div className="errorpage404wrap">
           

            <img src="./assets/images/errorPages/404/404.png" alt="" />
          
           
            <Link to="/">Go To Homepage</Link>
        </div>

    </div>
    </>
  )
}

export default NotFountPage404