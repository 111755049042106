import React from 'react'
import StuDashAttComp from '../../../components/dashboardComponents/studentDashboard/StuDashAttComp'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardAttmb from '../../../components/mobile/dashbordMobile/StuDashboardAttmb'
import FooterMobile from '../../../components/mobile/FooterMobile'

const StuDashAttPage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
            <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">
                    <StuDashAttComp/>

                </div>
            </div>
        </div> 
        <div className="mobile" style={{paddingTop:"60px"}}>
          <div className="studentdashboardmobilewrapper">
            <StuDashNavbarSidebarmb redirectkey="fifth"/>
           
            <StuDashboardAttmb/>
            
             <FooterMobile/>

          </div>
        </div> 
    </>
  )
}

export default StuDashAttPage