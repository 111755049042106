import React, { useEffect, useState } from 'react'
import { Row, Col, Form } from 'react-bootstrap'
import { RiContactsBook2Fill } from 'react-icons/ri'
import CommonButton from './subComponents/CommonButton'
import HeadlineOfOtherPage from './subComponents/HeadlineOfOtherPage'
import axios from 'axios';

const ContactPageComp = () => {

  const contactcardsdata = [
    {
      heading: "Corporate Office",
      address: "13, Larmini Street Wari, Dhaka",
      phone: "88027115628",
      email: "info@britishstandardschool.com"
    },
    {
      heading: "Junior Section",
      campus: "Campus 3",
      address: "26/1, Larmini Street Wari, Dhaka",
      phone: "88029512540",
      email: "juniorsection@britishstandardschool.com"
    },
    {
      heading: "Middle Section",
      campus: "Campus 4",
      address: "13, Larmini Street Wari, Dhaka",
      phone: "880223388129",
      email: "middlesection@britishstandardschool.com"
    },
    {
      heading: "Senior Section",
      campus: "Campus 1",
      address: "2/7, Nawab Street Wari, Dhaka",
      phone: "880247116519",
      email: "seniorsection@britishstandardschool.com"
    },

  ]
  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [campusId, setcampusId] = useState("");
  const [campusName, setcampusName] = useState("");
  const [title, settitle] = useState("");
  const [address, setaddress] = useState("");
  const [mobileNum, setmobileNum] = useState("");
  const [email, setemail] = useState("");
  const [myresponse, setmyResponse] = useState([]);
  const [contactformdata, setcontactformdata] = useState({
    name: "",
    email: "",
    phone: "",
    info: "",
    subject: "",
    message: "",
    captcha: ""
  })
  const handleInput = (e) => {
    setcontactformdata({ ...contactformdata, [e.target.name]: e.target.value })
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    console.log(contactformdata)
  }
  useEffect(() => {

    const fetchUpComingNotice = async () => {
      try {
        const myresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/Contact", {},
          headers
        );
        setmyResponse(myresponse.data);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchUpComingNotice();
    console.log(myresponse);
  }, []);
  return (
    <>
      <div className="myContainerseventy">

        <Row style={{ marginTop: "100px" }}>
          {
            myresponse.map((item, index) => {
              return (
                <Col lg={6} data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000" className="columnpadding" key={item.campusId} style={{ marginBottom: "30px", padding: "0 15px" }}>
                  <div className="contactdeskbox hoverscale">
                    <div className="flexwrapper">
                      <h4>{item.title}</h4>
                      {
                        // item.campus?
                        // <p>{item.campus}</p>
                        // :
                        // ""
                        item.campusId == 2 ? "" :
                          <p>{item.campusName}</p>
                      }
                      <p>{item.address}</p>
                      <p>TEL: {item.mobileNum}</p>
                      <p>{item.email}</p>
                    </div>
                  </div>
                </Col>
              )
            })
          }

        </Row>
        <HeadlineOfOtherPage noticepagegapbalance="contactmargintop" headline="Online Inquiry" />
        <Form onSubmit={handleSubmit}>

          <Row data-aos-delay="300" data-aos="fade-up" data-aos-duration="1000">
            <Col lg={6} style={{ padding: "0 15px" }} className="columnpadding">
              <div className="basicinputfield">
                <input type="text" placeholder='Name' name="name" onChange={handleInput} />
                <input type="emial" placeholder='Email' name="email" onChange={handleInput} />
                <input type="text" placeholder='Phone' name="phone" onChange={handleInput} />
                <input type="text" placeholder='Info' name="info" onChange={handleInput} />
                <input type="text" placeholder='Subject' name="subject" onChange={handleInput} />
              </div>
            </Col>
            <Col lg={6} style={{ padding: "0 15px" }} className="columnpadding">
              <div className="basicinputfield contactpagerightsideform">
                <textarea name="message" placeholder="Message" onChange={handleInput}></textarea>
                <input type="text" placeholder='Captcha' name="captcha" onChange={handleInput} />
              </div>
            </Col>
          </Row>
          <CommonButton buttonTitle="Send Message" />
        </Form>
      </div>
    </>
  )
}

export default ContactPageComp