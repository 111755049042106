import React,{useState,useEffect} from 'react'
import SidebarOfAdash from '../subComponent/SidebarOfAdash'
import HeaderofAdash from '../subComponent/HeaderofAdash'
import DynamicDrp from '../subComponents/DynamicDrp'
import {Row,Col} from 'react-bootstrap'
import Dropdown2 from '../../subComponents/CustomSubComponents/Dropdown2'
import { optionssection,optionsClass,optionssemester } from '../../Helper'
import { flexValueTable } from '../../../utils/AdminDashApi/FlexValue'
import ReactPaginate from 'react-paginate';
import { reportCardApi } from '../../../utils/AdminDashApi/ReportCardApi'
const ReportCardComp = () => {
    const [drpsectionselect,setdrpsection] = useState("")
    const [drpclassselect,setdrpclassselect] = useState("")
    const [drpsemesterselect,setdrpsemesterselect] = useState("")
    const [paginatenumselected,setpaginatenumselected] = useState(0)
    const drpfuncsession=(mysectionval)=>{
        setdrpsection(mysectionval)
    }
    const drpfunctionclass=(mysectionval)=>{
        setdrpclassselect(mysectionval)
    }
    const drpfunctionsemester=(mysectionval)=>{
        setdrpsemesterselect(mysectionval)
    }
   
    const handlePrint=()=>{
        // Process Code
    }
    const [isFrontReport, setisFrontReport] = useState(false);
    const [isBackReport, setisBackReport] = useState(false);
    const [isAssessmentReport, setisAssessmentReport] = useState(false);
    const [isDummyReport, setisDummyReport] = useState(false);
    const [isDownloadPdf, setisDownloadPdf] = useState(false);
    const handleCheckFrontReport = event => {
        if (event.target.checked) {
          console.log('✅ Checkbox is checked');
        } else {
          console.log('⛔️ Checkbox is NOT checked');
        }
        setisFrontReport(current => !current);
      };
    const handleCheckBackReport = event => {
        if (event.target.checked) {
          console.log('✅ Checkbox is checked');
        } else {
          console.log('⛔️ Checkbox is NOT checked');
        }
        setisBackReport(current => !current);
      };
    const handleAssessmentReport = event => {
        if (event.target.checked) {
          console.log('✅ Checkbox back report');
        } else {
          console.log('⛔️ Checkbox not checked back report');
        }
        setisAssessmentReport(current => !current);
      };
    const handleDummyReport = event => {
        if (event.target.checked) {
          console.log('✅ Checkbox dummy report');
        } else {
          console.log('⛔️ Checkbox not checked dummy report');
        }
        setisDummyReport(current => !current);
      };
    const handleDownloadPdf = event => {
        if (event.target.checked) {
          console.log('✅ Checkbox download report');
        } else {
          console.log('⛔️ Checkbox not checked download report');
        }
        setisDummyReport(current => !current);
      };
      const [currentItems, setCurrentItems] = useState(null);
      const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPage,setitemsPerPage] = useState(10);
      useEffect(() => {
        // Fetch items from another resources.
        const endOffset = itemOffset + itemsPerPage;
        console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        setCurrentItems(reportCardApi.data.slice(itemOffset, endOffset));
        setPageCount(Math.ceil(reportCardApi.data.length / itemsPerPage));
      }, [itemOffset, itemsPerPage]);
      const handlePageClick = (event) => {
        const newOffset = event.selected * itemsPerPage % reportCardApi.data.length;
        console.log(`User requested page number ${event.selected}, which is offset ${newOffset}`);
        setpaginatenumselected(event.selected);
        setItemOffset(newOffset);
      };
  return (
    <>
<div className="d-flex ">

<SidebarOfAdash/>
<div className="contentpartwidthwrapper">
  <HeaderofAdash head="Report Card"/>
  <div className="contentofadmindash contentflexvalue">
     
     <Row>
        <Col >
            <div className="dropdownwrapper">
                <Dropdown2 func={drpfuncsession} myplaceholder="Select Session" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionssection}/>
            </div>
        
        </Col>
        <Col >
            <div className="dropdownwrapper">
                <Dropdown2 func={drpfunctionclass} myplaceholder="Select Class" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionsClass}/>
            </div>
        
        </Col>
        <Col >
            <div className="dropdownwrapper">
                <Dropdown2 func={drpfunctionsemester} myplaceholder="Select Section" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionssection}/>
            </div>
        
        </Col>
        <Col >
        <div className="dropdownwrapper">
                <Dropdown2 func={drpfunctionsemester} myplaceholder="Select Semester" fontsize="14" fontfamily="'Poppins', sans-serif"  options={optionssemester}/>
            </div>
        </Col>
        <Col>
        <div className="commonadminbutton">
                <button onClick={handlePrint}>Print Result</button>
            </div>
        </Col>
     </Row>
     <div className="d-flex mt-3">
        <div className="checkboxfull">
        <input
            type="checkbox"
            value={isFrontReport}
            onChange={handleCheckFrontReport}
            id="frontreport"
            name="frontreport"                        
            />
            <span>Front Report</span>
        </div>
        <div className="checkboxfull">
        <input
            type="checkbox"
            value={isBackReport}
            onChange={handleCheckBackReport}
            id="backreport"
            name="backreport"                        
            />
            <span>Back Report</span>
        </div>
        <div className="checkboxfull">
        <input
            type="checkbox"
            value={isAssessmentReport}
            onChange={handleAssessmentReport}
            id="assessmentreport"
            name="assessmentreport"                        
            />
            <span>Assessment Report</span>
        </div>
        <div className="checkboxfull">
        <input
            type="checkbox"
            value={isDummyReport}
            onChange={handleDummyReport}
            id="dummyreport"
            name="dummyreport"                        
            />
            <span>Dummy Report</span>
        </div>
        <div className="checkboxfull">
        <input
            type="checkbox"
            value={isDownloadPdf}
            onChange={handleDownloadPdf}
            id="downloadpdf"
            name="downloadpdf"                        
            />
            <span>Download Pdf</span>
        </div>
     </div>

     <Row>
                <Col lg={10} className="m-auto">
                  <div className="admincommontable">
                    <table>
                      <thead>
                        <tr>
                            {
                              reportCardApi.heading.map((item,key)=>{
                                return(
                                  <th key={key}> <div>{item.head}</div> </th>
                                )
                              })
                            }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          currentItems?

                          currentItems.map((item,key)=>{
                            return(
                              <tr>
                                <td>{item.sl}</td>
                                <td>{item.studentid}</td>
                                <td>{item.name}</td>
                                <td>{item.class}</td>
                                <td>{item.section}</td>
                                <td>{item.front}</td>
                                <td>{item.back}</td>
                                
                              </tr>
                            )
                          })
                          :""
                        }
                      </tbody>
                         
                    </table>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={10} className="m-auto">

                  <Row>
                  <Col lg={6}>
                        <p className='pagileft'>Showing {itemOffset+1} to {itemOffset+10} of {reportCardApi.data.length} entries (filtered from 10 total entries)</p>
                  </Col>
                  <Col lg={6} className="ms-auto">
                  <ReactPaginate
                      breakLabel="..."
                      nextLabel="next"
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={5}
                      pageCount={pageCount}
                      previousLabel="previous"
                      renderOnZeroPageCount={null}
                      containerClassName='pagination'
                      pageLinkClassName='page-num'
                      previousLinkClassName='page-numl'
                      nextLinkClassName='page-numr'
                      activeLinkClassName='activelink'
                      disabledClassName="disabledClass"
                      
                    />
                  </Col>
                  </Row>
                </Col>
               
              </Row>

  </div>

</div>
</div>
    </>
  )
}

export default ReportCardComp