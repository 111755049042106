import React from 'react'
import TeadProfileComp from '../../../components/dashboardComponents/teacherDashboard/TeadProfileComp'
import StuDashNavbarSidebarmb from '../../../components/mobile/StuDashNavbarSidebarmb'
import StuDashboardmb from '../../../components/mobile/dashbordMobile/StuDashboardmb'
import FooterMobile from '../../../components/mobile/FooterMobile'
import StuDashProfilePage from '../studentDashboard/StuDashProfilePage'
import TeaDashSidebarMB from '../../../components/mobile/dashbordMobile/TeacherDashboard/SubComponent/TeaDashSidebarMB'
import TeaProfileDashTab from '../../../components/mobile/dashbordMobile/TeacherDashboard/TeaProfileDashTab'

const TeadProfilePage = () => {
    function setBodyColor({color}) {
        document.documentElement.style.setProperty('--bodyColor', color)
    }
    
      setBodyColor({color: "#f2f2f2"})
  return (
    <>
        <div className="desktop">
            <div className="studentdashboardwrapper">
                <div className="overlayfordrpdwn"></div>
                <div className="stu_sidebar">                
                    <TeadProfileComp/>
                    
                </div>
            </div>
        </div> 
        <div className="mobile">
        <div className="teacherdashboardmobilewrapper">
            <TeaDashSidebarMB/>
            <TeaProfileDashTab/>
            <FooterMobile/>
            

        </div>
        </div> 
    </>
  )
}

export default TeadProfilePage