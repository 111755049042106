import axios from 'axios';

export default axios.create({
    baseURL: 'https://sass.britishstandardschool.com/api'
})
// "scripts": {
//     "proxy": "lcp --proxyUrl https://www.yourdomain.ie"
//   }


