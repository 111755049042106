import React from 'react'
import {HiOutlineBell} from 'react-icons/hi'
const HeaderOfstuDashComp = (props) => {
  return (
    <>
        <div className="headerpartbill">
            
            <div className="leftsidecontent">
               <h3>{props.heading}</h3>
            </div>
        
            <div className="rightsidestuff">
                <div className="bellicon">
                    <img src="./assets/images/dashboards/studentDashboard/dashbellimg.png" alt="" />
                </div>
                <div className="profilesmallinfo">
                    <div className="profilepic">
                        <img src="./assets/images/dashboards/studentDashboard/profilepic.png" alt="headerprofilepic" />
                    </div>
                    <div className="roleandid">
                        <div className="roleidwrapper">
                            <h5>{props.myprops.name}</h5>
                            <p>ID {props.myprops.id}</p>
                        </div>
                    </div>
                </div>
            </div>
       
    
        </div>
    </>
  )
}

export default HeaderOfstuDashComp