export const rolemgmtmodelApi = {
    heading : [
        {
            head:""
        },
        {
            head:"User Name"
        },
        {
            head:"User Type"
        },
        {
            head:"Status"
        },
    ],
    data:[
        {
            
            username:"",
            usertype:"",
            status:""
        }
    ],
    selectRoles:{
        heading:[
            {
                
                head:""
            },
            {

                head:"Select Roles"
            }
        ],
        data:[
            {
                selectroles:""
            }
        ]
    },
    admindata:[
        {
            
            username:"safayet",
            usertype:"admin",
            status:"pending"
        },
        {
            
            username:"farhan",
            usertype:"admin academic",
            status:"approved"
        },
        {
            
            username:"shoumik",
            usertype:"admin",
            status:"approved"
        },
        {
            
            username:"safayet",
            usertype:"admin",
            status:"pending"
        },
        {
            
            username:"farhan",
            usertype:"admin academic",
            status:"approved"
        },
        {
            
            username:"shoumik",
            usertype:"admin",
            status:"approved"
        },
        {
            
            username:"safayet",
            usertype:"admin",
            status:"pending"
        },
        {
            
            username:"farhan",
            usertype:"admin academic",
            status:"approved"
        },
        {
            
            username:"shoumik",
            usertype:"admin",
            status:"approved"
        },
        {
            
            username:"safayet",
            usertype:"admin",
            status:"pending"
        },
        {
            
            username:"farhan",
            usertype:"admin academic",
            status:"approved"
        },
        {
            
            username:"shoumik",
            usertype:"admin",
            status:"approved"
        },
    ]
}

export const roleMgmtApi = {
    heading:[
        {
            head:"User Name"
        },
        {
            head:"User Type"
        },
        {
            head:"Real Name"
        },
        {
            head:"Active From"
        },
        {
            head:"Active To"
        },
        {
            head:"Action"
        }
    ],
    data:[
        {
            id:1,
            username:"Afsar Ali", 
            usertype:"", 
            rolename:"",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
        {
            id:2,
            username:"Alisha Alize", 
            usertype:"", 
            rolename:"",
            activefrom:"",
            activeto:"",
            action:"./assets/images/dashboards/adminDashboard/flexvalue/tableactionicon.png"
        },
       
    ]
}