import React from 'react'
import HeaderOfTDashComp from './subComponents/HeaderOfTDashComp'
import SidebarofTdash from './subComponents/SidebarofTdash'
import { headerpropst, prevSalarytabledata } from '../../../utils/DashboardApi/TeacherDashboardApi'
import { Button } from 'react-bootstrap'
const TeadSalaryinfoComp = () => {
  return (
    <>
        <div className="teadashcomp">
            <SidebarofTdash/>
            <div className="rightpartcontentwrap">
            <HeaderOfTDashComp heading="Good Morning Farhana" myprops={headerpropst}/>
            <div className="tabPane">
            <div className="tabpaneheightadjust tabpanebillinfo">
                    
                    <div className="salaryinfotablaebox">
                        <div className="headingofpaymenthistory">
                            <h3>Previous Salary History</h3>
                        </div>
                        <div className="bottombuttondiv">
                            {/* <Button>Total Amount: {billInfoData.previouspayment.tabledata.map((item,key)=>{
                                var tempprevsum = tempprevsum + item.receivedamount
                                setprevsum(tempprevsum)
                                return(
                                    <>
                                    
                                    </>
                                )
                            })} {prevsum} BDT</Button> */}
                            <Button>Total Amount: 73604.00 BDT</Button>
                        </div>
                        <div className="paymenthistorytable">
                        <table>
                                <thead>
                                    <tr>
                                        {
                                            
                                            prevSalarytabledata.header.map((item,key)=>{
                                                return(
                                                    <th key={key}>{item.headerline}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                      {
                                        prevSalarytabledata.tabledata.map((item,key)=>{
                                            return(
                                                <tr key={key}>
                                                    <td>{item.sl}</td>
                                                    <td>{item.date}</td>
                                                    <td>{item.month}</td>
                                                    <td>{item.type}</td>
                                                    <td>{item.salary}</td>
                                                    <td>{item.receivedamount}</td>
                                                </tr>
                                            )
                                        })
                                      }
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}

export default TeadSalaryinfoComp