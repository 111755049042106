import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { upcomingnoticedata } from '../utils/NoticePageApi'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
const UpcomingPartNoticePage = () => {

  let navigate = useNavigate()
  useEffect(() => {
  }, []);
  const handleNoticeSinglePage = (id) => {
    navigate(`/notice/upcoming/${id}`);
  };
  const headers = {
    "X-localization": localStorage.getItem("lan"),
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Max-Age": 3600,
  };
  const [NoticeId, setNoticeId] = useState("");
  const [NoticeHeader, setNoticeHeader] = useState("");
  const [NoticeBody, setNoticeBody] = useState("");
  const [NoticeDate, setNoticeDate] = useState("");
  const [myresponse, setmyResponse] = useState([]);

  useEffect(() => {

    const fetchUpComingNotice = async () => {
      try {
        const myresponse = await axios.get(
          "https://sass.britishstandardschool.com/api/Public/UpcomingNotice", {},
          headers
        );
        setmyResponse(myresponse.data);
      } catch (err) {
        if (err.response) {
          console.error(err.response.data);
          console.log(err.response.status);
          console.log(err.response.header);
        } else {
          console.error(`Error: ${err.message}`);
        }
      }
    };
    fetchUpComingNotice();
    console.log(myresponse);
  }, []);
  return (
    <>
      <div className="upcomingpartnoticepage">
        <div className="myContainerMini">
          <div
            className="subheadingandparagraph"
            data-aos-delay="600"
            data-aos="fade-in"
            data-aos-duration="1000"
          >
            <h3>Up Coming</h3>
          </div>

          <Row className="noticecontent">
            {myresponse.map((element, index) => {
              let trimmedString = element.body.substr(0, 38);
              return (
                <div className="myContainermini" key={element.noticeId}>
                  <Col lg={12} md={12}>
                    <div
                      className="noticebox noticeboxleft upcoming hoverscale"
                      data-aos-delay="300"
                      data-aos="fade-up"
                      data-aos-duration="1000"
                      onClick={() => handleNoticeSinglePage(element.noticeId)}
                    >
                      <span></span>
                      <div className="flexwrap">
                        <div className="noticeheading">
                          <h4>{element.header}</h4>
                        </div>
                        <p className="mainparagraph">{trimmedString}...</p>
                        <div className="lastrow d-flex">
                          <img
                            src="./assets/images/icons/cal27.png"
                            alt=""
                          />
                          <p>{element.noticeDate}</p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </div>
              );
            })}
          </Row>
        </div>
      </div>
    </>
  );
}

export default UpcomingPartNoticePage